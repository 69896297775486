import {
  ButtonV2,
  CardWithLabelV2,
  Component,
  LinkButton,
  styleForFullExpansion,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  DOMAINS,
  EVENT_SCHEDULE_ID_V2,
  EventHoldingMethod,
  EventScheduleIdV2,
  EventScheduleV2,
  EventV2,
  FETCH_EVENT_SCHEDULE_V2_FOR_ADMIN,
  FetchEventScheduleV2ForAdminRequest,
  FetchEventScheduleV2ForAdminResponse,
  HTTPS,
  MessageType,
  MessageV2,
  RecurrenceTypeEnum,
  SendingStatus
} from '@atomica.co/irori';
import { Text, TimeZone } from '@atomica.co/types';
import {
  EMPTY,
  Language,
  builder,
  copyText,
  embedIdInPath,
  isNull,
  isUndefined,
  toDateTimeDurationStr,
  toDateTimeStr,
  toDatetimeFromRelativeFormat
} from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import jaLocale from 'date-fns/locale/ja';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { SUCCESS } from '../../../constants/snackbar-const';
import env from '../../../env/env';
import usePath from '../../../redux/hooks/usePath';
import CommonRequest from '../../../requests/common-request';
import { PATH_IDS, Path } from '../../../router/Routes';
import { EVENT_HOLDING_METHOD_LABELS } from '../../../texts/event-text';
import { ACCOUNT_EVENT_DETAIL_LINK_COPIED } from '../../../texts/snackbar-text';
import { toRecurrenceLabel, toZonedTimeStr } from '../../../utils/date-util';

interface P {
  base: BaseDto;
  eventSchedule: EventScheduleV2;
}

const toTextForEntryFormDeadline = (eventSchedule: EventScheduleV2, timezone: TimeZone): Text => {
  const date =
    toDatetimeFromRelativeFormat(
      eventSchedule.startAtV2,
      eventSchedule.deadlineBeforeDate ?? null,
      eventSchedule.deadlineDateUnit ?? null,
      toZonedTimeStr(eventSchedule.deadlineTime || EMPTY, timezone)
    ) || eventSchedule.endAtV2;

  return toDateTimeStr(date, Language.JAPANESE);
};

const toTextForSendingStatusMessage = (message: MessageV2 | undefined): Text => {
  if (!message) return '送信履歴・送信予定はありません';

  switch (message.status) {
    case SendingStatus.COMPLETED:
      return toDateTimeStr(message.sendAtV2!, Language.JAPANESE);
    case SendingStatus.RETRY:
    case SendingStatus.WAITING:
      return `${toDateTimeStr(message.sendAtV2!, Language.JAPANESE)} (送信予定)`;
    case SendingStatus.SUSPENDED:
      return '未送信(手動で送信する必要があります)';
    case SendingStatus.ERROR:
      return `${toDateTimeStr(message.sendAtV2!, Language.JAPANESE)} (送信エラー)`;
    default:
      return EMPTY;
  }
};

const isErrorMessage = (message: MessageV2 | undefined): boolean => {
  if (!message) return true;
  switch (message.status) {
    case SendingStatus.RETRY:
    case SendingStatus.WAITING:
    case SendingStatus.COMPLETED:
      return false;
    default:
      return true;
  }
};

const EventScheduleSummary: React.FC<P> = React.memo(props => {
  const { base, eventSchedule } = props;
  const { params, openPath, openPathInNewTab } = usePath();
  const { enqueueSnackbar } = useSnackbar();
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [event, setEvent] = useSafeState<EventV2>(unmountRef);
  const [messageEntry, setMessageEntry] = useSafeState<MessageV2 | undefined>(unmountRef);
  const [messageRemind, setMessageRemind] = useSafeState<MessageV2 | undefined>(unmountRef);

  const eventScheduleId = useMemo<EventScheduleIdV2>(() => params[EVENT_SCHEDULE_ID_V2], [params]);
  const isHeld = useMemo<boolean>(() => eventSchedule && eventSchedule.endAtV2 <= new Date(), [eventSchedule]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchEventScheduleV2ForAdminRequest>()
      .baseId(base.baseId)
      .eventScheduleIdV2(eventScheduleId)
      .build();
    const response = await CommonRequest.call<
      FetchEventScheduleV2ForAdminRequest,
      FetchEventScheduleV2ForAdminResponse
    >(FETCH_EVENT_SCHEDULE_V2_FOR_ADMIN, request);

    if (!response.eventScheduleV2) {
      setLoaded(true);
      return;
    }

    setEvent(response.eventScheduleV2.eventV2!);
    setMessageEntry(response.messageEntry);
    setMessageRemind(response.messageRemind);
    setLoaded(true);
  }, [eventScheduleId, setEvent, setMessageEntry, setMessageRemind, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const openRegisterEventScheduleV2Screen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.EDIT_EVENT_SCHEDULE_V2, PATH_IDS, [base.baseCode, event!.eventId, eventScheduleId]));
  }, [base, event, eventScheduleId, openPath]);

  const openAccountEventEntryForm = useSafeCallback((): void => {
    openPathInNewTab(embedIdInPath(Path.ACCOUNT_EVENT_ENTRY_FORM, PATH_IDS, [base.baseCode, eventScheduleId]));
  }, [base, eventScheduleId, openPathInNewTab]);

  const handleMessageDetailClicked = useSafeCallback(
    (messageType: MessageType): void => {
      const path = messageType === MessageType.ENTRY ? Path.ENTRY_FORM_MESSAGES : Path.REMIND_MESSAGES;
      openPath(embedIdInPath(path, PATH_IDS, [base.baseCode, event!.eventId, eventScheduleId]));
    },
    [base, event, eventScheduleId, openPath]
  );

  const handleCopyButtonClicked = useSafeCallback(async (): Promise<void> => {
    await copyText(
      `${HTTPS}://${DOMAINS[env]}${embedIdInPath(Path.ACCOUNT_EVENT_DEATIL, PATH_IDS, [
        base.baseCode,
        eventSchedule.eventScheduleId
      ])}`
    );
    enqueueSnackbar(ACCOUNT_EVENT_DETAIL_LINK_COPIED, { variant: SUCCESS });
  }, [base, enqueueSnackbar, eventSchedule]);

  return (
    <Component style={styleForFullExpansion} loading={!loaded} className='event-schedule-summary'>
      {!!event && !!eventSchedule && (
        <Container>
          <SummaryWrapper>
            <TitleSummary>
              <SubTitle>概要</SubTitle>
              <ButtonsWrapper>
                <ButtonV2
                  startIcon={<FileCopyOutlinedIcon />}
                  label='申し込みフォームのリンクをコピー'
                  onClick={handleCopyButtonClicked}
                />
                {!isHeld && (
                  <ButtonV2 startIcon={<EditOutlinedIcon />} label='編集' onClick={openRegisterEventScheduleV2Screen} />
                )}
              </ButtonsWrapper>
            </TitleSummary>

            <TopSummary>
              <ThumbnailWrapper>
                {eventSchedule.thumbnailURL ? (
                  <Thumbnail src={eventSchedule.thumbnailURL} />
                ) : (
                  <ThumbnailNone>サムネイル未設定</ThumbnailNone>
                )}
              </ThumbnailWrapper>
              <SummaryInfoDiv>
                <SummaryInfoFlex>
                  <SummaryInfoWrapperHalf>
                    <CardWithLabelV2
                      label='日時'
                      text={toDateTimeDurationStr(eventSchedule.startAtV2, eventSchedule.endAtV2, Language.JAPANESE)}
                    />
                  </SummaryInfoWrapperHalf>
                  <SummaryInfoWrapperHalf>
                    <CardWithLabelV2
                      label='繰り返し'
                      text={toRecurrenceLabel(
                        eventSchedule.startAtV2,
                        eventSchedule.recurrenceType || RecurrenceTypeEnum.NOT_RECURRING,
                        jaLocale
                      )}
                    />
                  </SummaryInfoWrapperHalf>
                </SummaryInfoFlex>
                <SummaryInfoFlex>
                  <SummaryInfoWrapperHalf>
                    <CardWithLabelV2
                      label='定員'
                      text={
                        isNull(eventSchedule.capacity) || isUndefined(eventSchedule.capacity)
                          ? '定員なし'
                          : `${eventSchedule.capacity}名`
                      }
                    />
                  </SummaryInfoWrapperHalf>
                  <SummaryInfoWrapperHalf>
                    <CardWithLabelV2 label='消費ポイント' text={`${eventSchedule.points}ポイント`} />
                  </SummaryInfoWrapperHalf>
                </SummaryInfoFlex>
              </SummaryInfoDiv>
            </TopSummary>

            <DescriptionSummary>
              <CardWithLabelV2 label='スケジュールの概要' text={eventSchedule.description} />
            </DescriptionSummary>

            <HoldingMethodSummary>
              <HoldingMethodWrapper>
                <CardWithLabelV2 label='開催方法' text={EVENT_HOLDING_METHOD_LABELS[eventSchedule.holdingMethod]} />
              </HoldingMethodWrapper>

              {eventSchedule.holdingMethod !== EventHoldingMethod.UNDECIDED && (
                <SummaryInfoWrapper>
                  <CardWithLabelV2
                    label={eventSchedule.holdingMethod === EventHoldingMethod.OFFLINE ? '場所' : 'URL'}
                    text={
                      (eventSchedule.holdingMethod === EventHoldingMethod.OFFLINE
                        ? eventSchedule.location
                        : eventSchedule.meetingURL) ?? EMPTY
                    }
                    link={
                      eventSchedule.holdingMethod === EventHoldingMethod.ONLINE ? eventSchedule.meetingURL : undefined
                    }
                  />
                </SummaryInfoWrapper>
              )}
            </HoldingMethodSummary>

            <EntryFormInfoSummary>
              <SummaryInfoWrapperHalf>
                <CardWithLabelV2
                  label='申込みフォーム'
                  text={`${eventSchedule.name}申し込みフォーム`}
                  component={<LinkButton label='詳細' onClick={openAccountEventEntryForm}></LinkButton>}
                />
              </SummaryInfoWrapperHalf>
              <SummaryInfoWrapperHalf>
                <CardWithLabelV2
                  label='申し込みフォーム回答締め切り'
                  text={toTextForEntryFormDeadline(eventSchedule, base.timezone)}
                />
              </SummaryInfoWrapperHalf>
            </EntryFormInfoSummary>

            <EntryFormSendSummary>
              <SummaryInfoWrapperHalf>
                <CardWithLabelV2
                  label='申し込みフォーム送信日時'
                  text={toTextForSendingStatusMessage(messageEntry)}
                  component={
                    <LinkButton label='詳細' onClick={() => handleMessageDetailClicked(MessageType.ENTRY)}></LinkButton>
                  }
                  textType={isErrorMessage(messageEntry) ? 'error' : 'default'}
                />
              </SummaryInfoWrapperHalf>
              <SummaryInfoWrapperHalf />
            </EntryFormSendSummary>

            <RemindSendSummary>
              <SummaryInfoWrapperHalf>
                <CardWithLabelV2
                  label='リマインド日時'
                  text={toTextForSendingStatusMessage(messageRemind)}
                  component={
                    <LinkButton
                      label='詳細'
                      onClick={() => handleMessageDetailClicked(MessageType.REMIND)}
                    ></LinkButton>
                  }
                  textType={isErrorMessage(messageRemind) ? 'error' : 'default'}
                />
              </SummaryInfoWrapperHalf>
              <SummaryInfoWrapperHalf />
            </RemindSendSummary>

            <PreQuestionnaireSummary>
              <CardWithLabelV2
                label={'事前アンケートフォーム'}
                text={eventSchedule.preQuestionnaireURL ?? EMPTY}
                link={eventSchedule.preQuestionnaireURL}
              />
            </PreQuestionnaireSummary>

            <PostQuestionnaireSummary>
              <CardWithLabelV2
                label={'事後アンケートフォーム'}
                text={eventSchedule.postQuestionnaireURL ?? EMPTY}
                link={eventSchedule.postQuestionnaireURL}
              />
            </PostQuestionnaireSummary>
          </SummaryWrapper>
        </Container>
      )}
    </Component>
  );
});

EventScheduleSummary.displayName = 'EventScheduleSummary';
export default EventScheduleSummary;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${themeV2.mixins.v2.spacing * 2}px 0 ${themeV2.mixins.v2.spacing * 5}px 0;
`;

const SubTitle = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.xLarge};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;

const SummaryInfoFlex = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;

const SummaryInfoDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SummaryWrapper = styled.div`
  width: 100%;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: ${themeV2.mixins.v2.spacing}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 6}px;
`;

const TitleSummary = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${themeV2.mixins.v2.spacing}px;
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
`;

const SummaryInfoWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: ${themeV2.mixins.v2.spacing * 3}px;
`;

const SummaryInfoWrapperHalf = styled(SummaryInfoWrapper)`
  width: 50%;
`;

const ThumbnailWrapper = styled.div`
  width: 277px;
  height: 196px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ThumbnailNone = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.medium};
  color: ${themeV2.mixins.v2.color.font.pink};
`;

const HoldingMethodWrapper = styled(SummaryInfoWrapper)`
  width: 189px;
`;

const TopSummary = styled(SummaryInfoFlex)``;
const DescriptionSummary = styled(SummaryInfoWrapper)``;
const HoldingMethodSummary = styled(SummaryInfoFlex)``;
const EntryFormInfoSummary = styled(SummaryInfoFlex)``;
const EntryFormSendSummary = styled(SummaryInfoFlex)``;
const RemindSendSummary = styled(SummaryInfoFlex)``;
const PreQuestionnaireSummary = styled(SummaryInfoWrapper)``;
const PostQuestionnaireSummary = styled(SummaryInfoWrapper)``;
