import {
  SearchBox,
  Skeleton,
  theme,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseAuthority,
  BaseDto,
  SAVE_PERSON_IN_CHARGE_FOR_ADMIN,
  SEARCH_USERS_FOR_ADMIN,
  SavePersonInChargeForAdminRequest,
  SavePersonInChargeForAdminResponse,
  SearchUsersForAdminRequest,
  SearchUsersForAdminResponse,
  Sort,
  User,
  UserSortColumn
} from '@atomica.co/irori';
import { Index, Offset, UserId, Word } from '@atomica.co/types';
import { EMPTY, FIRST_INDEX, Language, ZERO, builder, hasLength, toDateStr } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Add, CheckCircle, RemoveCircle } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { ERROR, SUCCESS } from '../../constants/snackbar-const';
import { toPersonInChargeToSave } from '../../converters/user-converter';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import { USER_AUTH_ERROR, USER_AUTH_SAVED } from '../../texts/snackbar-text';
import mojaco from './../../assets/mojaco/mojaco_bow.png';
import UserAuthorityModal from './user-authority-modal/UserAuthorityModal';
import UserPropSortTh from './user-authority-sort-th/UserAuthoritySortTh';

const LIMIT = 20;

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 300px 0px'
};

interface P {
  base: BaseDto;
  user: User;
}

const UserSearchScreen: React.FC<P> = React.memo(props => {
  const { base } = props;
  const bottomRef = useRef<HTMLDivElement>(null);
  const loaded = useRef<boolean>(false);
  const offset = useRef<Offset>(ZERO);
  const hasMore = useRef<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [users, setUsers] = useSafeState<User[]>(unmountRef, []);
  const [selectedUserIds, setSelectedUserIds] = useSafeState<UserId[]>(unmountRef, []);
  const [sortKey, setSortKey] = useSafeState<UserSortColumn>(unmountRef, UserSortColumn.PERSON_IN_CHARGE);
  const [sort, setSort] = useSafeState<Sort>(unmountRef, Sort.ASC);

  const authorities = useMemo<BaseAuthority[]>(() => {
    return base.authorities ?? [];
  }, [base]);

  const isUserSelected = useMemo<boolean>(() => hasLength(selectedUserIds), [selectedUserIds]);

  const searchUsers = useSafeCallback(
    async (sortKey: UserSortColumn, sort: Sort, word: Word): Promise<User[]> => {
      if (!hasMore.current) return [];

      const request = builder<SearchUsersForAdminRequest>()
        .baseId(base.baseId)
        .limit(LIMIT)
        .offset(offset.current)
        .sortCol(sortKey)
        .sort(sort)
        .word(word)
        .build();
      const response = await commonRequest<SearchUsersForAdminRequest, SearchUsersForAdminResponse>(
        SEARCH_USERS_FOR_ADMIN,
        request
      );
      const users = response.users;

      offset.current += LIMIT;
      hasMore.current = users.length === LIMIT;
      return users;
    },
    [base, commonRequest]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    const users = await searchUsers(sortKey, sort, searchingWord);
    setUsers(users);
  }, [searchUsers, setUsers]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const onScroll = useSafeCallback(
    async (entries: IntersectionObserverEntry[]): Promise<void> => {
      for (const entry of entries) {
        if (!entry.isIntersecting) return;
        const usersToAdd = await searchUsers(sortKey, sort, searchingWord);
        setUsers(prevUsers => [...prevUsers, ...usersToAdd]);
      }
    },
    [searchUsers, sortKey, sort, searchingWord, setUsers]
  );

  useEffect(() => {
    if (loaded.current) return;
    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);
    bottomRef.current && observer.observe(bottomRef.current);
    loaded.current = true;
    return observer.disconnect();
  }, [loaded, onScroll]);

  const refreshUsers = useSafeCallback(
    async (sortKey: UserSortColumn, sort: Sort, word: Word): Promise<void> => {
      offset.current = ZERO;
      hasMore.current = true;
      setSearchingWord(word);
      const users = await searchUsers(sortKey, sort, word);
      setUsers(users);
      setSelectedUserIds([]);
    },
    [setSearchingWord, searchUsers, setUsers, setSelectedUserIds]
  );

  const handleSortThClicked = useSafeCallback(
    (sortKey: UserSortColumn): void => {
      const { ASC, DESC } = Sort;
      setSortKey(prevSortKey => {
        setSort(prevSort => {
          const sort = sortKey === prevSortKey ? (prevSort === ASC ? DESC : ASC) : ASC;
          refreshUsers(sortKey, sort, searchingWord);
          return sort;
        });
        return sortKey;
      });
    },
    [setSortKey, setSort, refreshUsers, searchingWord]
  );

  const handleHeaderChecked = useSafeCallback((): void => {
    setSelectedUserIds(selectedUserId =>
      users.length === selectedUserId.length ? [] : users.map(user => user.userId)
    );
  }, [setSelectedUserIds, users]);

  const handleRowClicked = useSafeCallback(
    (index: Index): void => {
      setSelectedUserIds(selectedUserId => {
        const userId: UserId = users[index].userId;
        const newSelected: UserId[] = selectedUserId.concat();
        newSelected.includes(userId) ? newSelected.splice(newSelected.indexOf(userId), 1) : newSelected.push(userId);
        return newSelected;
      });
    },
    [setSelectedUserIds, users]
  );

  const openModal = useSafeCallback((): void => {
    if (!isUserSelected) return;
    setIsModalOpen(true);
  }, [isUserSelected, setIsModalOpen]);

  const savePersonInCharge = useSafeCallback(
    async (selectedIdx: Index): Promise<void> => {
      const targetUsers = users.filter(targetUser => selectedUserIds.includes(targetUser.userId));
      const personInCharges = toPersonInChargeToSave(base, targetUsers, authorities[selectedIdx]);
      const request = builder<SavePersonInChargeForAdminRequest>()
        .baseId(base.baseId)
        .personInCharges(personInCharges)
        .build();
      const response = await commonRequest<SavePersonInChargeForAdminRequest, SavePersonInChargeForAdminResponse>(
        SAVE_PERSON_IN_CHARGE_FOR_ADMIN,
        request
      );

      const savedSuccessfully = hasLength(response.personInChargeIds);
      const message = savedSuccessfully ? USER_AUTH_SAVED : USER_AUTH_ERROR;
      const variant = savedSuccessfully ? SUCCESS : ERROR;

      enqueueSnackbar(message, { variant });
      refreshUsers(sortKey, sort, searchingWord);
      setIsModalOpen(false);
    },
    [
      authorities,
      base,
      enqueueSnackbar,
      refreshUsers,
      searchingWord,
      selectedUserIds,
      setIsModalOpen,
      sort,
      sortKey,
      users
    ]
  );

  return (
    <>
      <Container>
        <Content>
          <SubTitle>ユーザー一覧</SubTitle>
          <Title>Users</Title>

          <FunctionWrapper>
            <UserAuthChangeLabel isUserSelected={isUserSelected} onClick={openModal}>
              <AddIcon />
              権限を変更する
            </UserAuthChangeLabel>
            <SearchBox
              type='text'
              placeholder='Enter で検索'
              text={searchingWord}
              onChange={setSearchingWord}
              onEnter={(word: Word) => refreshUsers(sortKey, sort, word)}
            />
          </FunctionWrapper>

          <Table>
            <Thead>
              <Tr>
                <Th onClick={handleHeaderChecked}>
                  <IconWrapper>
                    {!isUserSelected ? (
                      <CheckCircleIcon checked={false} />
                    ) : users.length === selectedUserIds.length ? (
                      <CheckCircleIcon checked={true} />
                    ) : (
                      <PartialCheckCircleIcon checked={true} />
                    )}
                  </IconWrapper>
                </Th>
                <Th></Th>
                <UserPropSortTh
                  colLabel='名前'
                  colName={UserSortColumn.FAMILY_NAME}
                  sortKey={sortKey}
                  sort={sort}
                  handleClick={handleSortThClicked}
                />
                <UserPropSortTh
                  colLabel='電話番号'
                  colName={UserSortColumn.PHONE}
                  sortKey={sortKey}
                  sort={sort}
                  handleClick={handleSortThClicked}
                />
                <UserPropSortTh
                  colLabel='メールアドレス'
                  colName={UserSortColumn.EMAIL}
                  sortKey={sortKey}
                  sort={sort}
                  handleClick={handleSortThClicked}
                />
                <UserPropSortTh
                  colLabel='権限'
                  colName={UserSortColumn.PERSON_IN_CHARGE}
                  sortKey={sortKey}
                  sort={sort}
                  handleClick={handleSortThClicked}
                />
                <UserPropSortTh
                  colLabel='開始日'
                  colName={UserSortColumn.PERSON_IN_CHARGE}
                  sortKey={sortKey}
                  sort={sort}
                  handleClick={handleSortThClicked}
                />
                <UserPropSortTh
                  colLabel='終了日'
                  colName={UserSortColumn.PERSON_IN_CHARGE}
                  sortKey={sortKey}
                  sort={sort}
                  handleClick={handleSortThClicked}
                />
              </Tr>
            </Thead>

            {hasLength(users) && (
              <Tbody>
                {users.map((row, index) => (
                  <Tr key={`tr${index}`} onClick={() => handleRowClicked(index)}>
                    <TdCheck>
                      <IconWrapper>
                        <CheckCircleIcon checked={selectedUserIds.includes(row.userId)} />
                      </IconWrapper>
                    </TdCheck>
                    <TdPhoto>
                      <Skeleton style={styleForPhoto} src={row.photoURL ? row.photoURL : mojaco} />
                    </TdPhoto>
                    <Td>{`${row.familyName} ${row.firstName}`}</Td>
                    <Td>{row.phone}</Td>
                    <Td>{row.email}</Td>
                    <Td>
                      {row.personInCharge && row.personInCharge[FIRST_INDEX].authority
                        ? row.personInCharge[FIRST_INDEX].authority.authorityName
                        : '未設定'}
                    </Td>
                    <Td>{row.startDate ? toDateStr(row.startDate, Language.JAPANESE) : '未設定'}</Td>
                    <Td>{row.endDate ? toDateStr(row.endDate, Language.JAPANESE) : '未設定'}</Td>
                  </Tr>
                ))}
              </Tbody>
            )}
          </Table>

          <Bottom ref={bottomRef} />
        </Content>

        <UserAuthorityModal
          authority={authorities}
          isOpen={isModalOpen}
          onSave={savePersonInCharge}
          onClose={() => setIsModalOpen(false)}
          selectedUsers={users.filter(user => selectedUserIds.includes(user.userId))}
        />
      </Container>
    </>
  );
});

UserSearchScreen.displayName = 'UserSearchScreen';
export default UserSearchScreen;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 5}px ${theme.mixins.spacing * 6}px;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1280px;
  height: auto;
`;

const SubTitle = styled(Typography)`
  color: ${themeV2.mixins.v2.color.font.black};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
`;

const Title = styled(Typography)`
  color: ${themeV2.mixins.v2.color.font.black};
  font-size: 56px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  line-height: 56px;
`;

const FunctionWrapper = styled.div`
  width: 100%;
  height: 30px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  gap: ${theme.mixins.spacing * 2}px;
`;

const UserAuthChangeLabel = styled.div<{ isUserSelected: boolean }>`
  height: 30px;
  display: flex;
  align-items: center;
  gap: ${theme.mixins.spacing / 2}px;

  color: ${props =>
    props.isUserSelected ? theme.mixins.typography.fontColor.gray : theme.mixins.typography.fontColor.lightGray};
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  cursor: pointer;
`;

const AddIcon = styled(Add)`
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
`;

const Table = styled.table`
  width: 100%;
  height: auto;
  margin-top: ${theme.mixins.spacing}px;
  border-spacing: 0 ${theme.mixins.spacing / 4}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-size: ${theme.mixins.typography.fontSize.sixteen + 2}px;
`;

const Thead = styled.thead`
  width: 100%;
  background: ${theme.mixins.background.white};
`;

const Th = styled.th`
  text-align: left;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 2}px;
  cursor: pointer;
`;

const Tr = styled.tr`
  background: ${theme.mixins.background.white};
`;

const Td = styled.td`
  height: 64px;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 2}px;
  cursor: pointer;
`;

const TdCheck = styled(Td)`
  width: 48px;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const TdPhoto = styled(Td)`
  width: 56px;
  padding: 0px;
`;

const Tbody = styled.tbody``;

const IconWrapper = styled.div`
  width: 28px;
  height: 28px;
`;

const CheckCircleIcon = styled(CheckCircle)<{ checked: boolean }>`
  color: ${({ checked }) => (checked ? theme.mixins.background.pink : theme.mixins.background.gray)};
  font-size: ${theme.mixins.typography.fontSize.twentyEight}px;
`;

const PartialCheckCircleIcon = styled(RemoveCircle)<{ checked: boolean }>`
  color: ${({ checked }) => (checked ? theme.mixins.background.pink : theme.mixins.background.gray)};
  font-size: ${theme.mixins.typography.fontSize.twentyEight}px;
`;

const styleForPhoto: CSSProperties = {
  width: 48,
  height: 48,
  background: theme.mixins.background.lightGray,
  border: `2px solid ${theme.mixins.border.lightGray}`,
  borderRadius: '50%',
  objectFit: 'contain',
  transform: 'none'
};

const Bottom = styled.div``;
