import {
  CircularLoader,
  QRCode,
  ReactUseGestureEventHandlers,
  SnackbarStatus,
  customMedia,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AccessControl,
  BASE_CODE,
  BaseDto,
  KeyOperation,
  KeyOperationResult,
  KeyOperationStatus,
  OPERATE_SPACE_KEY,
  OperateSpaceKeyRequest,
  OperateSpaceKeyResponse,
  SHARED_SPACE_RESERVATION_END_AT,
  SHARED_SPACE_RESERVATION_ID,
  SHARED_SPACE_RESERVATION_START_AT,
  SpaceCategory,
  SpaceId,
  SpaceManagement,
  SpaceReservationId,
  User,
  constructExternalLink
} from '@atomica.co/irori';
import { Code, Height, Id, Label, Message, Milliseconds, Minute, Name, URL } from '@atomica.co/types';
import { builder, copyText, embedIdInPath, toFormattedDateTimeDurationStr, uuid } from '@atomica.co/utils';
import { Card, CardMedia } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { animated } from '@react-spring/web';
import { subMinutes } from 'date-fns';
import format from 'date-fns/format';
import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import IconShare from '../../../../assets/icon/icon_share.svg';
import { MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH } from '../../../../constants/common-const';
import { ERROR, SUCCESS } from '../../../../constants/snackbar-const';
import env from '../../../../env/env';
import { SpaceReservationCard } from '../../../../models/account-model';
import { useSnackbarV2 } from '../../../../provider/SnackbarProviderV2';
import usePath from '../../../../redux/hooks/usePath';
import CommonRequest from '../../../../requests/common-request';
import { Path } from '../../../../router/Routes';
import { FAILED_TO_OPEN_KEY, INVITATION_LINK_COPIED, OPEN_KEY_SUCCESSFULLY } from '../../../../texts/snackbar-text';
import { dueTimeToStr } from '../../../../utils/space-reservation-util';

const SHOW_SNACK_BAR_TIME_MS = 10000;

interface P {
  fronting: boolean;
  base: BaseDto;
  user: User;
  /** @deprecated */
  card?: SpaceReservationCard;
  accessControl?: AccessControl;
  setCardHeight: (height: Height) => void;
  bind: (id: Id) => ReactUseGestureEventHandlers;
}

interface SnackbarParameter {
  message: Message;
  status: SnackbarStatus;
  autoHideDuration?: Milliseconds;
}

const SpaceReservationCardV2: React.FC<P> = React.memo(props => {
  const { base, user, card, accessControl, setCardHeight, bind } = props;
  const { params } = usePath();
  const { openSnackbar } = useSnackbarV2();
  const spaceReservationId = useMemo<SpaceReservationId | undefined>(
    () => card?.spaceReservationId ?? accessControl?.spaceReservationId,
    [card, accessControl]
  );
  const spaceId = useMemo<SpaceId | undefined>(() => card?.spaceId ?? accessControl?.spaceId, [card, accessControl]);
  const spaceName = useMemo<Name | undefined>(() => card?.spaceName ?? accessControl?.spaceName, [card, accessControl]);
  const spacePhotoUrl = useMemo<URL | undefined>(
    () => card?.spacePhotoUrl ?? accessControl?.spacePhotoUrl,
    [card, accessControl]
  );
  const category = useMemo<SpaceCategory | undefined>(
    () => card?.category ?? accessControl?.spaceCategory,
    [card, accessControl]
  );
  const reservationName = useMemo<Name | undefined>(
    () => card?.reservationName ?? accessControl?.spaceReservationName,
    [card, accessControl]
  );
  const reservationStartAt = useMemo<Date | undefined>(
    () => card?.reservationStartAt ?? accessControl?.startAt,
    [card, accessControl]
  );
  const reservationEndAt = useMemo<Date | undefined>(
    () => card?.reservationEndAt ?? accessControl?.endAt,
    [card, accessControl]
  );
  const reservationCheckoutAt = useMemo<Date | undefined>(
    () => card?.reservationCheckeoutAt ?? accessControl?.checkoutAt,
    [card, accessControl]
  );
  const qrCodeText = useMemo<Code | undefined>(
    () => card?.qrCode ?? (accessControl?.qrCode as Code),
    [card, accessControl]
  );
  const spaceManagement = useMemo<SpaceManagement | undefined>(
    () => card?.spaceManagement ?? accessControl?.spaceManagement,
    [card, accessControl]
  );
  const permittedEntryMinutes = useMemo<Minute>(
    () => card?.permittedEntryMinutes ?? accessControl?.permittedEntryMinutes ?? 0,
    [card, accessControl]
  );
  const capacity = useMemo<Minute>(() => accessControl?.capacity ?? 0, [accessControl]);
  const participantCount = useMemo<Minute>(() => accessControl?.participantCount ?? 0, [accessControl]);

  const unmountRef = useUnmountRef();
  const [opening, setOpening] = useSafeState<boolean>(unmountRef, false);
  const [showOpenButton, setShowOpenButton] = useSafeState<boolean>(unmountRef, false);
  const [showReservationURLToShare, setShowReservationURLToShare] = useSafeState<boolean>(unmountRef, false);
  const [reservedSnackbarParameter, setReservedSnackbarParameter] = useSafeState<SnackbarParameter | undefined>(
    unmountRef
  );

  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const startAt = reservationStartAt ? subMinutes(new Date(reservationStartAt), permittedEntryMinutes) : undefined;
      const endAt = reservationEndAt;
      const now = new Date();
      const showOpenButtonToUpdate =
        spaceManagement === SpaceManagement.BITKEY &&
        (startAt ? startAt <= now : true) &&
        (endAt ? now <= endAt : true) &&
        !reservationCheckoutAt;
      setShowOpenButton(showOpenButtonToUpdate);

      const showReservationURLToShareToUpdate = (endAt ? now <= endAt : true) && !reservationCheckoutAt;
      setShowReservationURLToShare(showReservationURLToShareToUpdate);
    }, 1000);

    return () => clearInterval(interval);
  }, [
    permittedEntryMinutes,
    reservationStartAt,
    reservationEndAt,
    reservationCheckoutAt,
    spaceManagement,
    setShowOpenButton,
    setShowReservationURLToShare
  ]);

  const qrCode = useMemo<ReactNode>(() => {
    if (!qrCodeText) return <></>;

    switch (spaceManagement) {
      case SpaceManagement.DENSO:
        return <QrCode src={qrCodeText} />;
      case SpaceManagement.BITKEY:
        return <QRCode size={120} color='white' url={qrCodeText} />;
      default:
        return <></>;
    }
  }, [spaceManagement, qrCodeText]);

  const usageTimeLabel = useMemo<Label>(() => {
    if (!reservationStartAt && !reservationEndAt) return '指定なし';

    return !!reservationStartAt && !!reservationEndAt
      ? toFormattedDateTimeDurationStr(reservationStartAt, reservationEndAt, 'HH:mm', '~')
      : dueTimeToStr(reservationEndAt);
  }, [reservationStartAt, reservationEndAt]);

  const reservationURLToShare = useMemo<URL | undefined>(() => {
    const isConference = category === SpaceCategory.CONFERENCE;
    const isParticipantsOverCapacity = capacity >= 0 && capacity <= participantCount;

    if (!isConference || isParticipantsOverCapacity || !spaceReservationId || !reservationStartAt || !reservationEndAt)
      return;

    const startAt = format(reservationStartAt, 'yyyy/MM/dd HH:mm:ss').toString();
    const endAt = format(reservationEndAt, 'yyyy/MM/dd HH:mm:ss').toString();

    const queryParamOfSpaceReservationId = `${SHARED_SPACE_RESERVATION_ID}=${spaceReservationId}`;
    const queryParamOfStartAt = `${SHARED_SPACE_RESERVATION_START_AT}=${startAt}`;
    const queryParamOfEndAt = `${SHARED_SPACE_RESERVATION_END_AT}=${endAt}`;

    const pathToOpen = `${embedIdInPath(
      Path.ACCOUNT_SHARE,
      [BASE_CODE],
      [params[BASE_CODE]]
    )}?${queryParamOfSpaceReservationId}&${queryParamOfStartAt}&${queryParamOfEndAt}`;

    return constructExternalLink(env, pathToOpen);
  }, [spaceReservationId, reservationEndAt, reservationStartAt, params, category, capacity, participantCount]);

  const handleShareClicked = useSafeCallback(async (): Promise<void> => {
    if (navigator.share) {
      await navigator.share({ url: reservationURLToShare });
      return;
    }

    copyText(reservationURLToShare!);
    openSnackbar(INVITATION_LINK_COPIED, SUCCESS, SHOW_SNACK_BAR_TIME_MS);
  }, [reservationURLToShare, openSnackbar]);

  const reserveSnackbar = (message: Message, status: SnackbarStatus, autoHideDuration: Milliseconds) =>
    setReservedSnackbarParameter(
      builder<SnackbarParameter>().message(message).status(status).autoHideDuration(autoHideDuration).build()
    );

  useEffect(() => {
    if (reservedSnackbarParameter) {
      const { message, status, autoHideDuration } = reservedSnackbarParameter;
      openSnackbar(message, status, autoHideDuration);
      setReservedSnackbarParameter(undefined);
    }
  }, [reservedSnackbarParameter, setReservedSnackbarParameter, openSnackbar]);

  const openSpaceKey = useSafeCallback(async (): Promise<void> => {
    setOpening(true);

    const request = builder<OperateSpaceKeyRequest>()
      .baseId(base.baseId)
      .spaceId(spaceId!)
      .operation(KeyOperation.UNLOCK)
      .userId(user.userId)
      .build();
    const response = await CommonRequest.call<OperateSpaceKeyRequest, OperateSpaceKeyResponse>(
      OPERATE_SPACE_KEY,
      request
    );

    if (!response || response.result === KeyOperationResult.FAIL) {
      reserveSnackbar(FAILED_TO_OPEN_KEY, ERROR, SHOW_SNACK_BAR_TIME_MS);
    } else if (response.status === KeyOperationStatus.OPEN || KeyOperationStatus.UNKNOWN) {
      reserveSnackbar(OPEN_KEY_SUCCESSFULLY, SUCCESS, SHOW_SNACK_BAR_TIME_MS);
    }

    setOpening(false);
  }, [setOpening, base, spaceId, user, openSnackbar]);

  useEffect(() => {
    if (!cardRef.current) return;
    setCardHeight(cardRef.current.clientHeight);
  }, [cardRef, setCardHeight]);

  return (
    <Container {...bind(card?.spaceReservationId ?? accessControl?.accessControlId ?? uuid())} ref={cardRef}>
      <CardWrapper>
        <CustomCard data-testid='qr-card'>
          <CustomCardMedia image={spacePhotoUrl} />
          <div>
            <Title>{reservationName}</Title>
            <InfoWrapper>
              <CardRow>
                <InfoLabel>施設</InfoLabel>
                <InfoText>{spaceName}</InfoText>
              </CardRow>
              {((card && category === SpaceCategory.CONFERENCE) || accessControl) && usageTimeLabel && (
                <CardRow>
                  <InfoLabel>日時</InfoLabel>
                  <InfoText>{usageTimeLabel}</InfoText>
                </CardRow>
              )}
            </InfoWrapper>
          </div>
          <QrCodeArea>{qrCode}</QrCodeArea>
          <ButtonsWrapper>
            {showOpenButton && (
              <>
                {opening ? (
                  <ButtonArea>
                    <CircularLoader />
                  </ButtonArea>
                ) : (
                  <ButtonArea onClick={openSpaceKey}>
                    <StyledLockOpenIcon />
                    <ButtonLabel>解錠する</ButtonLabel>
                  </ButtonArea>
                )}
              </>
            )}

            {showReservationURLToShare && reservationURLToShare && (
              <ButtonArea onClick={handleShareClicked}>
                <CustomIconShare src={IconShare} />
                <ButtonLabel>招待する</ButtonLabel>
              </ButtonArea>
            )}
          </ButtonsWrapper>
        </CustomCard>
      </CardWrapper>
    </Container>
  );
});

SpaceReservationCardV2.displayName = 'SpaceReservationCardV2';
export default SpaceReservationCardV2;

const Container = styled(animated.div)`
  width: 100vw;
  max-width: ${MOBILE_MAX_WIDTH - themeV2.mixins.v2.spacing * 4}px;
  min-width: ${MOBILE_MIN_WIDTH - themeV2.mixins.v2.spacing * 4}px;
  height: auto;
`;

const CardWrapper = styled.div`
  width: 80%;
  position: relative;
  color: white;
  margin: 0 auto;
`;

const CustomCard = styled(Card)`
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  user-select: none;
  ${customMedia.greaterThan('medium')`
    height: 416px;
  `};
`;

const CustomCardMedia = styled(CardMedia).attrs(() => ({ component: 'img' }))`
  height: 18dvh;
  background: ${themeV2.mixins.v2.color.background.lightGray};
  -webkit-user-drag: none;
  ${customMedia.greaterThan('tiny')`
    height: 160px;
  `}
`;

const Title = styled.div`
  ${themeV2.mixins.v2.typography.title.large};
  margin-left: ${themeV2.mixins.v2.spacing * 3}px;
`;

const InfoWrapper = styled.div`
  height: 84px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
  margin: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 3}px 0;
`;

const CardRow = styled.div`
  display: flex;
  min-height: 24px;
  max-height: 48px;
`;

const InfoLabel = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
  width: 60px;
  flex-shrink: 0;
`;

const InfoText = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const QrCodeArea = styled.div`
  width: 100%;
  height: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
`;

const QrCode = styled.img`
  width: 120px;
  height: 120px;
  -webkit-user-drag: none;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 24px;
  display: flex;
  justify-content: center;
  gap: ${themeV2.mixins.v2.spacing}px;
  margin: auto 0 ${themeV2.mixins.v2.spacing * 2}px;
`;

const ButtonArea = styled.div`
  display: flex;
  gap: ${themeV2.mixins.v2.spacing}px;
  justify-content: center;
  align-items: center;
`;

const StyledLockOpenIcon = styled(LockOpenIcon)`
  width: 24px;
  height: 24px;
  color: ${themeV2.mixins.v2.color.font.gray};
  margin-bottom: ${themeV2.mixins.v2.spacing / 2}px;
  -webkit-user-drag: none;
`;

const CustomIconShare = styled.img`
  width: 24px;
  height: 24px;
  color: ${themeV2.mixins.v2.color.font.gray};
  margin-bottom: ${themeV2.mixins.v2.spacing / 2}px;
  -webkit-user-drag: none;
`;

const ButtonLabel = styled.label`
  color: ${themeV2.mixins.v2.color.font.lightGray};
  ${themeV2.mixins.v2.typography.body.medium};
  margin-bottom: 0px;
`;
