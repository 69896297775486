import {
  ButtonV2,
  CardWithLabelV2,
  DialogV2,
  IconButtonV2,
  InputDateWithLabelV2,
  LabelV2,
  RadioBoxV2,
  ScreenLoaderV2,
  TextFieldV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  DELETE_MESSAGE_V2_FOR_ADMIN,
  DOMAINS,
  DeleteMessageV2ForAdminRequest,
  DeleteMessageV2ForAdminResponse,
  EventHoldingMethod,
  EventScheduleV2,
  HTTPS,
  MessageType,
  MessageV2,
  ReplacingReservedWordsForEventMessage,
  ReservedWordsForEventMessageEnum,
  SAVE_MESSAGES_V2_FOR_ADMIN,
  SaveMessagesV2ForAdminRequest,
  SaveMessagesV2ForAdminResponse,
  SendingMethod,
  SendingStatus,
  User
} from '@atomica.co/irori';
import { Address, Message, Name, Time } from '@atomica.co/types';
import {
  EMPTY,
  EMPTY_HALF_WIDTH,
  Language,
  builder,
  embedIdInPath,
  toDateTimeDurationStr,
  toDateTimeStr,
  uuid
} from '@atomica.co/utils';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { format } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import InputWithPreview from '../../../components/input/InputWithPreview';
import env from '../../../env/env';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import { PATH_IDS, Path } from '../../../router/Routes';
import { EVENT_HOLDING_METHOD_LABELS } from '../../../texts/event-text';
import { MESSAGE_SENDING_METHOD } from '../../../texts/messages-text';

interface P {
  base: BaseDto;
  user: User;
  isOpen: boolean;
  messageType: MessageType;
  sendingStatus: SendingStatus | undefined;
  eventScheduleV2: EventScheduleV2;
  messageV2: MessageV2 | undefined;
  onClose(isUpdated: boolean): void;
}

const getHeaderLabel = (name: Name, status: SendingStatus | undefined) => {
  switch (status) {
    case SendingStatus.RETRY:
      return name + 'の再送信';
    case SendingStatus.COMPLETED:
      return '送信済みの' + name;
    case SendingStatus.ERROR:
      return '送信エラーの' + name;
    default:
      return '未送信の' + name;
  }
};

const timePattern = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;

const SendMessageModal: React.FC<P> = React.memo(props => {
  const { base, user, isOpen, messageType, sendingStatus, eventScheduleV2, messageV2, onClose } = props;

  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [isScreenLoaderShown, setIsScreenLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [sendingMethodToSave, setSendingMethodToSave] = useSafeState<SendingMethod>(unmountRef, SendingMethod.AUTO);
  const [sendingDateToSave, setSendingDateToSave] = useSafeState<Date>(unmountRef, new Date());
  const [sendingTimeToSave, setSendingTimeToSave] = useSafeState<Time>(unmountRef, EMPTY);
  const [emailMessageToSave, setEmailMessageToSave] = useSafeState<Message>(unmountRef, EMPTY);
  const [lineMessageToSave, setLineMessageToSave] = useSafeState<Message>(unmountRef, EMPTY);

  const name = useMemo<Name>(
    () => (messageType === MessageType.ENTRY ? '申し込みフォーム' : 'リマインド'),
    [messageType]
  );

  const readonly = useMemo<boolean>(() => {
    switch (sendingStatus) {
      case SendingStatus.COMPLETED:
      case SendingStatus.ERROR:
        return true;
    }

    return false;
  }, [sendingStatus]);

  const disabledTempButton = useMemo<boolean>(() => {
    if (sendingMethodToSave !== SendingMethod.MANUAL) {
      return true;
    }

    return false;
  }, [sendingMethodToSave]);

  const disabledSendButton = useMemo<boolean>(() => {
    if (lineMessageToSave.trim() === EMPTY || emailMessageToSave.trim() === EMPTY) {
      return true;
    }

    if (sendingMethodToSave === SendingMethod.AUTO && timePattern.test(sendingTimeToSave.trim()) === false) {
      return true;
    }

    return false;
  }, [lineMessageToSave, emailMessageToSave, sendingMethodToSave, sendingTimeToSave]);

  const locationForReplacing = useMemo<Address>(() => {
    switch (eventScheduleV2.holdingMethod) {
      case EventHoldingMethod.UNDECIDED:
        return EVENT_HOLDING_METHOD_LABELS[eventScheduleV2.holdingMethod];
      case EventHoldingMethod.OFFLINE:
        return eventScheduleV2.location ?? EMPTY;
      case EventHoldingMethod.ONLINE:
        return eventScheduleV2.meetingURL ?? EMPTY;
      default:
        return EMPTY;
    }
  }, [eventScheduleV2]);

  const configForReplacingEventMessage = useMemo<ReplacingReservedWordsForEventMessage>(
    () => ({
      [ReservedWordsForEventMessageEnum.userName]: `${user.familyName} ${user.firstName}`,
      [ReservedWordsForEventMessageEnum.eventDate]: format(eventScheduleV2.startAtV2, 'yyyy/MM/dd'),
      [ReservedWordsForEventMessageEnum.eventStartAt]: format(eventScheduleV2.startAtV2, 'HH:mm'),
      [ReservedWordsForEventMessageEnum.eventEndAt]: format(eventScheduleV2.endAtV2, 'HH:mm'),
      [ReservedWordsForEventMessageEnum.eventLocation]: locationForReplacing,
      [ReservedWordsForEventMessageEnum.eventEntryForm]: `${HTTPS}://${DOMAINS[env]}${embedIdInPath(
        Path.ACCOUNT_EVENT_ENTRY_FORM,
        PATH_IDS,
        [base.baseCode, eventScheduleV2.eventScheduleId]
      )}`
    }),
    [base, eventScheduleV2, locationForReplacing, user]
  );

  const handleSaveClicked = useSafeCallback(
    async (sendingSendAt: Date, sendingStatusToSave: SendingStatus): Promise<void> => {
      setIsScreenLoaderShown(true);
      const messageId = messageV2?.messageId || uuid();

      const messageToSave = builder<MessageV2>()
        .messageId(messageId)
        .status(sendingStatusToSave)
        .messageType(messageType)
        .sendMethod(sendingMethodToSave)
        .sendAtV2(sendingSendAt)
        .emailMessage(emailMessageToSave)
        .lineMessage(lineMessageToSave)
        .eventScheduleV2(eventScheduleV2)
        .build();

      const messageRequest = builder<SaveMessagesV2ForAdminRequest>()
        .baseId(base.baseId)
        .messagesV2([messageToSave])
        .build();
      await commonRequest<SaveMessagesV2ForAdminRequest, SaveMessagesV2ForAdminResponse>(
        SAVE_MESSAGES_V2_FOR_ADMIN,
        messageRequest
      );
      setIsScreenLoaderShown(false);
      onClose(true);
    },
    [
      base,
      commonRequest,
      messageV2,
      eventScheduleV2,
      messageType,
      sendingMethodToSave,
      emailMessageToSave,
      lineMessageToSave,
      setIsScreenLoaderShown,
      onClose
    ]
  );

  const handleAutoSend = useSafeCallback((): void => {
    const sendAt = new Date(format(sendingDateToSave, 'yyyy-MM-dd') + ' ' + sendingTimeToSave);
    handleSaveClicked(sendAt, SendingStatus.WAITING);
  }, [handleSaveClicked, sendingDateToSave, sendingTimeToSave]);

  const handleDeleteClicked = useSafeCallback(async (): Promise<void> => {
    if (!messageV2) return;
    setIsScreenLoaderShown(true);
    const messageRequest = builder<DeleteMessageV2ForAdminRequest>()
      .baseId(base.baseId)
      .messageId(messageV2.messageId)
      .build();
    await commonRequest<DeleteMessageV2ForAdminRequest, DeleteMessageV2ForAdminResponse>(
      DELETE_MESSAGE_V2_FOR_ADMIN,
      messageRequest
    );
    setIsScreenLoaderShown(false);
    onClose(true);
  }, [base, commonRequest, messageV2, onClose, setIsScreenLoaderShown]);

  const handleManualSend = useSafeCallback((): void => {
    const sendAt = new Date();
    handleSaveClicked(sendAt, SendingStatus.WAITING);
  }, [handleSaveClicked]);

  const handleManualSendDraft = useSafeCallback((): void => {
    const sendAt = new Date();
    handleSaveClicked(sendAt, SendingStatus.SUSPENDED);
  }, [handleSaveClicked]);

  const handleModalClosed = useSafeCallback((): void => {
    onClose(false);
  }, [onClose]);

  const initialize = useSafeCallback((): void => {
    if (!messageV2) {
      setSendingMethodToSave(SendingMethod.AUTO);
      setSendingDateToSave(new Date());
      setSendingTimeToSave(EMPTY);
      setEmailMessageToSave(EMPTY);
      setLineMessageToSave(EMPTY);
      return;
    }

    const { sendMethod, sendAtV2, emailMessage, lineMessage } = messageV2;
    setSendingMethodToSave(sendMethod);
    setSendingDateToSave(sendAtV2!);
    setSendingTimeToSave(sendAtV2 ? format(sendAtV2, 'HH:mm') : EMPTY);
    setEmailMessageToSave(emailMessage ?? EMPTY);
    setLineMessageToSave(lineMessage ?? EMPTY);
  }, [
    messageV2,
    setSendingMethodToSave,
    setSendingDateToSave,
    setSendingTimeToSave,
    setEmailMessageToSave,
    setLineMessageToSave
  ]);

  useEffect(() => {
    initialize();
  }, [isOpen, initialize]);

  const footerLeftButtons = useMemo<React.ReactNode[]>(() => {
    const buttons: React.ReactNode[] = [];
    if (!messageV2) return buttons;
    if (sendingStatus === SendingStatus.COMPLETED || sendingStatus === SendingStatus.ERROR) return buttons;

    buttons.push(
      <IconButtonV2 key='dialog_trash' size='medium' icon={<DeleteOutlinedIcon />} onClick={handleDeleteClicked} />
    );

    return buttons;
  }, [handleDeleteClicked, messageV2, sendingStatus]);

  const footerRightButtons = useMemo<React.ReactNode[]>(() => {
    const buttons: React.ReactNode[] = [];
    buttons.push(<ButtonV2 key='dialog_cancel' label='キャンセル' onClick={handleModalClosed} />);

    if (readonly) return buttons;

    buttons.push(
      <ButtonV2
        key='dialog_temp'
        width={105}
        type='secondary'
        label='一時保存'
        disabled={disabledTempButton}
        onClick={() => handleManualSendDraft()}
      />
    );

    buttons.push(
      <ButtonV2
        key='dialog_send'
        width={105}
        type='primary'
        label={sendingMethodToSave === SendingMethod.AUTO ? '保存' : '送信'}
        disabled={disabledSendButton}
        onClick={() => (sendingMethodToSave === SendingMethod.AUTO ? handleAutoSend() : handleManualSend())}
      />
    );

    return buttons;
  }, [
    handleModalClosed,
    handleAutoSend,
    handleManualSend,
    handleManualSendDraft,
    readonly,
    sendingMethodToSave,
    disabledTempButton,
    disabledSendButton
  ]);

  return (
    <DialogV2
      width={800}
      height={600}
      isOpen={isOpen}
      headerLabel={getHeaderLabel(name, sendingStatus)}
      buttonsOnTheLeft={footerLeftButtons}
      buttonsOnTheRight={footerRightButtons}
      onClose={handleModalClosed}
    >
      <MessageContent readonly={readonly}>
        {messageType === MessageType.REMIND && (
          <MessageWrapper>
            <LabelV2 text='スケジュール日時' />
            <StyledLabel
              readonly={readonly}
              text={toDateTimeDurationStr(eventScheduleV2.startAtV2, eventScheduleV2.endAtV2, Language.JAPANESE)}
            />
          </MessageWrapper>
        )}

        {!readonly && (
          <FullInputDiv>
            <LabelV2 text='送信方法' />
            <RadioBoxWrapper>
              <RadioBoxV2
                options={Object.values(SendingMethod).filter(v => v !== SendingMethod.NOT_SEND)}
                labels={MESSAGE_SENDING_METHOD}
                onChange={setSendingMethodToSave}
                value={sendingMethodToSave}
                gap={40}
              />
            </RadioBoxWrapper>
          </FullInputDiv>
        )}
        {sendingMethodToSave === SendingMethod.AUTO && !readonly && (
          <DateWrapper>
            <InputDateWithLabelV2 required text='送信日時' value={sendingDateToSave} onChange={setSendingDateToSave} />
            <TextFieldV2 placeholder='時刻' value={sendingTimeToSave} onChange={setSendingTimeToSave} />
          </DateWrapper>
        )}
        {readonly && (
          <CardWithLabelV2
            label='送信日時'
            text={toDateTimeStr(
              new Date(format(sendingDateToSave, 'yyyy-MM-dd') + EMPTY_HALF_WIDTH + sendingTimeToSave),
              Language.JAPANESE
            )}
          />
        )}
        {!readonly && (
          <>
            <InputWithPreview
              base={base}
              config={configForReplacingEventMessage}
              label='LINE メッセージ'
              defaultValue={messageV2?.lineMessage ?? EMPTY}
              value={lineMessageToSave}
              onChange={setLineMessageToSave}
            />
            <InputWithPreview
              base={base}
              config={configForReplacingEventMessage}
              label='EMail メッセージ'
              defaultValue={messageV2?.emailMessage ?? EMPTY}
              value={emailMessageToSave}
              onChange={setEmailMessageToSave}
            />
          </>
        )}
        {readonly && (
          <>
            <CardWithLabelV2 label='LINE メッセージ' text={lineMessageToSave} />
            <CardWithLabelV2 label='Email メッセージ' text={emailMessageToSave} />
          </>
        )}
      </MessageContent>
      <ScreenLoaderV2 loading={isScreenLoaderShown} />
    </DialogV2>
  );
});

SendMessageModal.displayName = 'SendMessageModal';
export default SendMessageModal;

const MessageContent = styled.div<{ readonly: boolean }>`
  background: ${({ readonly }) =>
    readonly ? themeV2.mixins.v2.color.background.white : themeV2.mixins.v2.color.background.offWhite};
  border-radius: 8px;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;

const RadioBoxWrapper = styled.div`
  margin-left: ${themeV2.mixins.v2.spacing * 1.5}px;
`;

const FullInputDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateWrapper = styled.div`
  width: 48%;
  display: flex;
  gap: ${themeV2.mixins.v2.spacing}px;
  align-items: flex-end;
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const StyledLabel = styled(LabelV2)<{ readonly: boolean }>`
  ${themeV2.mixins.v2.typography.body.large};
  width: 100%;
  display: flex;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  border-radius: 8px;
  color: ${themeV2.mixins.v2.color.font.black};
  background: ${({ readonly }) =>
    readonly ? themeV2.mixins.v2.color.background.offWhite : themeV2.mixins.v2.color.background.white};
`;
