import {
  Breadcrumb,
  BreadcrumbTrailV2,
  ButtonV2,
  Component,
  MoreHorizMenu,
  MoreHorizMenuButton,
  Pagenate,
  ScreenLoaderV2,
  TabComponent,
  TabsV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AccessV2,
  BILLING_ID_V2,
  BaseDto,
  BillingIdV2,
  BillingLogSortColumn,
  BillingLogV2,
  BillingV2,
  ContractUserV2,
  DELETE_BILLINGS_V2_FOR_ADMIN,
  DeleteBillingsV2ForAdminRequest,
  DeleteBillingsV2ForAdminResponse,
  FETCH_ACCESSES_V2_FOR_ADMIN,
  FETCH_BILLING_V2_FOR_ADMIN,
  FETCH_SPACE_RESERVATIONS_BY_USER_FOR_ADMIN,
  FetchAccessesV2ForAdminRequest,
  FetchAccessesV2ForAdminResponse,
  FetchBillingV2ForAdminRequest,
  FetchBillingV2ForAdminResponse,
  FetchSpaceReservationsByUserForAdminRequest,
  FetchSpaceReservationsByUserForAdminResponse,
  SAVE_BILLING_LOG_V2_FOR_ADMIN,
  SaveBillingLogV2ForAdminRequest,
  SaveBillingLogV2ForAdminResponse,
  Sort,
  SpaceReservation,
  SpaceReservationUsageSortColumn,
  User
} from '@atomica.co/irori';
import { Count, Index, Operation } from '@atomica.co/types';
import {
  MINUS_ONE,
  ONE,
  SLASH,
  ZERO,
  builder,
  embedIdInPath,
  hasLength,
  isUndefined,
  toFirstDayOfMonth,
  uuid
} from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { addSeconds } from 'date-fns';
import React, { useEffect, useMemo, useRef } from 'react';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import { BILLING_DETAIL_PATHS } from '../../constants/billing-v2-const';
import { BillingCSV, convertToBillingCSV } from '../../converters/export-converter';
import { BillingDetailPathEnum, BillingDetailScreenNameEnum } from '../../enums/billing-v2-enum';
import { CSVTemplate } from '../../models/common-model';
import useCachedBillingList, { CachedBillingInfo } from '../../redux/hooks/useCachedBillingList';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import CommonRequest from '../../requests/common-request';
import { PATH_IDS, Path } from '../../router/Routes';
import { BILLING_DELETED, BILLING_HEADERS } from '../../texts/billing-v2-text';
import { updateSortCol } from '../../utils/contract-v2-util';
import BillingAccesses from './billing-accesses/BillingAccesses';
import BillingDetails from './billing-details/BillingDetails';
import BillingLogs from './billing-logs/BillingLogs';
import BillingSpaceUsages from './billing-space-usages/BillingSpaceUsages';

const LIMIT = 1;

const BILLING_DETAILS_HEADER_HEIGHT = 100;
const BILLING_DETAILS_CONSOLE_FOOTER_HEIGHT = 72;
const BILLING_DETAILS_CONTENT_MAX_WIDTH = 1280;
const BILLING_DETAILS_CONTENT_MIN_WIDTH = 756;

interface P {
  base: BaseDto;
  user: User;
}

const BillingDetailsScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;

  const { path, params, openPath } = usePath();
  const { cachedBillingInfo, saveCachedBillingInfo, clearCachedBillingInfo } = useCachedBillingList();
  const billingList = useRef<CachedBillingInfo>(cachedBillingInfo);
  const { commonRequest } = useCommonRequest();

  const billingId = useMemo<BillingIdV2>(() => params[BILLING_ID_V2], [params]);
  const initialTabIdx = useMemo<Index>(() => {
    const lastOfPath = path?.split(SLASH).pop() as BillingDetailPathEnum;
    return Object.values(BillingDetailPathEnum).indexOf(lastOfPath) || ZERO;
  }, [path]);

  const unmountRef = useUnmountRef();
  const [confirmed, setConfirmed] = useSafeState<boolean>(unmountRef, false);
  const [showLoader, setShowLoader] = useSafeState<boolean>(unmountRef, false);
  const [sortKey, setSortKey] = useSafeState<BillingLogSortColumn>(unmountRef, BillingLogSortColumn.CREATED_AT);
  const [sort, setSort] = useSafeState<Sort>(unmountRef, Sort.ASC);
  const [displayBillingIdx, setDisplayBillingIdx] = useSafeState<Index>(
    unmountRef,
    billingList.current.selectedBillingIds.indexOf(billingId)
  );
  const [selectedTabIdx, setSelectedTabIdx] = useSafeState<Index>(unmountRef, initialTabIdx);
  const [billing, setBilling] = useSafeState<BillingV2>(unmountRef);
  const [reservations, setReservations] = useSafeState<SpaceReservation[]>(unmountRef, []);
  const [accesses, setAccesses] = useSafeState<AccessV2[]>(unmountRef, []);
  const [csvHeaders, setCsvHeaders] = useSafeState<CSVTemplate[]>(unmountRef, []);
  const [csvContent, setCsvContent] = useSafeState<BillingCSV[]>(unmountRef, []);

  const count = useMemo<Count>(() => {
    if (hasLength(billingList.current.selectedBillingIds)) return billingList.current.selectedBillingIds.length;
    return !billingList.current.totalRecordCount ? 1 : billingList.current.totalRecordCount;
  }, []);

  const handleBillingLogSortColClicked = useSafeCallback(
    (sortCol: BillingLogSortColumn | undefined): void => {
      updateSortCol<BillingLogSortColumn>(sortCol, setSortKey, setSort);
    },
    [setSortKey, setSort]
  );

  const tabComponents = useMemo<TabComponent[]>(() => {
    if (!billing) return [];
    return [
      {
        label: BillingDetailScreenNameEnum.DETAIL,
        component: (
          <BillingDetails
            confirmed={confirmed}
            billingId={billingId}
            base={base}
            user={user}
            billing={billing}
            reservations={reservations}
            setConfirmed={setConfirmed}
            setSelectedTabIdx={setSelectedTabIdx}
          />
        )
      },
      {
        label: BillingDetailScreenNameEnum.SPACE,
        component: (
          <BillingSpaceUsages
            sortKey={SpaceReservationUsageSortColumn.START_AT}
            sort={sort}
            billing={billing}
            reservations={reservations}
            base={base}
          />
        )
      },
      {
        label: BillingDetailScreenNameEnum.ACCESS,
        component: <BillingAccesses accesses={accesses} />
      },
      {
        label: BillingDetailScreenNameEnum.LOGS,
        component: (
          <BillingLogs
            base={base}
            sortKey={sortKey}
            sort={sort}
            billingId={billingId}
            onClick={handleBillingLogSortColClicked}
          />
        )
      }
    ];
  }, [
    base,
    user,
    billing,
    billingId,
    confirmed,
    reservations,
    accesses,
    sort,
    sortKey,
    setConfirmed,
    setSelectedTabIdx,
    handleBillingLogSortColClicked
  ]);

  const detailTabIdx = useMemo<Index>(() => {
    return tabComponents.findIndex(component => {
      return component.label === BillingDetailScreenNameEnum.DETAIL;
    });
  }, [tabComponents]);

  const fetchBilling = useSafeCallback(async (): Promise<BillingV2> => {
    const request = builder<FetchBillingV2ForAdminRequest>().baseId(base.baseId).billingId(billingId).build();
    const response = await commonRequest<FetchBillingV2ForAdminRequest, FetchBillingV2ForAdminResponse>(
      FETCH_BILLING_V2_FOR_ADMIN,
      request
    );
    return response.billing;
  }, [base, billingId, commonRequest]);

  const filteredByContractMember = useSafeCallback(
    (spaceReservations: SpaceReservation[], contractUsers: ContractUserV2[]): SpaceReservation[] => {
      return spaceReservations.filter(spaceReservation => {
        return contractUsers.some(contractUser => {
          if (isUndefined(contractUser.startDate) || isUndefined(spaceReservation.createdAt)) return false;
          // 予約時に契約メンバーだったユーザーによる予約を抽出
          return (
            spaceReservation.createdUser?.userId === contractUser.user?.userId &&
            contractUser.startDate <= spaceReservation.createdAt &&
            (contractUser.endDate ? spaceReservation.createdAt <= contractUser.endDate : true)
          );
        });
      });
    },
    []
  );

  const fetchSpaceReservations = useSafeCallback(
    async (billing: BillingV2): Promise<SpaceReservation[]> => {
      const contractUsers = billing?.contract?.contractUsers;
      if (!hasLength(contractUsers)) return [];

      const userIds = contractUsers
        .filter(contractUser => contractUser.user)
        .map(contractUser => contractUser.user!.userId);

      const request = builder<FetchSpaceReservationsByUserForAdminRequest>()
        .baseId(base.baseId)
        .userIds(userIds)
        .fromDate(toFirstDayOfMonth(billing.cutoffDate, ZERO))
        .toDate(addSeconds(toFirstDayOfMonth(billing.cutoffDate, ONE), MINUS_ONE))
        .build();

      const response = await CommonRequest.call<
        FetchSpaceReservationsByUserForAdminRequest,
        FetchSpaceReservationsByUserForAdminResponse
      >(FETCH_SPACE_RESERVATIONS_BY_USER_FOR_ADMIN, request);

      return filteredByContractMember(response.spaceReservations, contractUsers);
    },
    [base.baseId]
  );

  const fetchAccesses = useSafeCallback(
    async (billing: BillingV2): Promise<AccessV2[]> => {
      if (!billing || !billing.contract || !billing.contract.contractUsers) return [];

      const userIds = billing.contract.contractUsers
        .filter(contractUser => !!contractUser.user)
        .map(contractUser => contractUser.user!.userId);
      if (!hasLength(userIds)) return [];

      const start = toFirstDayOfMonth(new Date(billing.cutoffDate), ZERO);
      const end = addSeconds(toFirstDayOfMonth(new Date(billing.cutoffDate), ONE), MINUS_ONE);
      const request = builder<FetchAccessesV2ForAdminRequest>()
        .baseId(base.baseId)
        .userIds(userIds)
        .start(start)
        .end(end)
        .build();
      const response = await commonRequest<FetchAccessesV2ForAdminRequest, FetchAccessesV2ForAdminResponse>(
        FETCH_ACCESSES_V2_FOR_ADMIN,
        request
      );
      return response.accesses;
    },
    [base, commonRequest]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    const billing = await fetchBilling();
    const [reservations, accesses] = await Promise.all([fetchSpaceReservations(billing), fetchAccesses(billing)]);
    setBilling(billing);
    setReservations(reservations);
    setAccesses(accesses);
  }, [fetchBilling, fetchSpaceReservations, fetchAccesses, setBilling, setReservations, setAccesses]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const saveBillingLog = useSafeCallback(
    async (operation: Operation): Promise<void> => {
      const billingLog = builder<BillingLogV2>()
        .billingLogId(uuid())
        .billing(billing)
        .operation(operation)
        .createdUser(user)
        .build();
      const request = builder<SaveBillingLogV2ForAdminRequest>().baseId(base.baseId).billingLogs([billingLog]).build();
      await commonRequest<SaveBillingLogV2ForAdminRequest, SaveBillingLogV2ForAdminResponse>(
        SAVE_BILLING_LOG_V2_FOR_ADMIN,
        request
      );
    },
    [base, billing, commonRequest, user]
  );

  const deleteBilling = useSafeCallback(async (): Promise<void> => {
    setShowLoader(true);
    const request = builder<DeleteBillingsV2ForAdminRequest>().baseId(base.baseId).billingIds([billingId]).build();
    const response = await commonRequest<DeleteBillingsV2ForAdminRequest, DeleteBillingsV2ForAdminResponse>(
      DELETE_BILLINGS_V2_FOR_ADMIN,
      request
    );

    if (!hasLength(response.billingIds)) {
      setShowLoader(false);
      return;
    }

    await saveBillingLog(BILLING_DELETED(billingId));
    clearCachedBillingInfo();
    openPath(embedIdInPath(Path.BILLING_LIST_V2, PATH_IDS, [base.baseCode]));
    setShowLoader(false);
  }, [billingId, saveBillingLog, base.baseCode, base.baseId, openPath, clearCachedBillingInfo, setShowLoader]);

  const subMenus = useMemo<MoreHorizMenu[]>(() => {
    return [{ label: '削除', onClick: deleteBilling }];
  }, [deleteBilling]);

  const openBillingDetailsScreen = useSafeCallback(
    (selectedTabIdx: Index, selectedBillingId?: BillingIdV2): void => {
      const billingIdToOpen = selectedBillingId ? selectedBillingId : billingId;
      const path = BILLING_DETAIL_PATHS[selectedTabIdx];
      openPath(embedIdInPath(path, PATH_IDS, [base.baseCode, billingIdToOpen]));
    },
    [billingId, openPath, base]
  );

  useEffect(() => {
    openBillingDetailsScreen(selectedTabIdx);
  }, [openBillingDetailsScreen, selectedTabIdx]);

  useEffect(() => {
    return () => {
      clearCachedBillingInfo();
    };
  }, [clearCachedBillingInfo]);

  const toPreviousPage = useSafeCallback((): void => {
    setDisplayBillingIdx(displayIdx => {
      const nextDisplayIdx = displayIdx - LIMIT;
      const selectedBillingId = billingList.current.selectedBillingIds[nextDisplayIdx];
      const selectedBilling = billingList.current.billings[nextDisplayIdx];
      setBilling(selectedBilling);
      openBillingDetailsScreen(selectedTabIdx, selectedBillingId);
      return nextDisplayIdx;
    });
  }, [setDisplayBillingIdx, openBillingDetailsScreen, selectedTabIdx]);

  const toNextPage = useSafeCallback((): void => {
    setDisplayBillingIdx(displayIdx => {
      const nextDisplayIdx = displayIdx + LIMIT;
      const selectedBillingId = billingList.current.selectedBillingIds[nextDisplayIdx];
      const selectedBilling = billingList.current.billings[nextDisplayIdx];
      setBilling(selectedBilling);
      openBillingDetailsScreen(selectedTabIdx, selectedBillingId);
      return nextDisplayIdx;
    });
  }, [setDisplayBillingIdx, openBillingDetailsScreen, selectedTabIdx]);

  const backToBillingList = useSafeCallback((): void => {
    saveCachedBillingInfo(billingList.current);
  }, [saveCachedBillingInfo]);

  const breadcrumbs = useMemo(
    (): Breadcrumb[] => [
      {
        label: '請求一覧へ',
        path: embedIdInPath(Path.BILLING_LIST_V2, PATH_IDS, [base.baseCode]),
        handlePathChanged: backToBillingList
      }
    ],
    [backToBillingList, base]
  );

  const forwardToEditBillingScreen = useSafeCallback((): void => {
    saveCachedBillingInfo(billingList.current);
    openPath(embedIdInPath(Path.EDIT_BILLING_V2, PATH_IDS, [base.baseCode, billingId]));
  }, [base, billingId, billingList, openPath, saveCachedBillingInfo]);

  const exportCSV = useSafeCallback(
    (selectedBilling: BillingV2[]): void => {
      setCsvHeaders(BILLING_HEADERS);
      setCsvContent(convertToBillingCSV(selectedBilling));
    },
    [setCsvHeaders, setCsvContent]
  );

  return (
    <Component className='billing-details-screen' loading={!billing} style={styleForComponent}>
      <Container>
        {!!billing && (
          <Content>
            <HeaderWrapper>
              <Header>
                <BreadcrumbTrailV2 breadcrumbs={breadcrumbs} />

                <Wrapper>
                  <Title>{billing.billingNo} の詳細</Title>

                  {selectedTabIdx === detailTabIdx && (
                    <FunctionWrapper>
                      {confirmed ? (
                        <CSVLink data={csvContent} headers={csvHeaders}>
                          <ButtonV2 type='primary' label={'CSVダウンロード'} onClick={() => exportCSV([billing])} />
                        </CSVLink>
                      ) : (
                        <ButtonV2 type='primary' label={'編集'} onClick={forwardToEditBillingScreen} />
                      )}
                      <MoreHorizMenuButton menuButtons={subMenus} />
                    </FunctionWrapper>
                  )}
                </Wrapper>
              </Header>
            </HeaderWrapper>

            <Body>
              <TabsV2 selectedTabIdx={selectedTabIdx} tabComponents={tabComponents} onChange={setSelectedTabIdx} />
            </Body>
          </Content>
        )}

        <Footer>
          <Pagenate
            shape='rect'
            limit={LIMIT}
            offset={displayBillingIdx < 0 ? 0 : displayBillingIdx}
            count={count}
            clickable={count !== ONE}
            onClickBack={toPreviousPage}
            onClickForward={toNextPage}
          />
        </Footer>
      </Container>

      <ScreenLoaderV2 loading={showLoader} />
    </Component>
  );
});

BillingDetailsScreen.displayName = 'BillingDetailsScreen';
export default BillingDetailsScreen;

const styleForComponent: CSSProperties = {
  height: '100%'
};

const Container = styled.div`
  width: 100%;
  height: calc(100% - ${BILLING_DETAILS_CONSOLE_FOOTER_HEIGHT}px);
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px ${themeV2.mixins.v2.spacing * 3}px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  height: ${BILLING_DETAILS_HEADER_HEIGHT}px;
  max-width: ${BILLING_DETAILS_CONTENT_MAX_WIDTH}px;
  min-width: ${BILLING_DETAILS_CONTENT_MIN_WIDTH}px;
  margin: 0 auto;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
  padding: ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 3}px 0;
  width: 100%;
  max-width: ${BILLING_DETAILS_CONTENT_MAX_WIDTH}px;
  min-width: ${BILLING_DETAILS_CONTENT_MIN_WIDTH}px;
  z-index: 10;
`;

const Body = styled.div`
  width: 100%;
  flex: 1;
  max-width: ${BILLING_DETAILS_CONTENT_MAX_WIDTH}px;
  min-width: ${BILLING_DETAILS_CONTENT_MIN_WIDTH}px;
  margin: 0 auto;
  padding: 0 ${themeV2.mixins.v2.spacing * 3}px 0;
  height: calc(100% - ${BILLING_DETAILS_HEADER_HEIGHT + BILLING_DETAILS_CONSOLE_FOOTER_HEIGHT}px);
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FunctionWrapper = styled.div`
  display: flex;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  position: relative;
`;

const Title = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.xLarge};
  min-height: 36px;
`;

const Footer = styled.div`
  position: fixed;
  z-index: ${themeV2.zIndex.modal};
  right: 0;
  transition: ${themeV2.transitions.create(['width', 'right'], {
    easing: themeV2.transitions.easing.easeOut,
    duration: themeV2.transitions.duration.enteringScreen
  })};
  width: 100%;
  bottom: 0;
`;
