import {
  ButtonV2,
  CardWithLabelV2,
  ChipButton,
  IconButtonV2,
  LabelV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AuthorityCategoryDef,
  AuthorityDefCategory,
  AuthorityId,
  BaseAuthority,
  BaseDto,
  BaseFunctionToggleCode,
  BaseIdentification,
  ContractV2,
  FETCH_CONTRACT_V2_BY_USER_FOR_ADMIN,
  FetchContractV2ByUserForAdminRequest,
  FetchContractV2ByUserForAdminResponse,
  SAVE_USER_SKILL_TAGS_BY_NAME_FOR_ADMIN,
  SaveUserSkillTagsByNameForAdminRequest,
  SaveUserSkillTagsByNameForAdminResponse,
  SkillTag,
  User,
  isBaseFunctionToggleEnabled
} from '@atomica.co/irori';
import { Description, Word } from '@atomica.co/types';
import { EMPTY, FIRST_INDEX, PREFECTURE_NAMES, builder, copyText, embedIdInPath, hasLength } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { VisibilityOutlined } from '@material-ui/icons';
import EditOutlined from '@material-ui/icons/EditOutlined';
import FileCopyOutline from '@material-ui/icons/FileCopyOutlined';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import iconCaretDown from '../../../../assets/icon/caret_down.svg';
import iconCaretUp from '../../../../assets/icon/caret_up.svg';
import SkillTags from '../../../../components/tags/SkillTags';
import { SUCCESS } from '../../../../constants/snackbar-const';
import useCommonRequest from '../../../../redux/hooks/useCommonRequest';
import usePath from '../../../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../../../router/Routes';
import { EMAIL_COPIED } from '../../../../texts/snackbar-text';
import { USER_GENDER_LABELS } from '../../../../texts/user-text';
import { getBaseIdentification, getBasePICV2, toFullName } from '../../../../utils/user-util';
import RegisterPersonInChargeModal from '../../modal/RegisterPersonInChargeModal';
import RegisterUserIdentificationModal from '../../modal/RegisterUserIdentificationModal';

interface P {
  selectedUser: User;
  base: BaseDto;
  initialize?: () => Promise<void>;
}

const buildOtherName = (name: Word | undefined, isOther: boolean | undefined, otherDesc: Word | undefined): Word => {
  if (!isOther) return name ?? EMPTY;
  return otherDesc ? `その他【${otherDesc}】` : 'その他';
};

const ProfileSectionV2: React.FC<P> = props => {
  const { selectedUser, base, initialize } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { openPath } = usePath();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();

  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);

  const [resourceAuthorityIds, setResourceAuthorityIds] = useSafeState<AuthorityId[]>(
    unmountRef,
    getBasePICV2(selectedUser, base, AuthorityDefCategory.RESOURCE_AUTHORITY).map(pic => pic.authority!.authorityId)
  );
  const [rankAuthorityIds, setRankAuthorityIds] = useSafeState<AuthorityId[]>(
    unmountRef,
    getBasePICV2(selectedUser, base, AuthorityDefCategory.RANK).map(pic => pic.authority!.authorityId)
  );

  const [isSkillTagsEditable, setIsSkillTagsEditable] = useSafeState<boolean>(unmountRef, false);
  const [isRegisterResourceAuthorityModalOpen, setIsRegisterResourceAuthorityModalOpen] = useSafeState<boolean>(
    unmountRef,
    false
  );
  const resourceAuthorities = useMemo<BaseAuthority[]>(() => {
    if (!base.authorities) return [];
    return base.authorities.filter(
      auth => auth.authorityDef?.category?.categoryCode === AuthorityDefCategory.RESOURCE_AUTHORITY
    );
  }, [base]);

  const category = useMemo<AuthorityCategoryDef | undefined>(() => {
    if (!hasLength(resourceAuthorities)) return;
    return resourceAuthorities[FIRST_INDEX].authorityDef?.category;
  }, [resourceAuthorities]);

  const initialAuthorityIds = useMemo<AuthorityId[]>(() => {
    const pics = getBasePICV2(selectedUser, base, AuthorityDefCategory.RESOURCE_AUTHORITY);
    return pics.map(pic => pic.authority!.authorityId);
  }, [base, selectedUser]);

  const [isUserIdentificationEditable, setIsUserIdentificationEditable] = useSafeState<boolean>(unmountRef, false);
  const [isRegisterUserIdentificationModalOpen, setIsRegisterUserIdentificationModalOpen] = useSafeState<boolean>(
    unmountRef,
    false
  );
  const [isRegisterPersonInchargeForRankModalOpen, setIsRegisterPersonInchargeForRankModalOpen] = useSafeState<boolean>(
    unmountRef,
    false
  );

  const [contract, setContract] = useSafeState<ContractV2 | undefined>(unmountRef);

  const initialLoad = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchContractV2ByUserForAdminRequest>()
      .baseId(base.baseId)
      .userId(selectedUser.userId)
      .build();
    const { contract } = await commonRequest<
      FetchContractV2ByUserForAdminRequest,
      FetchContractV2ByUserForAdminResponse
    >(FETCH_CONTRACT_V2_BY_USER_FOR_ADMIN, request);
    setContract(contract);
  }, [base, commonRequest, selectedUser, setContract]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  const [isProfileDetailsOpen, setIsProfileDetailsOpen] = useSafeState<boolean>(unmountRef, false);

  const initialSkillTags = useMemo<SkillTag[]>(
    () =>
      hasLength(selectedUser.userSkillTags)
        ? selectedUser
            .userSkillTags!.filter(tag => tag.base?.baseId === base?.baseId || tag.base === null)
            .map(tag => tag.skillTag!)
        : [],
    [selectedUser, base]
  );

  const [skillTags, setSkillTags] = useSafeState<SkillTag[]>(unmountRef, initialSkillTags);
  const [savedSkillTags, setSavedSkillTags] = useSafeState<SkillTag[]>(unmountRef, initialSkillTags);

  const hasUserIdentificationStatus = useMemo<boolean>(
    () => isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USER_IDENTIFICATION),
    [base]
  );

  const hasResourceAuthority = useMemo<boolean>(
    () =>
      isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_RESOURCE_AUTHORITY) &&
      hasLength(resourceAuthorities),
    [base, resourceAuthorities]
  );

  const hasUserRankAuthority = useMemo<boolean>(
    () => isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USER_RANK),
    [base]
  );

  const { memberRankAuthorities, memberRankCategoryDef } = useMemo<{
    memberRankAuthorities: BaseAuthority[];
    memberRankCategoryDef: AuthorityCategoryDef;
  }>(() => {
    const memberRankAuthorities = (base.authorities ?? []).filter(
      authority => authority.authorityDef?.category?.categoryCode === AuthorityDefCategory.RANK
    );
    const memberRankCategoryDef =
      hasLength(memberRankAuthorities) && memberRankAuthorities[FIRST_INDEX].authorityDef
        ? memberRankAuthorities[FIRST_INDEX].authorityDef.category!
        : builder<AuthorityCategoryDef>()
            .categoryCode(AuthorityDefCategory.RANK)
            .categoryName('会員ランク')
            .isMultiple(false)
            .build();
    return { memberRankAuthorities, memberRankCategoryDef };
  }, [base]);

  const resourceCategoryNames = useMemo<Description>(
    () =>
      resourceAuthorityIds
        .map(id => resourceAuthorities.find(auth => auth.authorityId === id))
        .filter(auth => !!auth)
        .map(auth => auth?.authorityName)
        .join(', '),
    [resourceAuthorities, resourceAuthorityIds]
  );

  const memberRankNames = useMemo<Description>(
    () =>
      rankAuthorityIds
        .map(id => memberRankAuthorities.find(auth => auth.authorityId === id))
        .filter(auth => !!auth)
        .map(auth => auth?.authorityName)
        .join(', '),
    [memberRankAuthorities, rankAuthorityIds]
  );

  const baseIdentification = useMemo<BaseIdentification | undefined>(() => {
    return getBaseIdentification(selectedUser, base);
  }, [base, selectedUser]);

  const { userDivName, hasCompanyName, isStudent, userStudentDivName } = useMemo(() => {
    const { userDiv, userStudentDiv } = selectedUser;

    const hasCompanyName = userDiv?.userDivDef?.hasCompanyName ?? false;
    const isStudent = userDiv?.userDivDef?.isStudent ?? false;

    const userDivName = buildOtherName(userDiv?.userDivName, userDiv?.userDivDef?.hasOther, selectedUser.userDivOther);
    const userStudentDivName = buildOtherName(
      userStudentDiv?.userStudentDivName,
      userStudentDiv?.userStudentDivDef?.hasOther,
      selectedUser.userStudentDivOther
    );

    return { userDivName, hasCompanyName, isStudent, userStudentDivName };
  }, [selectedUser]);

  const userInflowSourceName = useMemo<Word>(() => {
    const infrowSource = selectedUser.userInflowSource;
    return buildOtherName(
      infrowSource?.userInflowSourceName,
      infrowSource?.userInflowSourceDef?.hasOther,
      selectedUser.userInflowSourceOther
    );
  }, [selectedUser]);

  // const userUsagePurposeName = useMemo<Word>(() => {
  //   const usagePurpose = selectedUser.userUsagePurpose;
  //   return buildOtherName(
  //     usagePurpose?.userUsagePurposeName,
  //     usagePurpose?.userUsagePurposeDef?.hasOther,
  //     selectedUser.userUsagePurposeOther
  //   );
  // }, [selectedUser]);

  // const familyMemberNamesStr = useMemo((): Name => {
  //   return getFamilyMemberNamesStr(selectedUser.userFamilyMemberMappings);
  // }, [selectedUser]);

  const copyEmail = useSafeCallback((): void => {
    copyText(selectedUser.email);
    enqueueSnackbar(EMAIL_COPIED, { variant: SUCCESS });
  }, [selectedUser, enqueueSnackbar]);

  const handleSaveClicked = useSafeCallback(async (): Promise<void> => {
    setSaving(true);

    const tagNames = skillTags.map(tag => tag.name);
    const userSkillTagsByNameRequest = builder<SaveUserSkillTagsByNameForAdminRequest>()
      .baseId(base.baseId)
      .tagNames(tagNames)
      .userId(selectedUser.userId)
      .build();
    await commonRequest<SaveUserSkillTagsByNameForAdminRequest, SaveUserSkillTagsByNameForAdminResponse>(
      SAVE_USER_SKILL_TAGS_BY_NAME_FOR_ADMIN,
      userSkillTagsByNameRequest
    );

    setSavedSkillTags(skillTags);
    setSaving(false);
    setIsSkillTagsEditable(false);
  }, [base, commonRequest, setSavedSkillTags, setSaving, skillTags, selectedUser, setIsSkillTagsEditable]);

  const handleCancelClicked = useSafeCallback((): void => {
    setIsSkillTagsEditable(false);
    setSkillTags(savedSkillTags);
  }, [savedSkillTags, setIsSkillTagsEditable, setSkillTags]);

  const goToContract = useSafeCallback(
    (contract: ContractV2): void => {
      openPath(embedIdInPath(Path.CONTRACT_DETAILS_V2_DETAIL, PATH_IDS, [base.baseCode, contract.contractId]));
    },
    [openPath, base]
  );

  const handleRegisterResourceAuthorityModalOpened = useSafeCallback((): void => {
    setIsRegisterResourceAuthorityModalOpen(true);
  }, [setIsRegisterResourceAuthorityModalOpen]);

  const handleModalClosed = useSafeCallback(
    async (setIsModalOpen: (isModalOpen: boolean) => void): Promise<void> => {
      setIsModalOpen(false);
      if (initialize) await initialize();
    },
    [initialize]
  );

  const handleResourceAuthorityIdsUpdated = useSafeCallback(
    (resourceAuthorityIds: AuthorityId[] | undefined): void => {
      if (hasLength(resourceAuthorityIds)) setResourceAuthorityIds(resourceAuthorityIds);
      handleModalClosed(setIsRegisterResourceAuthorityModalOpen);
    },
    [setRankAuthorityIds, handleModalClosed, setIsRegisterResourceAuthorityModalOpen]
  );

  const handleRankAuthorityIdsUpdated = useSafeCallback(
    (rankAuthorityIds: AuthorityId[] | undefined): void => {
      if (hasLength(rankAuthorityIds)) setRankAuthorityIds(rankAuthorityIds);
      handleModalClosed(setIsRegisterPersonInchargeForRankModalOpen);
    },
    [setRankAuthorityIds, handleModalClosed, setIsRegisterPersonInchargeForRankModalOpen]
  );

  const handleRegisterPersonInchargeForRankModalOpened = useSafeCallback(async (): Promise<void> => {
    setIsRegisterPersonInchargeForRankModalOpen(true);
  }, [setIsRegisterPersonInchargeForRankModalOpen]);

  const handleRegisterUserIdentificationModalOpened = useSafeCallback(
    async (editable: boolean): Promise<void> => {
      setIsRegisterUserIdentificationModalOpen(true);
      setIsUserIdentificationEditable(editable);
    },
    [setIsRegisterUserIdentificationModalOpen, setIsUserIdentificationEditable]
  );

  const handleDetailButtonClicked = useSafeCallback((): void => {
    setIsProfileDetailsOpen(!isProfileDetailsOpen);
  }, [isProfileDetailsOpen, setIsProfileDetailsOpen]);

  return (
    <ProfileContainer>
      <Heading>プロフィール</Heading>
      <InformationTopWrapper>
        {selectedUser.photoURL && (
          <ImageWrapper>
            <Image src={selectedUser.photoURL} />
          </ImageWrapper>
        )}
        <InformationRightWrapper>
          <NameText>{toFullName(selectedUser)}</NameText>
          {selectedUser.companyName && <CompanyNameText>{`${selectedUser.companyName}のメンバー`}</CompanyNameText>}

          <EmailWrapper>
            <EmailText>{selectedUser.email}</EmailText>
            <IconButtonV2 icon={<FileCopyOutline />} onClick={copyEmail} />
          </EmailWrapper>
          {selectedUser.phone && <PhoneNumberText>{selectedUser.phone}</PhoneNumberText>}
          {contract && <SecondaryText onClick={() => goToContract(contract)}>契約を確認</SecondaryText>}
        </InformationRightWrapper>
      </InformationTopWrapper>

      <div>
        <LabelV2 text='得意なこと' />
        <SkillTags
          editable={isSkillTagsEditable}
          addNewTags
          base={base}
          skillTags={skillTags}
          setSkillTags={setSkillTags}
        />

        {!isSkillTagsEditable && (
          <OutlineChipRow>
            <ChipButton
              font='bold'
              label='得意なことを編集'
              icon={<EditOutlined />}
              variant='outlined'
              onClick={() => setIsSkillTagsEditable(true)}
            />
          </OutlineChipRow>
        )}

        {isSkillTagsEditable && (
          <ButtonWrapper>
            <ButtonV2 label='キャンセル' onClick={handleCancelClicked} />
            <ButtonV2 disabled={saving} type='primary' label='保存' onClick={handleSaveClicked} />
          </ButtonWrapper>
        )}
      </div>

      {isProfileDetailsOpen && (
        <ProfileDetailContainer>
          <InformationRowWrapper isHalf={!hasResourceAuthority}>
            {hasResourceAuthority && (
              <>
                <CardWithLabelV2
                  label='リソース権限'
                  text={resourceCategoryNames ? resourceCategoryNames : '未設定'}
                  textType={resourceCategoryNames ? 'default' : 'error'}
                  component={
                    <IconButtonV2
                      icon={<StyledEditOutlined error={!resourceCategoryNames} />}
                      onClick={handleRegisterResourceAuthorityModalOpened}
                    />
                  }
                />
                {category && (
                  <RegisterPersonInChargeModal
                    isOpen={isRegisterResourceAuthorityModalOpen}
                    base={base}
                    baseAuthorities={resourceAuthorities}
                    selectedUsers={[selectedUser]}
                    category={category}
                    initialAuthorityIds={initialAuthorityIds}
                    onClose={handleResourceAuthorityIdsUpdated}
                  />
                )}
              </>
            )}
            {hasUserRankAuthority && (
              <>
                <CardWithLabelV2
                  label='会員ランク'
                  text={memberRankNames}
                  component={
                    <>
                      <IconButtonV2
                        icon={<StyledEditOutlined error={!memberRankNames} />}
                        onClick={handleRegisterPersonInchargeForRankModalOpened}
                      />
                    </>
                  }
                />
                <RegisterPersonInChargeModal
                  isOpen={isRegisterPersonInchargeForRankModalOpen}
                  base={base}
                  baseAuthorities={memberRankAuthorities}
                  selectedUsers={[selectedUser]}
                  category={memberRankCategoryDef}
                  initialAuthorityIds={rankAuthorityIds}
                  onClose={handleRankAuthorityIdsUpdated}
                />
              </>
            )}
          </InformationRowWrapper>
          <InformationRowWrapper isHalf={!hasUserIdentificationStatus}>
            {hasUserIdentificationStatus && (
              <>
                <CardWithLabelV2
                  label='本人確認状況'
                  text={!baseIdentification ? '未確認' : '確認済み'}
                  textType={baseIdentification ? 'default' : 'error'}
                  component={
                    <>
                      {baseIdentification && (
                        <IconButtonV2
                          icon={<VisibilityOutlined />}
                          onClick={() => handleRegisterUserIdentificationModalOpened(false)}
                        />
                      )}
                      <IconButtonV2
                        icon={<StyledEditOutlined error={!baseIdentification} />}
                        onClick={() => handleRegisterUserIdentificationModalOpened(true)}
                      />
                    </>
                  }
                />
                <RegisterUserIdentificationModal
                  isOpen={isRegisterUserIdentificationModalOpen}
                  base={base}
                  selectedUser={selectedUser}
                  isEditable={isUserIdentificationEditable}
                  onClose={() => handleModalClosed(setIsRegisterUserIdentificationModalOpen)}
                />
              </>
            )}

            <CardWithLabelV2
              label='最終チェックイン'
              text={selectedUser.lastCheckInDate ? format(selectedUser.lastCheckInDate, 'yyyy/MM/dd HH:mm') : 'なし'}
            />
          </InformationRowWrapper>
          <InformationRowWrapper>
            <CardWithLabelV2
              label='性別'
              text={selectedUser.gender ? USER_GENDER_LABELS[selectedUser.gender] : EMPTY}
            />
            <CardWithLabelV2
              label='生年月日'
              text={
                selectedUser.dateOfBirthV2 ? format(new Date(selectedUser.dateOfBirthV2 ?? 0), 'yyyy/MM/dd') : 'なし'
              }
            />
          </InformationRowWrapper>
          <InformationRowWrapper>
            <CardWithLabelV2
              label={'都道府県'}
              text={selectedUser.prefecture ? PREFECTURE_NAMES[selectedUser.prefecture] : EMPTY}
            />
            <CardWithLabelV2 label={'市区町村'} text={selectedUser.city ?? EMPTY} />
          </InformationRowWrapper>
          <InformationRowWrapper isHalf={!hasCompanyName && !isStudent}>
            <CardWithLabelV2 label={'職業'} text={userDivName} />
            {!isStudent && hasCompanyName && (
              <CardWithLabelV2 label={'お勤め先'} text={selectedUser.companyName ?? EMPTY} />
            )}
            {isStudent && <CardWithLabelV2 label={'学年'} text={userStudentDivName ?? EMPTY} />}
          </InformationRowWrapper>
          {isStudent && selectedUser.schoolName && (
            <div>
              <InformationRowWrapper isHalf={true}>
                <CardWithLabelV2 label={'学校名'} text={selectedUser.schoolName} />
              </InformationRowWrapper>
              <InformationRowWrapper isHalf={true}>
                <CardWithLabelV2 label={'学部名'} text={selectedUser.schoolDepartmentName!} />
              </InformationRowWrapper>
            </div>
          )}
          <InformationRowWrapper isHalf={true}>
            <CardWithLabelV2 label={'ご利用のきっかけ'} text={userInflowSourceName} />
            {/* <CardWithLabelV2 label={'ご利用の目的'} text={userUsagePurposeName} /> */}
          </InformationRowWrapper>
          {/* <InformationRowWrapper isHalf={true}>
            <CardWithLabelV2 label={'同居家族'} text={familyMemberNamesStr} />
          </InformationRowWrapper> */}
        </ProfileDetailContainer>
      )}

      <ProfileDetailsButtonWrapper>
        <ButtonV2
          label={isProfileDetailsOpen ? 'プロフィールを畳む' : 'プロフィールを全て表示'}
          size='large'
          startIcon={<ProfileDetailsButtonIcon icon={isProfileDetailsOpen ? iconCaretUp : iconCaretDown} />}
          onClick={handleDetailButtonClicked}
        />
      </ProfileDetailsButtonWrapper>
    </ProfileContainer>
  );
};

ProfileSectionV2.displayName = 'ProfileSectionV2';
export default ProfileSectionV2;

const ProfileContainer = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  background-color: ${themeV2.mixins.v2.color.font.white};
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  color: ${themeV2.mixins.v2.color.font.black};
`;

const InformationTopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ProfileDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InformationRowWrapper = styled.div<{ isHalf?: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  width: ${({ isHalf }) => (isHalf ? 'calc(50% - ' + Math.floor((themeV2.mixins.v2.spacing * 3) / 2) + 'px)' : '100%')};
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  max-height: 120px;
  max-width: 120px;
  border-radius: 60px;
  overflow: hidden;
`;

const InformationRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const NameText = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.large};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const CompanyNameText = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const EmailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${themeV2.mixins.v2.spacing}px;
  align-items: center;
`;

const EmailText = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const PhoneNumberText = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const SecondaryText = styled.p`
  margin: 0px;
  padding-top: ${themeV2.mixins.v2.spacing}px;
  padding-bottom: ${themeV2.mixins.v2.spacing * 2}px;
  color: ${themeV2.mixins.v2.color.background.pinkDark};
  ${themeV2.mixins.v2.typography.body.medium};
  &:hover {
    cursor: pointer;
  }
`;

const OutlineChipRow = styled.div`
  padding-bottom: ${themeV2.mixins.v2.spacing}px;
  color: ${themeV2.mixins.v2.color.font.black};
`;

const Heading = styled.div`
  ${themeV2.mixins.v2.typography.title.xLarge};
  background-color: ${themeV2.mixins.v2.color.font.white};
  margin: 0px;
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
  padding-bottom: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  justify-content: flex-end;
  align-items: center;
  padding: ${themeV2.mixins.v2.spacing}px;
`;

const StyledEditOutlined = styled(EditOutlined)<{ error: boolean }>`
  color: ${({ error }) => error && themeV2.mixins.v2.color.background.pink};
`;

const ProfileDetailsButtonWrapper = styled.div`
  display: block;
  width: 100%;
  text-align: center;
`;

const ProfileDetailsButtonIcon = styled.div<{ icon: string }>`
  width: 24px;
  height: 24px;
  background-image: url(${({ icon }) => icon});
  background-size: cover;
`;
