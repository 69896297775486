import {
  ButtonV2,
  CardWithLabelV2,
  ColWidth,
  Component,
  Header,
  SearchListV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  ContractStatusV2,
  ContractV2,
  FETCH_CONTRACT_V2_BY_USER_FOR_ADMIN,
  FETCH_USER_FOR_ADMIN,
  FetchContractV2ByUserForAdminRequest,
  FetchContractV2ByUserForAdminResponse,
  FetchUserForAdminRequest,
  FetchUserForAdminResponse,
  SEARCH_USER_POINT_LOGS_FOR_ADMIN,
  SearchUserPointLogsForAdminRequest,
  SearchUserPointLogsForAdminResponse,
  User,
  UserPoint
} from '@atomica.co/irori';
import { Comment, Count, DateStr, Id, Offset, Point, Remarks, Word } from '@atomica.co/types';
import { EMPTY, builder, hasLength, isLessThanZero } from '@atomica.co/utils';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Skeleton } from '@material-ui/lab';
import { endOfMonth, format, isBefore, setDate, setMonth } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { PointAction } from '../../../enums/point-enum';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import useUser from '../../../redux/hooks/useUser';
import PointModal from '../modal/PointModal';

interface P {
  base: BaseDto;
  selectedUserId: Id;
}

const LIMIT = 10;
const HEADER: Header = {
  createdDateStr: { label: '利用日' },
  comment: { label: '内容' },
  point: { label: 'ポイント利用・付与', align: 'right' },
  remarks: { label: '備考' }
};
const COLUMN_WIDTH: ColWidth = { createdDateStr: 120, comment: 'auto', point: 160, remarks: 220 };

interface UserPointLogRow {
  id: Id;
  createdDateStr: DateStr;
  comment: Comment;
  point: React.JSX.Element;
  remarks: Remarks;
}
const UserPointUsageLog: React.FC<P> = React.memo(props => {
  const { base, selectedUserId } = props;
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();
  const signinUser = useUser().user;
  const [selectedUser, setSelectedUser] = useSafeState<User>(unmountRef);

  // summary
  const [contract, setContract] = useSafeState<ContractV2>(unmountRef);
  const [selectedUserPoint, setSelectedUserPoint] = useSafeState<UserPoint>(unmountRef);
  const [isOpenAddPointModal, setIsOpenAddPointModal] = useSafeState<boolean>(unmountRef, false);
  const [isOpenDeletePointModal, setIsOpenDeletePointModal] = useSafeState<boolean>(unmountRef, false);
  const [isInitialized, setIsInitialized] = useSafeState<boolean>(unmountRef, false);

  // logs
  const [rows, setRows] = useSafeState<UserPointLogRow[]>(unmountRef, []);
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, 0);
  const [totalCount, setTotalCount] = useSafeState<Count>(unmountRef, 0);
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);

  const totalUserPoint = useMemo<Point>(() => {
    if (!selectedUser || !hasLength(selectedUser.userPoints)) return 0;
    return selectedUser.userPoints!.reduce((total: Point, userPoint: UserPoint): Point => total + userPoint.point, 0);
  }, [selectedUser]);

  const nextGrantedPointDate = useMemo<Date | void>(() => {
    if (!contract) return;
    if (contract.contractStatus !== ContractStatusV2.CONFIRMED) return;
    if (isBefore(contract.endDate!, new Date())) return;
    if (!contract.pointGrantDate) return;
    const today = new Date();
    const lastDayOfMonth = endOfMonth(today).getDate();
    const pointGrantDate = lastDayOfMonth < contract.pointGrantDate ? lastDayOfMonth : contract.pointGrantDate;
    const grantDateOfThisMonth = setDate(today, pointGrantDate);
    return pointGrantDate > today.getDate()
      ? grantDateOfThisMonth
      : setMonth(grantDateOfThisMonth, today.getMonth() + 1);
  }, [contract]);

  const searchContract = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchContractV2ByUserForAdminRequest>().baseId(base.baseId).userId(selectedUserId).build();
    const response = await commonRequest<FetchContractV2ByUserForAdminRequest, FetchContractV2ByUserForAdminResponse>(
      FETCH_CONTRACT_V2_BY_USER_FOR_ADMIN,
      request
    );
    if (response.contract) setContract(response.contract);
  }, [base, commonRequest, selectedUserId, setContract]);

  const fetchUser = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchUserForAdminRequest>().baseId(base.baseId).userId(selectedUserId).build();
    const response = await commonRequest<FetchUserForAdminRequest, FetchUserForAdminResponse>(
      FETCH_USER_FOR_ADMIN,
      request
    );
    if (response.user) setSelectedUser(response.user);
  }, [base, commonRequest, selectedUserId]);

  const searchUserPointLogs = useSafeCallback(
    async (word: Word): Promise<void> => {
      setIsLoaderShown(true);
      const request = builder<SearchUserPointLogsForAdminRequest>()
        .userId(selectedUserId)
        .baseId(base.baseId)
        .limit(LIMIT)
        .offset(offset)
        .word(word)
        .build();
      const { totalCount, userPointLogs } = await commonRequest<
        SearchUserPointLogsForAdminRequest,
        SearchUserPointLogsForAdminResponse
      >(SEARCH_USER_POINT_LOGS_FOR_ADMIN, request);

      const rows = userPointLogs.map(userPointLog => {
        return builder<UserPointLogRow>()
          .id(userPointLog.userPointLogId)
          .point(
            <PointTd point={userPointLog.point}>
              {!isLessThanZero(userPointLog.point) && '+'}
              {userPointLog.point.toLocaleString()}
            </PointTd>
          )
          .comment(userPointLog.comment || EMPTY)
          .createdDateStr(format(new Date(userPointLog.createdAt!), 'yyyy/MM/dd'))
          .remarks(`${format(userPointLog.expirationDate!, 'yyyy/MM/dd HH:mm')}まで有効`)
          .build();
      });

      setRows(rows);
      setTotalCount(totalCount);
      setIsLoaderShown(false);
    },
    [base, commonRequest, offset, selectedUserId, setIsLoaderShown, setRows, setTotalCount]
  );

  const handleSearchingWordChanged = useSafeCallback(
    (word: Word): void => {
      setSearchingWord(word);
      searchUserPointLogs(word);
    },
    [searchUserPointLogs, setSearchingWord]
  );

  const initialize = useSafeCallback(async () => {
    setIsInitialized(false);
    await Promise.all([searchContract(), fetchUser(), searchUserPointLogs(EMPTY)]);
    setIsInitialized(true);
  }, [fetchUser, searchContract, searchUserPointLogs, setIsInitialized]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handlePointUpdated = useSafeCallback((): void => {
    setOffset(0);
    initialize();
  }, [setOffset, initialize]);

  const handleAddPointButtonClick = useSafeCallback((): void => {
    setIsOpenAddPointModal(true);
  }, [setIsOpenAddPointModal]);

  const handleAddPointModalClose = useSafeCallback(
    (isUpdated: boolean): void => {
      setIsOpenAddPointModal(false);
      if (isUpdated) handlePointUpdated();
    },
    [setIsOpenAddPointModal, handlePointUpdated]
  );

  const handleDeletePointButtonClick = useSafeCallback(
    (userPoint: UserPoint): void => {
      setSelectedUserPoint(userPoint);
      setIsOpenDeletePointModal(true);
    },
    [setIsOpenDeletePointModal, setSelectedUserPoint]
  );

  const handleDeletePointModalClose = useSafeCallback(
    (isUpdated: boolean): void => {
      setIsOpenDeletePointModal(false);
      if (isUpdated) handlePointUpdated();
    },
    [setIsOpenDeletePointModal, handlePointUpdated]
  );

  return (
    <Component className='user-point-usage-log'>
      <Container>
        {!isInitialized && (
          <PointSummary>
            <Heading>
              <HalfSkeleton />
            </Heading>
            {[0, 1, 2].map(idx => (
              <div key={`skeleton-${idx}`}>
                <LabelSkeleton />
                <CardSkeleton />
              </div>
            ))}
          </PointSummary>
        )}
        {isInitialized && (
          <PointSummary>
            <Heading>ポイント概要</Heading>

            <CardWrapper>
              <CardLabel>利用可能な総ポイント</CardLabel>
              <AvailablePointCard>
                <AvailablePointCardContent>
                  <AvailablePointCardText>{totalUserPoint.toLocaleString()}</AvailablePointCardText>
                  <AvailablePointCardSubText>ポイント</AvailablePointCardSubText>
                </AvailablePointCardContent>
                <ButtonV2 label='追加' startIcon={<AddIcon />} size='small' onClick={handleAddPointButtonClick} />
              </AvailablePointCard>
            </CardWrapper>

            {selectedUser?.userPoints?.map((userPoint, idx) => (
              <CardWithLabelV2
                key={`user-point-${idx}`}
                label='有効期限'
                text={userPoint.point.toLocaleString()}
                subText={`${format(userPoint.expirationDate!, 'yyyy/MM/dd')}まで`}
                subTextPosition='top'
                component={
                  <ButtonV2
                    label='削除'
                    startIcon={<RemoveIcon />}
                    size='small'
                    onClick={() => handleDeletePointButtonClick(userPoint)}
                  />
                }
              />
            ))}

            {!!contract?.grantedPoints && !!nextGrantedPointDate && (
              <CardWithLabelV2
                label='次回の付与ポイント'
                text={`${contract.grantedPoints.toLocaleString()}ポイント`}
                subText={`${format(nextGrantedPointDate, 'yyyy/MM/dd')}に付与予定`}
              />
            )}

            <PointModal
              isOpen={isOpenAddPointModal}
              base={base}
              action={PointAction.GRANT}
              selectedUser={selectedUser}
              signInUser={signinUser!}
              onSave={() => {
                handleAddPointModalClose(true);
              }}
              onClose={() => {
                handleAddPointModalClose(false);
              }}
            />
            <PointModal
              isOpen={isOpenDeletePointModal}
              base={base}
              action={PointAction.SUBTRACT}
              selectedUser={selectedUser}
              signInUser={signinUser!}
              userPoint={selectedUserPoint}
              onSave={() => {
                handleDeletePointModalClose(true);
              }}
              onClose={() => {
                handleDeletePointModalClose(false);
              }}
            />
          </PointSummary>
        )}
        <PointHistory>
          <SearchListV2
            wrapText
            colWidth={COLUMN_WIDTH}
            title='ポイント利用履歴'
            name='ポイント利用履歴'
            header={HEADER}
            rows={rows}
            offset={offset}
            limit={LIMIT}
            totalCount={totalCount}
            searchingWord={searchingWord}
            placeholder='内容で検索'
            onChange={handleSearchingWordChanged}
            setOffset={setOffset}
            isLoaderShown={isLoaderShown}
          />
        </PointHistory>
      </Container>
    </Component>
  );
});

UserPointUsageLog.displayName = 'UserPointUsageLog';
export default UserPointUsageLog;

const Container = styled.div`
  display: flex;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px 0;
`;
const PointSummary = styled.div`
  width: 357px;
  display: flex;
  flex-direction: column;
  border-radius: ${themeV2.mixins.v2.spacing}px;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  background-color: ${themeV2.mixins.v2.color.font.white};
`;
const Heading = styled.div`
  ${themeV2.mixins.v2.typography.title.xLarge};
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
  padding-bottom: ${themeV2.mixins.v2.spacing * 2}px;
`;
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;
const CardLabel = styled.div`
  ${themeV2.mixins.v2.typography.label.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
`;
const CardCommon = styled.div`
  border-radius: ${themeV2.mixins.v2.spacing}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background-color: ${themeV2.mixins.v2.color.background.offWhite};
`;
const AvailablePointCard = styled(CardCommon)`
  display: flex;
  align-items: center;
`;
const AvailablePointCardContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing}px;
`;
const AvailablePointCardText = styled.div`
  ${themeV2.mixins.v2.typography.headLine.small};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const AvailablePointCardSubText = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const PointHistory = styled.div`
  display: flex;
  flex: 1;
  height: max-content;
`;

const HalfSkeleton = styled(Skeleton)`
  display: inline-block;
  width: 50%;
  height: 100%;
`;
const LabelSkeleton = styled(Skeleton)`
  display: inline-block;
  width: 50%;
  height: 42px;
`;
const CardSkeleton = styled(Skeleton)`
  display: inline-block;
  width: 100%;
  height: 68px;
`;
const PointTd = styled.div<{ point: Point }>`
  ${({ point }) => isLessThanZero(point) && `color: ${themeV2.mixins.v2.color.status.error};`}
`;
