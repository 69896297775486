import {
  ButtonV2,
  CheckBoxV2,
  InputWithLabelV2,
  LabelV2,
  MasterSearchOption,
  MasterSearchV2,
  TextFieldV2,
  themeV2,
  useSafeCallback
} from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  ContractDetailId,
  ContractPlanV2,
  DetailItemCode,
  ItemV2,
  RecurrencePattern,
  UnitV2,
  isBaseFunctionToggleEnabled
} from '@atomica.co/irori';
import { Code, Count, Day, Gap, Name, Point, Quantity, Rate } from '@atomica.co/types';
import { EMPTY, ONE, ZERO, hasLength, isEmpty, isNull, uuid } from '@atomica.co/utils';
import { Paper, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { MASTER_SEARCH_LIMIT } from '../../../constants/contract-v2-const';
import {
  DetailForContractRegisterScreen,
  toMasterSearchOptionFromPlan
} from '../../../converters/contract-v2-converter';
import { DisplayTaxes } from '../../../converters/tax-converter';
import {
  ContractDetailInputKeyEnum,
  ContractDiscountInputKeyEnum,
  UsedContractOptionEnum
} from '../../../enums/contract-v2-enum';
import ItemService from '../../../services/item-service';
import { ContractDetailsInput } from './plan-input/ContractDetailsInput';
import { DiscountDetailsInput } from './plan-input/DiscountDetailInput';

const getInitialContractDetail = (): DetailForContractRegisterScreen => {
  return {
    contractDetailId: uuid(),
    disabled: false,
    isDeletable: true,
    itemName: EMPTY,
    quantity: ZERO,
    unitPrice: ZERO
  };
};

const getInitialDiscountDetail = (): DetailForContractRegisterScreen => {
  return {
    contractDetailId: uuid(),
    disabled: false,
    isDeletable: true,
    itemName: EMPTY,
    quantity: ZERO,
    unitPrice: ZERO,
    recurrenceCount: ONE,
    recurrencePattern: RecurrencePattern.MONTHLY
  };
};

export interface PlanInputValues {
  selectedPlan: ContractPlanV2 | undefined;
  usersCount: Count;
  grantedPoints: Point;
  pointGrantDate: Day | null;
  monthlyContractDetails: DetailForContractRegisterScreen[];
  atOnceContractDetails: DetailForContractRegisterScreen[];
  discountDetails: DetailForContractRegisterScreen[];
}

export interface PlanInputOnChanges {
  setAtOnceContractDetails: React.Dispatch<React.SetStateAction<DetailForContractRegisterScreen[]>>;
  setDiscountDetails: React.Dispatch<React.SetStateAction<DetailForContractRegisterScreen[]>>;
  setMonthlyContractDetails: React.Dispatch<React.SetStateAction<DetailForContractRegisterScreen[]>>;
  setSelectedPlan: (option: MasterSearchOption) => void;
  setUsersCount: (usersCount: Count) => void;
  setGrantedPoints: (point: Point) => void;
  setPointGrantedDate: (date: Day | null) => void;
}

interface P {
  base: BaseDto;
  handleSearchPlans: (planName: Name) => Promise<void>;
  handleClearPlan: () => void;
  onChanges: PlanInputOnChanges;
  plans: ContractPlanV2[];
  values: PlanInputValues;
  displayTaxes: DisplayTaxes | undefined;
  units: UnitV2[];
}

const PlanInput: React.FC<P> = React.memo(props => {
  const { base, handleSearchPlans, handleClearPlan, onChanges, plans, values, displayTaxes, units } = props;
  const {
    selectedPlan,
    usersCount,
    grantedPoints,
    pointGrantDate,
    monthlyContractDetails,
    atOnceContractDetails,
    discountDetails
  } = values;
  const {
    setSelectedPlan,
    setUsersCount,
    setGrantedPoints,
    setPointGrantedDate,
    setMonthlyContractDetails,
    setAtOnceContractDetails,
    setDiscountDetails
  } = onChanges;

  const lockerUsageOption = useMemo((): DetailForContractRegisterScreen | undefined => {
    if (!hasLength(monthlyContractDetails)) return;
    return monthlyContractDetails.find(contractDetail => contractDetail.itemCode === UsedContractOptionEnum.LOCKER_USE);
  }, [monthlyContractDetails]);

  const addressUsageOption = useMemo((): DetailForContractRegisterScreen | undefined => {
    if (!hasLength(monthlyContractDetails)) return;
    return monthlyContractDetails.find(
      contractDetail => contractDetail.itemCode === UsedContractOptionEnum.ADDRESS_USE
    );
  }, [monthlyContractDetails]);

  const keyIssuanceItem = useMemo<ItemV2 | undefined>(() => {
    if (!selectedPlan || !hasLength(selectedPlan.contractOptions)) return;
    const option = selectedPlan.contractOptions!.find(
      opt => !!opt.item && opt.item.itemCode === DetailItemCode.KEY_ISSUANCE
    );
    return option ? option.item : undefined;
  }, [selectedPlan]);

  const addDetailRow = useSafeCallback(
    (recurrencePattern: RecurrencePattern): void => {
      const contractDetailToAdd = getInitialContractDetail();
      switch (recurrencePattern) {
        case RecurrencePattern.AT_ONCE:
          setAtOnceContractDetails(atOnceContractDetails => {
            return [...atOnceContractDetails, contractDetailToAdd];
          });
          break;

        case RecurrencePattern.MONTHLY:
          setMonthlyContractDetails(monthlyContractDetails => {
            return [...monthlyContractDetails, contractDetailToAdd];
          });
          break;
      }
    },
    [setAtOnceContractDetails, setMonthlyContractDetails]
  );

  const deleteDetailRow = useSafeCallback(
    (recurrencePattern: RecurrencePattern, contractDetailId: ContractDetailId): void => {
      switch (recurrencePattern) {
        case RecurrencePattern.AT_ONCE:
          setAtOnceContractDetails(details => {
            return details.filter(detail => detail.contractDetailId !== contractDetailId);
          });
          break;

        case RecurrencePattern.MONTHLY:
          setMonthlyContractDetails(details => {
            return details.filter(detail => detail.contractDetailId !== contractDetailId);
          });
          break;
      }
    },
    [setAtOnceContractDetails, setMonthlyContractDetails]
  );

  const handleDetailInput = useSafeCallback(
    <T,>(
      recurrencePattern: RecurrencePattern | undefined,
      detailId: ContractDetailId | Code, // FIXME
      key: ContractDetailInputKeyEnum,
      val: T
    ): void => {
      if (!recurrencePattern) return;
      switch (recurrencePattern) {
        case RecurrencePattern.AT_ONCE:
          setAtOnceContractDetails(details => {
            return details.map(detail =>
              detail.contractDetailId === detailId || detail.itemCode === detailId ? { ...detail, [key]: val } : detail
            );
          });
          break;

        case RecurrencePattern.MONTHLY:
          setMonthlyContractDetails(details => {
            return details.map(detail => (detail.contractDetailId === detailId ? { ...detail, [key]: val } : detail));
          });
          break;
      }
    },
    [setAtOnceContractDetails, setMonthlyContractDetails]
  );

  const addDiscountRow = useSafeCallback((): void => {
    const contractDetailToAdd = getInitialDiscountDetail();
    setDiscountDetails(discountDetails => {
      return [...discountDetails, contractDetailToAdd];
    });
  }, [setDiscountDetails]);

  const deleteDiscountRow = useSafeCallback(
    (contractDetailId: ContractDetailId): void => {
      setDiscountDetails(details => {
        return details.filter(detail => detail.contractDetailId !== contractDetailId);
      });
    },
    [setDiscountDetails]
  );

  const handleDiscountInput = useSafeCallback(
    <T,>(contractDetailId: ContractDetailId, key: ContractDiscountInputKeyEnum, val: T): void => {
      setDiscountDetails(details => {
        return details.map(detail =>
          detail.contractDetailId === contractDetailId ? { ...detail, [key]: val } : detail
        );
      });
    },
    [setDiscountDetails]
  );

  const handleNumberOfKeyIssuanceChanged = useSafeCallback(
    (usersCount: Count) => {
      handleDetailInput(
        RecurrencePattern.AT_ONCE,
        DetailItemCode.KEY_ISSUANCE,
        ContractDetailInputKeyEnum.QUANTITY,
        usersCount
      );
      setUsersCount(usersCount);
    },
    [handleDetailInput, setUsersCount]
  );

  const handleAddressUsageChanged = useSafeCallback(
    (disabled: boolean): void => {
      if (!addressUsageOption) return;
      handleDetailInput(
        addressUsageOption.recurrencePattern,
        addressUsageOption.contractDetailId,
        ContractDetailInputKeyEnum.DISABLED,
        disabled
      );
    },
    [addressUsageOption, handleDetailInput]
  );

  const handleLockerUsageChanged = useSafeCallback(
    (disabled: boolean): void => {
      if (!lockerUsageOption) return;
      handleDetailInput(
        lockerUsageOption.recurrencePattern,
        lockerUsageOption.contractDetailId,
        ContractDetailInputKeyEnum.DISABLED,
        disabled
      );
    },
    [lockerUsageOption, handleDetailInput]
  );

  const handleLockerQuantityChanged = useSafeCallback(
    (quantity: Quantity): void => {
      if (!lockerUsageOption) return;
      handleDetailInput(
        lockerUsageOption.recurrencePattern,
        lockerUsageOption.contractDetailId,
        ContractDetailInputKeyEnum.QUANTITY,
        quantity
      );
    },
    [lockerUsageOption, handleDetailInput]
  );

  return (
    <PlanContent>
      <ContentTitle>契約プラン</ContentTitle>
      <Rows>
        <Row direction='column'>
          <LabelV2 required text='基本プラン' />
          {!!selectedPlan && hasLength(monthlyContractDetails) && (
            <PlanInfo>
              <PlanName>{selectedPlan.contractPlanName}</PlanName>

              <PlanItem>
                <PlanItemName>基本料</PlanItemName>
                <PlanItemValue>{`\xA5${ItemService.calcTaxIncludedPrice(
                  selectedPlan.item
                ).toLocaleString()} / 月（税込）`}</PlanItemValue>
              </PlanItem>
              <PlanItem>
                <PlanItemName>会議室利用:</PlanItemName>
                <PlanItemValue>
                  {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    parseFloat(selectedPlan.freeTier as any) || ZERO
                  }
                  時間まで無料
                </PlanItemValue>
                <PlanItemName>会議室利用額割引率:</PlanItemName>
                <PlanItemValue>{selectedPlan.discountRate}％</PlanItemValue>
              </PlanItem>
              <StyledCloseIcon onClick={handleClearPlan} />
            </PlanInfo>
          )}

          {!selectedPlan && !hasLength(monthlyContractDetails) && (
            <MasterSearchV2
              placeholder='プラン名で検索'
              labelForNoData='選択可能なプランがありません'
              options={toMasterSearchOptionFromPlan(plans)}
              handleOptionCleared={handleClearPlan}
              handleOptionSelected={setSelectedPlan}
              onChange={handleSearchPlans}
              limit={MASTER_SEARCH_LIMIT}
            />
          )}
        </Row>
        {!!keyIssuanceItem && (
          <Row>
            <Cell widthRate={25}>
              <InputWithLabelV2
                align='right'
                text='利用人数'
                value={usersCount}
                readonly={!selectedPlan}
                onChange={handleNumberOfKeyIssuanceChanged}
                inputWidth={108}
                unit='名'
              />
            </Cell>
            <Cell widthRate={75} />
          </Row>
        )}
        {isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USER_POINT) && (
          <Row direction='column'>
            <ContentSubTitle>定期ポイント</ContentSubTitle>
            <Row direction='column' gap={16}>
              <InputWithLabelV2
                align='right'
                text='ポイント数'
                inputType='number'
                inputWidth={108}
                unit='ポイント / 月'
                value={grantedPoints}
                onChange={setGrantedPoints}
              />
              <Row direction='column'>
                <LabelV2 text='ポイント付与日' remarks='ポイントの有効期限は、付与日の月末 23:59 までです。' />
                <InputWrapper>
                  <Text>毎月</Text>
                  <TextFieldV2
                    align='right'
                    inputType='number'
                    inputWidth={64}
                    value={isNull(pointGrantDate) ? EMPTY : pointGrantDate}
                    onChange={value => setPointGrantedDate(isEmpty(value) ? null : value)}
                  />
                  日
                </InputWrapper>
              </Row>
            </Row>
          </Row>
        )}
        {!!selectedPlan && (
          <Row direction='column'>
            <ContentSubTitle>オプション</ContentSubTitle>
            {addressUsageOption && (
              <Row direction='row'>
                <CheckBoxV2
                  disabled={false}
                  checked={!addressUsageOption.disabled}
                  onChange={handleAddressUsageChanged}
                >
                  {`${addressUsageOption.itemName || EMPTY}（¥${ItemService.calcTaxIncludedPrice(
                    addressUsageOption.item
                  ).toLocaleString()} / 月・税込み）`}
                </CheckBoxV2>
              </Row>
            )}
            {lockerUsageOption && (
              <Row direction='row'>
                <CheckBoxV2 disabled={false} checked={!lockerUsageOption.disabled} onChange={handleLockerUsageChanged}>
                  {`${lockerUsageOption.itemName || EMPTY}（一個あたり ¥${ItemService.calcTaxIncludedPrice(
                    lockerUsageOption.item
                  ).toLocaleString()} / 月・税込み）`}
                </CheckBoxV2>
                <Separater />
                <Cell widthRate={10}>
                  <TextFieldV2
                    align='right'
                    value={lockerUsageOption.quantity}
                    // unit={units[UsedContractUnitCodeEnum.PIECE] || '個'}
                    onChange={handleLockerQuantityChanged}
                  />
                </Cell>
              </Row>
            )}
          </Row>
        )}

        {!!selectedPlan && (
          <Row direction='column'>
            <ContentSubTitle>明細</ContentSubTitle>

            <ContractDetailsInput
              label='月々の請求'
              contractDetails={monthlyContractDetails}
              deleteDetailRow={deleteDetailRow}
              displayTaxes={displayTaxes}
              handleDetailInput={handleDetailInput}
              recurrencePattern={RecurrencePattern.MONTHLY}
              units={units}
              usersCount={usersCount}
              setUsersCount={setUsersCount}
            />

            <ButtonWrapper>
              <ButtonV2
                startIcon={<Add />}
                label='品目を追加'
                onClick={() => addDetailRow(RecurrencePattern.MONTHLY)}
                size='large'
              />
            </ButtonWrapper>

            <ContractDetailsInput
              label='初回のみ請求'
              contractDetails={atOnceContractDetails}
              deleteDetailRow={deleteDetailRow}
              displayTaxes={displayTaxes}
              handleDetailInput={handleDetailInput}
              recurrencePattern={RecurrencePattern.AT_ONCE}
              units={units}
              usersCount={usersCount}
              setUsersCount={setUsersCount}
            />

            <ButtonWrapper>
              <ButtonV2
                startIcon={<Add />}
                label='品目を追加'
                onClick={() => addDetailRow(RecurrencePattern.AT_ONCE)}
                size='large'
              />
            </ButtonWrapper>
          </Row>
        )}

        {!!selectedPlan && (
          <Row direction='column'>
            <ContentSubTitle>割引項目</ContentSubTitle>

            <DiscountDetailsInput
              deleteDiscountRow={deleteDiscountRow}
              discountDetails={discountDetails}
              handleDiscountInput={handleDiscountInput}
              displayTaxes={displayTaxes}
              units={units}
            ></DiscountDetailsInput>

            <ButtonWrapper>
              <ButtonV2 startIcon={<Add />} label='品目を追加' onClick={addDiscountRow} size='large' />
            </ButtonWrapper>
          </Row>
        )}
      </Rows>
    </PlanContent>
  );
});

PlanInput.displayName = 'PlanInput';
export default PlanInput;

const Content = styled.div`
  background-color: ${themeV2.mixins.v2.color.background.offWhite};
  border-radius: 6px;
  padding: ${`${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px`};
`;
const Rows = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 3}px 0;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  width: 100%;
`;
const Row = styled.div<{ direction?: 'row' | 'column'; gap?: Gap }>`
  display: flex;
  min-height: 24px;
  width: 100%;
  flex-direction: ${({ direction = 'row' }) => direction};
  gap: ${({ direction, gap = direction === 'row' ? themeV2.mixins.v2.spacing * 3 : themeV2.mixins.v2.spacing / 2 }) =>
    gap}px;
  align-items: ${({ direction = 'row' }) => (direction === 'row' ? 'center' : 'left')};
`;
const Cell = styled.div<{ widthRate: Rate }>`
  width: ${({ widthRate = 100 }) => widthRate}%;
  display: flex;
`;
const PlanContent = styled(Content)``;

const ContentTitle = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
  padding: ${themeV2.mixins.v2.spacing}px 0;
  width: 100%;
`;
const ContentSubTitle = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.medium};
  color: ${themeV2.mixins.v2.color.font.black};
  padding: ${themeV2.mixins.v2.spacing}px 0;
  width: 100%;
`;

const PlanInfo = styled(Paper)`
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: ${themeV2.mixins.v2.spacing}px;
`;
const PlanName = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.medium};
  color: ${themeV2.mixins.v2.color.font.black};
`;
const PlanItem = styled.div`
  display: flex;
  gap: ${themeV2.mixins.v2.spacing}px;
`;
const PlanItemName = styled(Typography)`
  ${themeV2.mixins.v2.typography.label.large};
  color: #4f4f4f; //FIXME
`;
const PlanItemValue = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.medium};
  color: #4f4f4f; //FIXME
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${themeV2.mixins.v2.color.background.gray};
  cursor: pointer;
  position: absolute;
  right: ${themeV2.mixins.v2.spacing * 2}px;
  top: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  padding: ${themeV2.mixins.v2.spacing}px 0;
`;

const Separater = styled.div`
  height: 1px;
  border-bottom: 1px dashed ${themeV2.mixins.v2.color.border.gray};
  width: 120px;
`;

const Text = styled.div`
  white-space: nowrap;
`;

const InputWrapper = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
