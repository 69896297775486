import {
  ButtonV2,
  ColWidth,
  Component,
  DRAWER_WIDTH,
  Header,
  IconButtonV2,
  PageHeaderV2,
  PullDownOption,
  PullDownV2,
  RadioButton,
  ScreenLoaderV2,
  TextFieldV2,
  themeV2,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';

import {
  BaseDto,
  FETCH_SHOT_CATEGORIES_FOR_ADMIN,
  FetchShotCategoriesForAdminRequest,
  FetchShotCategoriesForAdminResponse,
  ItemV2,
  SAVE_SHOT_CATEGORIES_FOR_ADMIN,
  SaveShotCategoriesForAdminRequest,
  SaveShotCategoriesForAdminResponse,
  ShotCategory,
  ShotCategoryId,
  ShotQuantityInputType,
  ShotSubcategory,
  TaxDiv
} from '@atomica.co/irori';
import { Index, Message, PriceStr, Width } from '@atomica.co/types';
import { builder, EMPTY, FIRST_INDEX, hasLength, uuid } from '@atomica.co/utils';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arraySwap, SortableContext } from '@dnd-kit/sortable';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { TaxMapping, toTaxLabel, toTaxMapping } from '../../../converters/tax-converter';
import { useSnackbarV2 } from '../../../provider/SnackbarProviderV2';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import useTax from '../../../redux/hooks/useTax';
import useUnit from '../../../redux/hooks/useUnit';
import { styleForComponent } from '../../../styles/components';
import { SHOT_QUANTITY_INPUT_TYPE_LABELS } from '../../../texts/shot-text';
import { Expand } from '../../../types';
import { partialBuilder } from '../../../utils/common-util';
import { getTaxKey } from '../../../utils/tax-util';
import { validate } from '../../../validate/shotMasterScreen/validate';
import ShotCategoryCard from './card/ShotCategoryCard';

interface P {
  base: BaseDto;
}

export type MainOrSub = 'main' | 'sub';

const DEFAULT_COLUMN_WIDTH: ColWidth<ShotCategoryRow> = {
  itemCode: 'auto',
  itemName: 'auto',
  unitPrice: 135,
  tax: 120,
  quantityInputType: 'auto',
  unit: 112
};

const DEFAULT_HEADER: Header<ShotCategoryRow> = {
  itemCode: { label: '品目コード' },
  itemName: { label: '品目名' },
  unitPrice: { label: '単価' },
  tax: { label: '課税' },
  quantityInputType: { label: 'ユーザーの利用時間選択' },
  unit: { label: '利用単位' }
};

export interface ShotCategoryRow {
  id: ShotCategoryId;
  itemCode: React.ReactElement;
  itemName: React.ReactElement;
  unitPrice: React.ReactElement;
  tax: React.ReactElement;
  quantityInputType: React.ReactElement;
  unit: React.ReactElement;
}

export type ShotSubCategoryRow = Expand<ShotCategoryRow & { deleteButton: React.ReactElement }>;

const getColumnWidth = (type: MainOrSub): ColWidth<ShotCategoryRow> | ColWidth<ShotSubCategoryRow> => {
  if (type === 'main') return DEFAULT_COLUMN_WIDTH;

  return { ...DEFAULT_COLUMN_WIDTH, deleteButton: 64 };
};

const getHeader = (type: MainOrSub): Header<ShotCategoryRow> | Header<ShotSubCategoryRow> => {
  if (type === 'main') return DEFAULT_HEADER;

  return { ...DEFAULT_HEADER, deleteButton: { label: EMPTY, align: 'center' } };
};

const orderCategories = (categories: ShotCategory[]): ShotCategory[] => {
  return categories.map((category, order) =>
    partialBuilder(category, {
      order,
      subcategories: category.subcategories?.map((sub, order) => partialBuilder(sub, { order }))
    })
  );
};

const isDuplicated = <T,>(arr: T[]) => arr.length !== new Set(arr).size;

const ShotMasterScreen: React.FC<P> = React.memo(props => {
  const { base } = props;

  const { commonRequest } = useCommonRequest();
  const { openSnackbar } = useSnackbarV2();

  const { taxes, fetchTaxes } = useTax();
  const { units, fetchUnits } = useUnit();

  const prevShotCategories = useRef<ShotCategory[]>([]);

  const unmountRef = useUnmountRef();
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [isScreenLoaderShown, setIsScreenLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [shotCategories, setShotCategories] = useSafeState<ShotCategory[]>(unmountRef, []);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);
  const [closedIndexes, setClosedIndexes] = useSafeState<Index[]>(unmountRef, []);

  const [isDragging, setIsDragging] = useSafeState<boolean>(unmountRef, false);

  const unitForTime = units.find(unit => unit.unitName === '時間');

  const getInitialItem = useSafeCallback(() => {
    return builder<ItemV2>()
      .base(base)
      .itemId(uuid())
      .itemCode(EMPTY)
      .itemName(EMPTY)
      .unitPrice(0)
      .taxDiv(TaxDiv.EXCLUDED)
      .tax(taxes.find(({ taxRate }) => taxRate === 10) ?? taxes[0])
      .unit(undefined)
      .build();
  }, [base, taxes]);

  const getInitializeShotCategory = useSafeCallback(() => {
    return builder<ShotCategory>()
      .shotCategoryId(uuid())
      .order(shotCategories.length)
      .base(base)
      .subcategories([])
      .item(getInitialItem())
      .quantityInputType(ShotQuantityInputType.FIXED)
      .defaultQuantity(1)
      .build();
  }, [base, getInitialItem, shotCategories.length]);

  const getInitializeShotSubcategory = useSafeCallback(
    (index: Index) => {
      const target = shotCategories[index];
      return builder<ShotSubcategory>()
        .shotSubcategoryId(uuid())
        .order(target?.subcategories?.length ?? 0)
        .category(target)
        .item(getInitialItem())
        .quantityInputType(ShotQuantityInputType.FIXED)
        .defaultQuantity(1)
        .build();
    },
    [getInitialItem, shotCategories]
  );

  const handleDragEnd = useSafeCallback(
    event => {
      const { active, over } = event;
      if (over == null || active.id === over.id) {
        setIsDragging(false);
        return;
      }
      const oldIndex = shotCategories.findIndex(item => item.shotCategoryId === active.id);
      const newIndex = shotCategories.findIndex(item => item.shotCategoryId === over.id);
      setShotCategories(shotCategories => arraySwap(shotCategories, oldIndex, newIndex));

      const isOldShotSubcategoryClosed = closedIndexes.includes(oldIndex);
      const isNewShotSubCategoryClosed = closedIndexes.includes(newIndex);
      const targetOldIndex = closedIndexes.findIndex(index => index === oldIndex);
      const targetNewIndex = closedIndexes.findIndex(index => index === newIndex);
      setIsDragging(false);
      if (!isOldShotSubcategoryClosed && isNewShotSubCategoryClosed) {
        setClosedIndexes(closedIndexes => {
          closedIndexes.splice(targetNewIndex, 1, oldIndex);
          return closedIndexes.concat();
        });
      }
      if (isOldShotSubcategoryClosed && !isNewShotSubCategoryClosed) {
        setClosedIndexes(closedIndexes => {
          closedIndexes.splice(targetOldIndex, 1, newIndex);
          return closedIndexes.concat();
        });
      }
    },
    [closedIndexes, setClosedIndexes, setIsDragging, setShotCategories, shotCategories]
  );

  const handleShotCategoryItemAdd = useSafeCallback(() => {
    setShotCategories(shotCategories => {
      return shotCategories.concat(getInitializeShotCategory());
    });
  }, [getInitializeShotCategory, setShotCategories]);

  const handleShotSubcategoryItemAdd = useSafeCallback(
    (index: Index) => {
      setShotCategories(shotCategories => {
        const shotCategory = shotCategories[index];
        const subcategories = shotCategory?.subcategories?.concat(getInitializeShotSubcategory(index));
        shotCategories.splice(index, 1, partialBuilder(shotCategory, { subcategories }));
        return shotCategories.concat();
      });
    },
    [getInitializeShotSubcategory, setShotCategories]
  );

  const handleShotCategoryItemChanged = useSafeCallback(
    (index: Index, value: Partial<ItemV2>): void => {
      setShotCategories(shotCategories => {
        const shotCategory = shotCategories[index];
        const newShotCategory = partialBuilder(shotCategory, { item: partialBuilder(shotCategory.item, value) });
        shotCategories.splice(index, 1, newShotCategory);
        return shotCategories.concat();
      });
    },
    [setShotCategories]
  );

  const handleShotSubcategoryItemChanged = useSafeCallback(
    (index: Index, subIndex: Index, value: Partial<ItemV2>): void => {
      setShotCategories(shotCategories => {
        const shotSubcategories = shotCategories[index].subcategories;
        if (!shotSubcategories) return shotCategories;
        const shotSubcategory = shotSubcategories[subIndex];
        const item = partialBuilder(shotSubcategory.item, value);
        const newShotSubcategory = partialBuilder(shotSubcategory, { item });
        shotSubcategories.splice(subIndex, 1, newShotSubcategory);
        const newShotCategory = partialBuilder(shotCategories[index], { subcategories: shotSubcategories.concat() });
        shotCategories.splice(index, 1, newShotCategory);
        return shotCategories.concat();
      });
    },
    [setShotCategories]
  );
  const handleShotCategoryChanged = useSafeCallback(
    (index: Index, value: Partial<ShotCategory>): void => {
      setShotCategories(shotCategories => {
        const shotCategory = shotCategories[index];
        const newShotCategory = partialBuilder(shotCategory, value);
        shotCategories.splice(index, 1, newShotCategory);
        return shotCategories.concat();
      });
    },
    [setShotCategories]
  );

  const handleShotSubcategoryChanged = useSafeCallback(
    (index: Index, subIndex: Index, value: Partial<ShotSubcategory>): void => {
      setShotCategories(shotCategories => {
        const shotSubcategories = shotCategories[index].subcategories;
        if (!shotSubcategories) return shotCategories;
        const shotSubcategory = shotSubcategories[subIndex];
        const newShotSubcategory = partialBuilder(shotSubcategory, value);
        shotSubcategories.splice(subIndex, 1, newShotSubcategory);
        const newShotCategory = partialBuilder(shotCategories[index], { subcategories: shotSubcategories.concat() });
        shotCategories.splice(index, 1, newShotCategory);
        return shotCategories.concat();
      });
    },
    [setShotCategories]
  );

  const handleShotCategoryDeleted = useSafeCallback(
    (index: Index) => {
      setShotCategories(shotCategories => {
        shotCategories.splice(index, 1);
        return shotCategories.concat();
      });
    },
    [setShotCategories]
  );

  const handleShotSubcategoryDeleted = useSafeCallback(
    (index: Index, subIndex: Index) => {
      setShotCategories(shotCategories => {
        shotCategories[index].subcategories?.splice(subIndex, 1);
        return shotCategories.concat();
      });
    },
    [setShotCategories]
  );

  const clearErrorMessages = useSafeCallback((): void => {
    setErrorMessage(EMPTY);
  }, [setErrorMessage]);

  const inValidDuplicateCategoryItemCode = useSafeCallback((): boolean => {
    const itemCodes = shotCategories.map(({ item }) => item?.itemCode ?? EMPTY).filter(code => code !== EMPTY);
    return isDuplicated(itemCodes);
  }, [shotCategories]);

  const inValidDuplicateSubCategoryItemCode = useSafeCallback((): boolean => {
    return shotCategories.some(category => {
      const subcategories = category?.subcategories;
      if (!subcategories?.length) return false;

      const uniqueCodes = new Set(subcategories.map(({ item }) => item?.itemCode));
      return uniqueCodes.size !== subcategories.length;
    });
  }, [shotCategories]);

  const handleExpandSubCategoriesClicked = useSafeCallback(
    (targetIndex: Index) => {
      setClosedIndexes(indexes => {
        if (indexes.includes(targetIndex)) {
          const index = indexes.findIndex(index => index === targetIndex);
          indexes.splice(index, 1);
        } else {
          indexes.push(targetIndex);
        }
        return indexes.concat();
      });
    },
    [setClosedIndexes]
  );

  const handleShotSubcategoriesSorted = useSafeCallback(
    (parentIndex, oldIndex, newIndex) => {
      setShotCategories(shotCategories => {
        const shotSubcategories = shotCategories[parentIndex].subcategories;
        if (!shotSubcategories) return shotCategories;
        const newShotCategory = partialBuilder(shotCategories[parentIndex], {
          subcategories: arraySwap(shotSubcategories, oldIndex, newIndex)
        });
        shotCategories.splice(parentIndex, 1, newShotCategory);
        return shotCategories.concat();
      });
    },
    [setShotCategories]
  );

  const taxMapping = useMemo<TaxMapping>(() => {
    return toTaxMapping(taxes);
  }, [taxes]);

  const taxPullDownOptions = useMemo<PullDownOption[]>(() => {
    return Object.entries(taxMapping).map(([key, obj]) => {
      const label = toTaxLabel(obj);
      return builder<PullDownOption>().id(key).label(label).build();
    });
  }, [taxMapping]);

  const unitPullDownOptions = useMemo<PullDownOption[]>(() => {
    return units.map(unit => builder<PullDownOption>().id(unit.unitId).label(unit.unitName).build());
  }, [units]);

  const rows = useMemo<[ShotCategoryRow[], ShotSubCategoryRow[]][]>(() => {
    return shotCategories.map((category, index) => {
      const shotCategoryRows: ShotCategoryRow[] = [
        builder<ShotCategoryRow>()
          .id(category.shotCategoryId)
          .itemCode(
            <StyledTextField
              name='item-code'
              value={category.item?.itemCode}
              onChange={itemCode => handleShotCategoryItemChanged(index, { itemCode })}
            />
          )
          .itemName(
            <StyledTextField
              name='item-name'
              value={category.item?.itemName}
              onChange={itemName => handleShotCategoryItemChanged(index, { itemName })}
            />
          )
          .unitPrice(
            <StyledTextField
              name='unit-price'
              align='right'
              inputType='price'
              value={category.item?.unitPrice.toLocaleString()}
              onChange={(unitPrice: PriceStr) => {
                return handleShotCategoryItemChanged(index, {
                  unitPrice: parseInt(unitPrice)
                });
              }}
            />
          )
          .tax(
            <PullDownV2
              name='tax'
              id={getTaxKey(category.item?.tax?.taxId, category.item?.taxDiv, category.item?.tax?.taxRate)}
              options={taxPullDownOptions}
              height={40}
              onClick={id => {
                const { taxId, taxDiv } = taxMapping[id];
                const tax = taxes.find(tax => tax.taxId === taxId);
                handleShotCategoryItemChanged(index, { tax, taxDiv });
              }}
            />
          )
          .quantityInputType(
            <RadioButton
              options={Object.values(ShotQuantityInputType)}
              labels={SHOT_QUANTITY_INPUT_TYPE_LABELS}
              value={category.quantityInputType}
              onChange={quantityInputType => {
                handleShotCategoryChanged(index, { quantityInputType });
                if (quantityInputType === ShotQuantityInputType.MANUAL) {
                  handleShotCategoryItemChanged(index, { unit: unitForTime });
                }
              }}
            />
          )
          .unit(
            <PullDownV2
              readonly={category.quantityInputType === ShotQuantityInputType.MANUAL}
              name='unit'
              id={category.item?.unit?.unitId ?? EMPTY}
              options={unitPullDownOptions}
              height={40}
              nullable
              onClick={unitId => {
                const unit = units.find(unit => unit.unitId === unitId);
                handleShotCategoryItemChanged(index, { unit });
              }}
            />
          )
          .build()
      ];
      const shotSubCategoryRows: ShotSubCategoryRow[] =
        category.subcategories?.map((subcategory, subIndex) => {
          const preBuild = builder<ShotSubCategoryRow>()
            .id(subcategory.shotSubcategoryId)
            .itemCode(
              <StyledTextField
                name='item-code'
                value={subcategory.item?.itemCode}
                onChange={itemCode => handleShotSubcategoryItemChanged(index, subIndex, { itemCode })}
              />
            )
            .itemName(
              <StyledTextField
                name='item-name'
                value={subcategory.item?.itemName}
                onChange={itemName => handleShotSubcategoryItemChanged(index, subIndex, { itemName })}
              />
            )
            .unitPrice(
              <StyledTextField
                name='unit-price'
                align='right'
                inputType='price'
                value={subcategory.item?.unitPrice.toLocaleString()}
                onChange={(unitPrice: PriceStr) => {
                  return handleShotSubcategoryItemChanged(index, subIndex, {
                    unitPrice: parseInt(unitPrice)
                  });
                }}
              />
            )
            .tax(
              <PullDownV2
                name='tax'
                id={getTaxKey(subcategory.item?.tax?.taxId, subcategory.item?.taxDiv, subcategory.item?.tax?.taxRate)}
                options={taxPullDownOptions}
                height={40}
                onClick={id => {
                  const { taxId, taxDiv } = taxMapping[id];
                  const tax = taxes.find(tax => tax.taxId === taxId);
                  handleShotSubcategoryItemChanged(index, subIndex, { tax, taxDiv });
                }}
              />
            )

            .quantityInputType(
              <RadioButton
                options={Object.values(ShotQuantityInputType)}
                labels={SHOT_QUANTITY_INPUT_TYPE_LABELS}
                value={subcategory.quantityInputType}
                onChange={quantityInputType => {
                  handleShotSubcategoryChanged(index, subIndex, { quantityInputType });
                  if (quantityInputType === ShotQuantityInputType.MANUAL) {
                    handleShotSubcategoryItemChanged(index, subIndex, { unit: unitForTime });
                  }
                }}
              />
            )
            .unit(
              <PullDownV2
                readonly={subcategory.quantityInputType === ShotQuantityInputType.MANUAL}
                name='unit'
                id={subcategory.item?.unit?.unitId ?? EMPTY}
                options={unitPullDownOptions}
                height={40}
                nullable
                onClick={unitId => {
                  const unit = units.find(unit => unit.unitId === unitId);
                  handleShotSubcategoryItemChanged(index, subIndex, { unit });
                }}
              />
            );
          return preBuild
            .deleteButton(
              <IconButtonV2
                shape='rect'
                icon={<CloseRoundedIcon data-testid='close-icon' />}
                onClick={() => handleShotSubcategoryDeleted(index, subIndex)}
              />
            )
            .build();
        }) ?? [];

      return [shotCategoryRows, shotSubCategoryRows];
    });
  }, [
    handleShotCategoryChanged,
    handleShotCategoryItemChanged,
    handleShotSubcategoryChanged,
    handleShotSubcategoryDeleted,
    handleShotSubcategoryItemChanged,
    shotCategories,
    taxMapping,
    taxPullDownOptions,
    taxes,
    unitForTime,
    unitPullDownOptions,
    units
  ]);

  const fetchShotCategories = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchShotCategoriesForAdminRequest>().baseId(base.baseId).build();
    const response = await commonRequest<FetchShotCategoriesForAdminRequest, FetchShotCategoriesForAdminResponse>(
      FETCH_SHOT_CATEGORIES_FOR_ADMIN,
      request
    );

    prevShotCategories.current = response.shotCategories;

    setShotCategories(response.shotCategories);
  }, [base.baseId, commonRequest, setShotCategories]);

  const refreshShotCategories = useSafeCallback(async (): Promise<void> => {
    setIsScreenLoaderShown(true);
    await fetchShotCategories();
    setIsScreenLoaderShown(false);
  }, [fetchShotCategories, setIsScreenLoaderShown]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    await Promise.all([fetchTaxes(base.baseId), fetchUnits(base.baseId), fetchShotCategories()]);
    setIsLoaderShown(false);
  }, [fetchShotCategories, fetchTaxes, fetchUnits, setIsLoaderShown, base]);

  const saveShotCategories = useSafeCallback(async (): Promise<void> => {
    clearErrorMessages();
    const errorMessages = [validate(shotCategories)];
    if (inValidDuplicateCategoryItemCode()) {
      errorMessages.push('選択肢の品目コードが重複しています');
    }
    if (inValidDuplicateSubCategoryItemCode()) {
      errorMessages.push('追加の選択肢の品目コードが重複しています');
    }
    if (errorMessages.some(message => message !== EMPTY)) {
      setErrorMessage(errorMessages.filter(code => code !== EMPTY).join('\n'));
      openSnackbar('マスタの保存に失敗しました', 'error', 3000);
      return;
    }

    setIsScreenLoaderShown(true);
    const request = builder<SaveShotCategoriesForAdminRequest>()
      .baseId(base.baseId)
      .shotCategories(orderCategories(shotCategories))
      .build();

    const response = await commonRequest<SaveShotCategoriesForAdminRequest, SaveShotCategoriesForAdminResponse>(
      SAVE_SHOT_CATEGORIES_FOR_ADMIN,
      request
    );

    if (hasLength(response.shotCategoryIds)) {
      openSnackbar('マスタを保存しました', 'success', 3000);
      await fetchShotCategories();
    } else {
      openSnackbar('マスタの保存に失敗しました', 'error', 3000);
    }
    setIsScreenLoaderShown(false);
  }, [
    base.baseId,
    clearErrorMessages,
    commonRequest,
    fetchShotCategories,
    openSnackbar,
    setErrorMessage,
    setIsScreenLoaderShown,
    shotCategories,
    inValidDuplicateCategoryItemCode,
    inValidDuplicateSubCategoryItemCode
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Component className='shot-master-screen' loading={isLoaderShown} style={styleForComponent}>
      <Container>
        <PageHeaderV2 title='マスタ設定 入館受付' />
        <DndContext
          onDragStart={() => setIsDragging(true)}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext items={rows.flatMap(([shotCategoryRows]) => shotCategoryRows.map(row => row.id))}>
            {rows.map(([shotCategoryRows, shotSubCategoryRows], index) => (
              <ShotCategoryCard
                key={`shot-category-card-${index}`}
                base={base}
                isDeletable={shotCategories.length > 1}
                isExpanded={!closedIndexes.includes(index) && !isDragging}
                shotCategoryRow={shotCategoryRows[FIRST_INDEX]}
                shotSubcategoryRows={shotSubCategoryRows}
                getColumnWidth={getColumnWidth}
                getHeader={getHeader}
                onAdd={() => handleShotSubcategoryItemAdd(index)}
                onDelete={() => handleShotCategoryDeleted(index)}
                onExpand={() => handleExpandSubCategoriesClicked(index)}
                onSort={(oldIndex, newIndex) => handleShotSubcategoriesSorted(index, oldIndex, newIndex)}
              />
            ))}
          </SortableContext>
        </DndContext>
        <ButtonV2
          startIcon={<AddRoundedIcon />}
          type='secondary'
          label='ブロックを追加'
          onClick={handleShotCategoryItemAdd}
        />
      </Container>
      {hasLength(rows) && (
        <Footer data-testid='shot-master-screen-footer' width={DRAWER_WIDTH}>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <ButtonWrapper>
            <ButtonV2 type='secondary' label={'キャンセル'} onClick={refreshShotCategories} />
            <ButtonV2
              type='primary'
              disabled={Object.is(shotCategories, prevShotCategories.current)}
              label={'保存'}
              onClick={saveShotCategories}
            />
          </ButtonWrapper>
        </Footer>
      )}
      <ScreenLoaderV2 loading={isScreenLoaderShown} />
    </Component>
  );
});

ShotMasterScreen.displayName = 'ShotMasterScreen';
export default ShotMasterScreen;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  padding-bottom: ${themeV2.mixins.v2.spacing * 10}px;
`;

const StyledTextField = styled(TextFieldV2).attrs(() => ({ height: 40 }))``;

const Footer = styled.div<{ width: Width }>`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: ${({ width }) => `calc(100vw - ${width}px)`};
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 3}px;
  justify-content: center;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
  position: fixed;
  bottom: 0;
  z-index: 100;
  box-shadow: ${themeV2.mixins.v2.shadow.elevationFooterShadow};
  background-color: ${themeV3.mixins.v3.color.container.neutral.row};
  white-space: pre-wrap;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ErrorMessage = styled.div`
  ${themeV2.mixins.v2.typography.body.small};
  color: ${themeV2.mixins.v2.color.font.red};
`;
