import {
  ButtonV2,
  DialogV2,
  IconButtonV2,
  themeV2,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  DELETE_INTRODUCING_OTHER_FOR_ADMIN,
  DeleteIntroducingOtherForAdminRequest,
  DeleteIntroducingOtherForAdminResponse,
  IntroducingOther,
  SEARCH_INTRODUCING_OTHERS_FOR_ADMIN,
  SearchIntroducingOthersForAdminRequest,
  SearchIntroducingOthersForAdminResponse,
  User
} from '@atomica.co/irori';
import { Index, Message, Offset, Text, USER_ID, UserId } from '@atomica.co/types';
import { ZERO, builder, copyText, hasLength } from '@atomica.co/utils';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import EditOutlined from '@material-ui/icons/EditOutlined';
import FileCopyOutline from '@material-ui/icons/FileCopyOutlined';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { SUCCESS } from '../../../../constants/snackbar-const';
import useCommonRequest from '../../../../redux/hooks/useCommonRequest';
import usePath from '../../../../redux/hooks/usePath';
import { INTRODUCTION_MESSAGE_COPIED } from '../../../../texts/snackbar-text';
import { toFullName } from '../../../../utils/user-util';
import RegisterIntroModal from '../../modal/RegisterIntroModal';

const LIMIT = 10;

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 300px 0px'
};

interface P {
  base: BaseDto;
  selectedUser: User;
  signInUser: User;
}

const IntroductionSection: React.FC<P> = React.memo(props => {
  const { base, selectedUser, signInUser } = props;
  const unmountRef = useUnmountRef();
  const { params } = usePath();
  const { enqueueSnackbar } = useSnackbar();
  const { commonRequest } = useCommonRequest();

  const hasMore = useRef<boolean>(true);
  const offset = useRef<Offset>(ZERO);
  const bottomRef = useRef<HTMLDivElement>(null);

  const [isRegisterModalOpen, setIsRegisterModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [selectedIntroducingOther, setSelectedIntroducingOther] = useSafeState<IntroducingOther | null>(
    unmountRef,
    null
  );
  const [introducingOthers, setIntroducingOthers] = useSafeState<IntroducingOther[]>(unmountRef, []);
  const selectedUserId = useMemo<UserId>(() => params[USER_ID], [params]);
  const [isEditable, setIsEditable] = useSafeState<boolean>(unmountRef, true);
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);

  const isIntroductingOtherExisting = useMemo<boolean>(
    () =>
      introducingOthers &&
      !!introducingOthers.find(
        intro => intro.toUser?.userId === selectedUser.userId && intro.fromUser?.userId === signInUser.userId
      ),
    [introducingOthers, selectedUser, signInUser]
  );

  const searchIntroductionOthers = useSafeCallback(async (): Promise<IntroducingOther[]> => {
    if (!hasMore.current) return [];
    setIsLoaderShown(true);

    const request = builder<SearchIntroducingOthersForAdminRequest>()
      .limit(LIMIT)
      .offset(offset.current)
      .userId(selectedUserId)
      .baseId(base.baseId)
      .build();
    const response = await commonRequest<
      SearchIntroducingOthersForAdminRequest,
      SearchIntroducingOthersForAdminResponse
    >(SEARCH_INTRODUCING_OTHERS_FOR_ADMIN, request);
    const { introducingOthers } = response;

    offset.current += 1;
    hasMore.current = introducingOthers.length === LIMIT;

    setIsLoaderShown(false);

    return introducingOthers;
  }, [base.baseId, commonRequest, selectedUserId, setIsLoaderShown]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const introducingOthers = await searchIntroductionOthers();
    if (hasLength(introducingOthers)) {
      setIntroducingOthers(prevIntro => Array.from(new Set([...prevIntro, ...introducingOthers])));
    }
  }, [searchIntroductionOthers, setIntroducingOthers]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const onScroll = useSafeCallback(
    async (entries: IntersectionObserverEntry[]): Promise<void> => {
      for (const entry of entries) {
        if (!entry.isIntersecting) return;

        const introducingOthers = await searchIntroductionOthers();
        setIntroducingOthers(prevIntro => Array.from(new Set([...prevIntro, ...introducingOthers])));
      }
    },
    [searchIntroductionOthers, setIntroducingOthers]
  );

  useEffect(() => {
    if (isLoaderShown || !hasMore.current) return;
    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);
    bottomRef.current && observer.observe(bottomRef.current);
    return () => observer.disconnect();
  }, [isLoaderShown, onScroll]);

  const handleIntroducingOtherClicked = useSafeCallback(
    (value: IntroducingOther): void => {
      setIsEditable(false);
      setSelectedIntroducingOther(value);
      setIsRegisterModalOpen(true);
    },
    [setIsEditable, setSelectedIntroducingOther, setIsRegisterModalOpen]
  );

  const copyMessage = useSafeCallback(
    (message: Text): void => {
      copyText(message);
      enqueueSnackbar(INTRODUCTION_MESSAGE_COPIED, { variant: SUCCESS });
    },
    [enqueueSnackbar]
  );

  const handleIntroSaved = useSafeCallback(
    (newIntro: IntroducingOther): void => {
      setIntroducingOthers(prevIntros => {
        const prevIntro = prevIntros.find(intro => intro.introducingOtherId === newIntro.introducingOtherId);

        const updatedIntros = prevIntros.map(prevIntro => {
          return prevIntro.introducingOtherId === newIntro.introducingOtherId ? newIntro : prevIntro;
        });

        return prevIntro ? updatedIntros : [newIntro, ...prevIntros];
      });
      setIsRegisterModalOpen(false);
    },
    [setIntroducingOthers, setIsRegisterModalOpen]
  );

  const handleEditButtonClicked = useSafeCallback(
    (introducingOther: IntroducingOther): void => {
      setSelectedIntroducingOther(introducingOther);
      setIsRegisterModalOpen(true);
    },
    [setIsRegisterModalOpen, setSelectedIntroducingOther]
  );

  const handleCopyButtonClicked = useSafeCallback(
    (message: Message): void => {
      copyMessage(message);
    },
    [copyMessage]
  );

  const handleClearButtonClicked = useSafeCallback(
    (selectedIntroducingOther: IntroducingOther) => {
      setIsDeleteModalOpen(true);
      setSelectedIntroducingOther(selectedIntroducingOther);
    },
    [setIsDeleteModalOpen, setSelectedIntroducingOther]
  );

  const deleteIntroducingOther = useSafeCallback(
    async (introducingOther: IntroducingOther | null): Promise<void> => {
      if (!introducingOther) return;
      const request = builder<DeleteIntroducingOtherForAdminRequest>()
        .baseId(base.baseId)
        .introducingOtherId(introducingOther.introducingOtherId)
        .build();
      const response = await commonRequest<
        DeleteIntroducingOtherForAdminRequest,
        DeleteIntroducingOtherForAdminResponse
      >(DELETE_INTRODUCING_OTHER_FOR_ADMIN, request);
      if (response.introducingOtherId) {
        offset.current = ZERO;
        hasMore.current = true;
        await initialize();
        setIsDeleteModalOpen(false);
        setSelectedIntroducingOther(null);
      }
    },
    [base, commonRequest, initialize, setIsDeleteModalOpen, setSelectedIntroducingOther]
  );

  return (
    <DataContainer data-testid='introduction-section'>
      <Heading>他己紹介</Heading>
      {!isIntroductingOtherExisting && (
        <AddIntroButton
          onClick={() => {
            setSelectedIntroducingOther(null);
            setIsRegisterModalOpen(true);
          }}
        >
          <EditOutlined color='inherit' /> 他己紹介を書く
        </AddIntroButton>
      )}
      {introducingOthers.map((row: IntroducingOther, index: Index) => (
        <MessageContainer key={index} onClick={() => handleIntroducingOtherClicked(row)}>
          <MessageImageContainer>
            <FlexHolder>
              <SmallImage src={row.fromUser!.photoURL} />
              <TxtHolder>
                <RowUpperText>{`${toFullName(row.fromUser!)}`}</RowUpperText>
                <RowLowerText>{row.fromUser!.companyName}</RowLowerText>
              </TxtHolder>
            </FlexHolder>

            <IconsWrapper>
              {row.fromUser?.userId === signInUser.userId && (
                <IconButtonV2 icon={<EditOutlined />} onClick={() => handleEditButtonClicked(row)} />
              )}
              <IconButtonV2 icon={<FileCopyOutline />} onClick={() => handleCopyButtonClicked(row.message)} />
              {row.fromUser?.userId === signInUser.userId && (
                <IconButtonV2 icon={<ClearOutlinedIcon />} onClick={() => handleClearButtonClicked(row)} />
              )}
            </IconsWrapper>
          </MessageImageContainer>

          <MessageText>{row.message}</MessageText>
        </MessageContainer>
      ))}

      {!isLoaderShown && !hasLength(introducingOthers) && (
        <NoIntroArea>
          <NoIntroMessage>まだ他己紹介文がありません。</NoIntroMessage>
          <ButtonV2
            type='secondary'
            label='他己紹介文を書く'
            startIcon={<EditOutlined color='inherit' />}
            onClick={() => setIsRegisterModalOpen(true)}
          />
        </NoIntroArea>
      )}

      <Bottom ref={bottomRef} />

      <DialogV2
        width={350}
        isOpen={isDeleteModalOpen}
        headerLabel='他己紹介を削除しますか？'
        buttonsOnTheRight={[
          <ButtonV2 key='cancel' label='いいえ' onClick={() => setIsDeleteModalOpen(false)} />,
          <ButtonV2
            type='primary'
            key='sign-out'
            label='はい'
            onClick={() => deleteIntroducingOther(selectedIntroducingOther)}
          />
        ]}
        onClose={() => setIsDeleteModalOpen(false)}
      />

      <RegisterIntroModal
        base={base}
        isOpen={isRegisterModalOpen}
        selectedUser={selectedUser}
        fromUser={selectedIntroducingOther?.fromUser ? selectedIntroducingOther.fromUser : signInUser}
        prevIntroducingOther={selectedIntroducingOther}
        onSave={handleIntroSaved}
        isEditable={isEditable}
        onClose={() => {
          setIsRegisterModalOpen(false);
          setIsEditable(true);
        }}
      />
    </DataContainer>
  );
});

IntroductionSection.displayName = 'IntroductionSection';
export default IntroductionSection;

const Heading = styled.p`
  ${themeV2.mixins.v2.typography.title.xLarge};
  background-color: ${themeV2.mixins.v2.color.font.white};
  margin: 0px;
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
  padding: ${themeV2.mixins.v2.spacing * 2}px 0;
`;

const RowUpperText = styled.p`
  margin: 0px;
  padding: 2px 0px;
  font-size: 14px;
  color: ${themeV2.mixins.v2.color.font.black};
  font-weight: 700;
`;

const RowLowerText = styled.p`
  margin: 0px;
  padding: 2px 0px;
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const DataContainer = styled.div`
  background-color: ${themeV2.mixins.v2.color.font.white};
  border-radius: 8px;
  min-height: 465px;
  max-height: 680px;
  overflow-y: scroll;
  padding-left: 24px;
  padding-right: 24px;
  flex: 1;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const MessageContainer = styled.div`
  border-radius: 8px;
  background-color: ${themeV2.mixins.v2.color.background.offWhite};
  padding: 16px;
  margin: 8px 0px;
  transition-duration: 0.5s;
  transition-property: background-color;
  &:hover {
    cursor: pointer;
    background-color: ${themeV3.mixins.v3.color.container.neutral.middle};
  }
`;

const MessageImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SmallImage = styled.img`
  max-width: 40px;
  max-height: 40px;
  border-radius: 20px;
  overflow: hidden;
`;

const FlexHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TxtHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MessageText = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.black};
  padding-top: ${themeV2.mixins.v2.spacing}px;
  margin: 0px;
  white-space: break-spaces;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  word-break: break-all;
`;

const NoIntroArea = styled.div`
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const NoIntroMessage = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  margin-bottom: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Bottom = styled.div``;

const AddIntroButton = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
  border: 2px dashed ${themeV2.mixins.v2.color.border.gray};
  border-radius: 8px;
  text-align: center;
  padding: ${themeV2.mixins.v2.spacing * 2}px 0;
  margin: ${themeV2.mixins.v2.spacing * 2}px 0;
  cursor: pointer;
  color: ${themeV2.mixins.v2.color.font.gray};
`;
