import {
  ButtonV2,
  CheckBoxV2,
  ChipButton,
  ColWidth,
  Component,
  DateFieldV2,
  Header,
  PAGINATE_HEIGHT,
  PageHeaderV2,
  SearchListV2,
  StatusV2,
  TextFieldV2,
  styleForFullExpansion,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  SEARCH_WISHES_FOR_ADMIN,
  SearchWishesForAdminRequest,
  SearchWishesForAdminResponse,
  User,
  Wish,
  WishId,
  WishStatus,
  WishType,
  toFullName
} from '@atomica.co/irori';
import { Content, Count, Index, Name, Offset, Title, Word } from '@atomica.co/types';
import { EMPTY, ZERO, builder, embedIdInPath, toBeginningOfDay, toEndOfDay } from '@atomica.co/utils';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import PersonInCharge from '../../components/tags/PersonInCharge';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../router/Routes';
import { WISH_STATUS_TEXT, WISH_TYPE_TEXT } from '../../texts/wish-text';
import { toStatus } from '../../utils/wish-util';

const LIMIT = 50;

export const WISH_COLUMN_WIDTH: ColWidth<WishRow> = {
  title: 200,
  content: 200,
  fromUser: 120,
  status: 80,
  toUser: 80
};

export const WISH_HEADER: Header<WishRow> = {
  title: { label: 'タイトル' },
  content: { label: '内容' },
  fromUser: { label: 'ユーザー' },
  status: { label: 'ステータス' },
  toUser: { label: '担当者' }
};

export interface WishRow {
  id: WishId;
  title: Title;
  content: Content;
  fromUser: React.ReactNode;
  status: React.ReactElement;
  toUser: Name | JSX.Element;
}

interface P {
  base: BaseDto;
}

const getFromUserName = (wish: Wish): Name => {
  if (!wish.fromUser) return wish.fromUserName ?? EMPTY;
  return toFullName(wish.fromUser);
};

const handleCheckboxClicked = <T extends WishStatus | WishType>(
  value: T,
  setValues: React.Dispatch<React.SetStateAction<T[]>>
): void => {
  setValues((prevValues: T[]) => {
    const prevType = prevValues.find(type => type === value);
    return prevType ? prevValues.filter(type => type !== value) : [...prevValues, value];
  });
};

const WishListScreen: React.FC<P> = React.memo(props => {
  const { base } = props;
  const { openBasePath, openPathInNewTab } = usePath();
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();
  const [searchCond, setSearchCond] = useSafeState<SearchWishesForAdminRequest>(
    unmountRef,
    builder<SearchWishesForAdminRequest>().baseId(base.baseId).build()
  );

  // Facet
  const [statuses, setStatuses] = useSafeState<WishStatus[]>(unmountRef, []);
  const [types, setTypes] = useSafeState<WishType[]>(unmountRef, []);
  const [fromDate, setFromDate] = useSafeState<Date | null>(unmountRef, null);
  const [toDate, setToDate] = useSafeState<Date | null>(unmountRef, null);
  const [editablePic, setEditablePic] = useSafeState<boolean>(unmountRef, false);
  const [editableFromUser, setEditableFromUser] = useSafeState<boolean>(unmountRef, false);
  const [toUsers, setToUsers] = useSafeState<User[]>(unmountRef, []);
  const [fromUsers, setFromUsers] = useSafeState<User[]>(unmountRef, []);
  const [companyName, setCompanyName] = useSafeState<Name>(unmountRef, EMPTY);
  const [wishWord, setWishWord] = useSafeState<Word>(unmountRef, EMPTY);

  const handleSearchClicked = useSafeCallback((): void => {
    const searchCond = builder<SearchWishesForAdminRequest>()
      .baseId(base.baseId)
      .statuses(statuses)
      .toUserIds(editablePic ? toUsers.map(opt => opt.userId) : [])
      .fromUserIds(editableFromUser ? fromUsers.map(opt => opt.userId) : [])
      .fromDate(fromDate ? toBeginningOfDay(new Date(fromDate)) : undefined)
      .toDate(toDate ? toEndOfDay(new Date(toDate)) : undefined)
      .types(types)
      .companyName(companyName)
      .wishWord(wishWord)
      .build();

    setSearchCond(searchCond);
  }, [
    base.baseId,
    statuses,
    toUsers,
    fromUsers,
    fromDate,
    toDate,
    setSearchCond,
    types,
    companyName,
    wishWord,
    editablePic,
    editableFromUser
  ]);

  const handlePicSelected = useSafeCallback((): void => {
    setEditablePic(editable => {
      editable && setToUsers([]);
      return !editable;
    });
  }, [setEditablePic, setToUsers]);

  const handleUserNameSelected = useSafeCallback((): void => {
    setEditableFromUser(editable => {
      editable && setFromUsers([]);
      return !editable;
    });
  }, [setEditableFromUser, setFromUsers]);

  //list
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, ZERO);
  const [wishes, setWishes] = useSafeState<Wish[]>(unmountRef, []);
  const [totalRecordCount, setTotalRecordCount] = useSafeState<Count>(unmountRef, ZERO);
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);

  const rows = useMemo<WishRow[]>(() => {
    return wishes.map(wish =>
      builder<WishRow>()
        .id(wish.wishId)
        .title(wish.content)
        .content(wish.remarks)
        .fromUser(
          <>
            <UserText>{getFromUserName(wish)}</UserText>
            {wish.fromUser?.companyName && <UserText>{wish.fromUser.companyName}</UserText>}
          </>
        )
        .status(<StatusV2 label={WISH_STATUS_TEXT[wish.status]} status={toStatus(wish.status)} width={74} />)
        .toUser(wish.toUser?.photoURL ? <TableImage src={wish.toUser.photoURL} /> : toFullName(wish.toUser))
        .build()
    );
  }, [wishes]);

  const searchWishes = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<SearchWishesForAdminRequest>()
      .baseId(base.baseId)
      .limit(LIMIT)
      .wishWord(searchCond.wishWord)
      .offset(offset)
      .statuses(searchCond.statuses)
      .toUserIds(searchCond.toUserIds)
      .fromUserIds(searchCond.fromUserIds)
      .fromDate(searchCond.fromDate)
      .toDate(searchCond.toDate)
      .types(searchCond.types)
      .companyName(searchCond.companyName)
      .build();

    // await getBsWish().searchWishesForAdmin(base.baseId, request);
    const response = await commonRequest<SearchWishesForAdminRequest, SearchWishesForAdminResponse>(
      SEARCH_WISHES_FOR_ADMIN,
      request
    );
    const { wishes, totalCount } = response;

    setWishes(wishes);
    setTotalRecordCount(totalCount);
    setIsLoaderShown(false);
  }, [base.baseId, commonRequest, offset, searchCond, setIsLoaderShown, setTotalRecordCount, setWishes]);

  const handleClickRow = useSafeCallback(
    (index: Index): void => {
      const wishId: WishId = wishes[index].wishId;
      openPathInNewTab(embedIdInPath(Path.WISH_DETAILS, PATH_IDS, [base.baseCode, wishId]));
    },
    [wishes, base, openPathInNewTab]
  );

  useEffect(() => {
    searchWishes();
  }, [searchWishes]);

  const handleSearchingWordChanged = useSafeCallback(
    (word: Word): void => {
      setWishWord(word);
      setOffset(ZERO);
    },
    [setOffset, setWishWord]
  );

  const navigateHandler = useSafeCallback((): void => {
    openBasePath(Path.REGISTER_WISH);
  }, [openBasePath]);

  return (
    <Component style={styleForFullExpansion} className='wish-screen'>
      <Container>
        <Facet>
          <Heading>フィルター</Heading>
          <Filter data-testid='free-word'>
            <FilterHeading>フリーワード</FilterHeading>
            <TextFieldV2 value={wishWord} placeholder='タイトル,内容で検索' onChange={handleSearchingWordChanged} />
          </Filter>
          <Filter>
            <FilterHeading>ユーザー </FilterHeading>
            <ChipButton
              variant='outlined'
              label='ユーザーを追加'
              icon={<StyledAddIcon selected={editableFromUser} />}
              selected={editableFromUser}
              onClick={handleUserNameSelected}
            />

            {editableFromUser && <PersonInCharge editable base={base} toUser={fromUsers} setToUser={setFromUsers} />}
          </Filter>

          <Filter>
            <FilterHeading>ユーザーの所属</FilterHeading>
            <TextFieldV2 value={companyName} placeholder='テキスト検索' onChange={setCompanyName} />
          </Filter>

          <Filter>
            <FilterHeading>ステータス</FilterHeading>
            <CheckboxGroup>
              {Object.values(WishStatus).map((status, index) => (
                <CheckBoxV2
                  key={`wish-status-checkbox-${index}`}
                  checked={statuses.includes(status)}
                  onChange={() => handleCheckboxClicked(status, setStatuses)}
                >
                  <FilterText>{WISH_STATUS_TEXT[status]}</FilterText>
                </CheckBoxV2>
              ))}
            </CheckboxGroup>
          </Filter>

          <Filter>
            <FilterHeading>タイプ</FilterHeading>
            <CheckboxGroup>
              {Object.values(WishType).map((type, index) => (
                <CheckBoxV2
                  key={`wish-type-checkbox-${index}`}
                  checked={types.includes(type)}
                  onChange={() => handleCheckboxClicked(type, setTypes)}
                >
                  <FilterText>{WISH_TYPE_TEXT[type]}</FilterText>
                </CheckBoxV2>
              ))}
            </CheckboxGroup>
          </Filter>

          <Filter>
            <FilterHeading>担当者</FilterHeading>
            <ChipButton
              variant='outlined'
              label='担当者を追加'
              icon={<StyledAddIcon selected={editablePic} />}
              selected={editablePic}
              onClick={handlePicSelected}
            />

            {editablePic && <PersonInCharge editable base={base} toUser={toUsers} setToUser={setToUsers} />}
          </Filter>
          <Filter>
            <FilterHeading>作成日</FilterHeading>
            <DateWrapper>
              <DateRow>
                <DateRowLabel>開始</DateRowLabel>
                <DateFieldV2 value={fromDate} onChange={setFromDate} clearable={true} />
              </DateRow>
              <DateRow>
                <DateRowLabel>終了</DateRowLabel>
                <DateFieldV2 value={toDate} onChange={setToDate} required={false} clearable={true} />
              </DateRow>
            </DateWrapper>
          </Filter>
          <ButtonV2 isFullWidth type='primary' label='フィルターを適用' onClick={handleSearchClicked} />
        </Facet>

        <ListContent>
          <PageHeaderV2
            title='Wish'
            rightComponent={
              <ButtonV2
                type='primary'
                label='新規作成'
                startIcon={<AddIcon style={{ color: themeV2.mixins.v2.color.font.white }} />}
                onClick={navigateHandler}
              />
            }
          />
          <SearchListV2
            name='Wish'
            title={`${totalRecordCount}件のWish`}
            colWidth={WISH_COLUMN_WIDTH}
            header={WISH_HEADER}
            rows={rows}
            paginateProps={{ offset, limit: LIMIT, totalCount: totalRecordCount, setOffset }}
            isLoaderShown={isLoaderShown}
            onClickRow={handleClickRow}
          />
        </ListContent>
      </Container>
    </Component>
  );
});

WishListScreen.displayName = 'WishListScreen';
export default WishListScreen;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const Facet = styled.div`
  width: 290px;
  height: 100%;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  background-color: ${themeV2.mixins.v2.color.font.white};
  border-right: 1px solid ${themeV2.mixins.v2.color.font.lightGray};
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  overflow-x: hidden;
  ${themeV2.mixins.v2.scrollbarInvisible};
`;

const Heading = styled.div`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const FilterHeading = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const FilterText = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
`;

const CheckboxGroup = styled.div`
  cursor: pointer;
`;

const StyledAddIcon = styled(AddIcon)<{ selected: boolean }>`
  color: ${props => (props.selected ? themeV2.mixins.v2.color.font.white : themeV2.mixins.v2.color.font.gray)};
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const DateRowLabel = styled.div`
  min-width: 32px;
  width: max-content;
  ${themeV2.mixins.v2.typography.body.medium};
`;

const DateRow = styled.div`
  width: 100%;
  display: flex;
  gap: ${themeV2.mixins.v2.spacing}px;
  align-items: center;
`;

const ListContent = styled.div`
  flex: 1;
  height: calc(100% - ${PAGINATE_HEIGHT}px);
  display: flex;
  flex-flow: column;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const UserText = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const TableImage = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: 2px solid ${themeV2.mixins.v2.color.font.lightGray};
`;
