import {
  BackButtonV2,
  ButtonV2,
  Component,
  ImageUploadV2,
  InformationPanel,
  InputDateWithLabelV2,
  InputWithLabelV2,
  PageHeaderV2,
  ScreenLoaderV2,
  TabProperty,
  TabsV3,
  themeV2,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  QUESTIONNAIRE_ID,
  Questionnaire,
  QuestionnaireId,
  QuestionnaireItem,
  QuestionnaireItemOption,
  QuestionnaireItemType,
  QuestionnaireStatus,
  SAVE_QUESTIONNAIRE_FOR_ADMIN,
  SaveQuestionnaireForAdminRequest,
  SaveQuestionnaireForAdminResponse,
  User
} from '@atomica.co/irori';
import { Count, Description, Index, Label, Message, Name, Order, URL } from '@atomica.co/types';
import {
  EMPTY,
  NOT_FOUND_INDEX,
  builder,
  embedIdInPath,
  hasLength,
  isEmpty,
  isNull,
  isZero,
  toEndOfDay,
  uuid
} from '@atomica.co/utils';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import { isAfter, isBefore } from 'date-fns';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import DefaultModal from '../../components/modal/DefaultModal';
import { EditorTabEnum, ImageCategory } from '../../enums/common-enum';
import { Labels } from '../../models/common-model';
import { useSnackbarV2 } from '../../provider/SnackbarProviderV2';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import useQuestionnaire from '../../redux/hooks/useQuestionnaire';
import { PATH_IDS, Path } from '../../router/Routes';
import { ImageService } from '../../services/image-service';
import { EDITER_TYPE_LABELS } from '../../texts/common-text';
import { isNotFoundIndex, partialBuilder } from '../../utils/common-util';
import { getQuestionnaireStatusLabel } from '../../utils/questionnaire-util';
import QuestionCard from './card/QuestionCard';
import QuestionnaireForm from './form/QuestionnaireForm';

type ModalType = 'delete' | 'release' | 'private';

const MODAL_LABEL: Labels = {
  delete: '削除',
  release: '公開',
  private: '非公開に戻す'
};

interface P {
  base: BaseDto;
  user: User;
}

const tabProperties: TabProperty[] = [
  {
    icon: <EditOutlinedIcon />,
    label: EDITER_TYPE_LABELS[EditorTabEnum.EDIT],
    disabled: false
  },
  {
    icon: <VisibilityRoundedIcon />,
    label: EDITER_TYPE_LABELS[EditorTabEnum.PREVIEW],
    disabled: false
  }
];

const getItem = (order: Order): QuestionnaireItem => {
  return builder<QuestionnaireItem>()
    .questionnaireItemId(uuid())
    .name(EMPTY)
    .order(order)
    .type(QuestionnaireItemType.RADIO)
    .isAnswerRequired(false)
    .QuestionnaireItemOptions([
      builder<QuestionnaireItemOption>()
        .questionnaireItemOptionId(uuid())
        .name('選択肢1')
        .order(0)
        .isOtherItemOption(false)
        .build()
    ])
    .build();
};

const orderItems = (items: QuestionnaireItem[]): QuestionnaireItem[] => {
  return items.map((item, order) => partialBuilder(item, { order }));
};

const RegisterQuestionnaireScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;

  const { path, params, openPath, openBasePath } = usePath();
  const {
    isLoading,
    cachedAnswer,
    saveCachedAnswer,
    clearCachedAnswer,
    validateAnswer,
    fetchQuestionnaireForAdmin,
    deleteQuestionnaire
  } = useQuestionnaire();
  const { openSnackbar } = useSnackbarV2();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [selectedTabIdx, setSelectedTabIdx] = useSafeState<Index>(unmountRef, EditorTabEnum.EDIT);
  const [name, setName] = useSafeState<Name>(unmountRef, EMPTY);
  const [description, setDescription] = useSafeState<Description>(unmountRef, EMPTY);
  const [thumbnail, setThumbnail] = useSafeState<File>(unmountRef);
  const [answerDueAt, setAnswerDueAt] = useSafeState<Date | null>(unmountRef, null);
  const [prevQuestionnaire, setPrevQuestionnaire] = useSafeState<Questionnaire>(unmountRef, new Questionnaire());
  const [isValidAnswerDueAt, setIsValidAnswerDueAt] = useSafeState<boolean>(unmountRef, true);

  const status = useMemo<QuestionnaireStatus>(() => prevQuestionnaire.status, [prevQuestionnaire]);

  const [items, setItems] = useSafeState<QuestionnaireItem[]>(unmountRef, [getItem(0)]);

  const isEdit = useMemo<boolean>(() => path === Path.EDIT_QUESTIONNAIRE, [path]);

  const questionnaireId = useMemo<QuestionnaireId>(() => {
    return isEdit ? params[QUESTIONNAIRE_ID] : uuid();
  }, [isEdit, params]);

  const questionnaire = useMemo<Questionnaire>(() => {
    const questionnaireToSave = builder<Questionnaire>()
      .questionnaireId(questionnaireId)
      .name(name)
      .description(description)
      .questionnaireItems(items)
      .status(status)
      .answerDueAt(isNull(answerDueAt) ? undefined : answerDueAt)
      .base(base);
    return questionnaireToSave.build();
  }, [base, description, items, name, questionnaireId, status, answerDueAt]);

  const [selectedCardIdx, setSelectedCardIdx] = useSafeState<Index>(unmountRef, NOT_FOUND_INDEX);
  const [selectedQuestionIdx, setSelectedQuestionIdx] = useSafeState<Index>(unmountRef, NOT_FOUND_INDEX);

  const [isSaving, setIsSaving] = useSafeState<boolean>(unmountRef, false);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [modalType, setmodalType] = useSafeState<ModalType>(unmountRef, 'delete');

  const isReleased = useMemo<boolean>(() => status === QuestionnaireStatus.RELEASED, [status]);

  const isExpired = useMemo<boolean>(() => {
    if (prevQuestionnaire.answerDueAt) return isAfter(new Date(), prevQuestionnaire.answerDueAt);
    return false;
  }, [prevQuestionnaire]);

  const isEditOnly = useMemo<boolean>(
    () => status === QuestionnaireStatus.PRIVATE || status === QuestionnaireStatus.RELEASED,
    [status]
  );

  const secondaryButtonLabel = useMemo<Label>(() => {
    switch (status) {
      case QuestionnaireStatus.PRIVATE:
        return '保存';
      case QuestionnaireStatus.RELEASED:
        return '非公開に変更して保存';
      case QuestionnaireStatus.DRAFT:
      default:
        return '下書き保存';
    }
  }, [status]);

  const isButtonDisabled = useMemo<boolean>(() => {
    return (
      isEmpty(name) ||
      !hasLength(items) ||
      items.some(item => isEmpty(item.name)) ||
      items.some(item => item.QuestionnaireItemOptions?.some(option => isEmpty(option.name)))
    );
  }, [items, name]);

  const modalMessage = useMemo<JSX.Element>(() => {
    switch (modalType) {
      case 'delete':
        return (
          <>
            <div>アンケートを削除します。この操作は取り消すことができません。</div>
            <br />
            <div> 削除してよろしいですか？</div>
          </>
        );
      case 'release':
        return (
          <>
            <div>一度公開すると、質問の追加や削除ができなくなります。 </div>
            <div>※ 登録済みの質問・選択肢の文言を変更することは可能です。</div>
            <br />
            <div>公開してよろしいですか？</div>
          </>
        );
      case 'private':
        return (
          <>
            <div>
              アンケートを非公開に戻すと、ユーザーはアンケートに回答できなくなります。また、すでにユーザーからの回答がある場合、非公開にしても以下の変更はできません。
            </div>
            <div>・質問項目の追加・削除</div>
            <div>・回答形式の変更</div>
            <br />
            <div>アンケートを非公開に戻しますか？</div>
          </>
        );
    }
  }, [modalType]);

  const messageForInformationPanel = useMemo<Message>(() => {
    switch (prevQuestionnaire.status) {
      case QuestionnaireStatus.RELEASED:
        return isExpired ? '公開終了しているため' : 'すでに公開済みのため';
      case QuestionnaireStatus.PRIVATE:
        return '過去に公開されたため';
      case QuestionnaireStatus.DRAFT:
      default:
        return EMPTY;
    }
  }, [isExpired, prevQuestionnaire]);

  const onChangeFile = useSafeCallback(
    async (file: File): Promise<boolean> => {
      setThumbnail(file);
      return true;
    },
    [setThumbnail]
  );

  const getQuestionnaireThumbnail = useSafeCallback(
    async (thumbnailURL: URL): Promise<void> => {
      const downloadFile = await ImageService.urlToFile(thumbnailURL);
      downloadFile && (await onChangeFile(downloadFile));
    },
    [onChangeFile]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    const questionnaire = await fetchQuestionnaireForAdmin(questionnaireId, base.baseId);
    if (!questionnaire) return;
    const { name, description, thumbnailURL, answerDueAt, questionnaireItems } = questionnaire;
    setName(name);
    if (description) setDescription(description);
    if (answerDueAt) setAnswerDueAt(answerDueAt);
    setItems(
      questionnaireItems?.map(questionnaireItem => {
        delete questionnaireItem.QuestionnaireAnswerDetails;
        return partialBuilder(questionnaireItem, {
          questionnaire,
          QuestionnaireItemOptions: questionnaireItem.QuestionnaireItemOptions?.map(option => {
            delete option.QuestionnaireAnswerDetails;
            return partialBuilder(option, { questionnaireItem });
          })
        });
      }) || []
    );
    setPrevQuestionnaire(questionnaire);
    saveCachedAnswer(partialBuilder(cachedAnswer, { questionnaire }));
    if (thumbnailURL) getQuestionnaireThumbnail(thumbnailURL);
  }, [
    base,
    cachedAnswer,
    fetchQuestionnaireForAdmin,
    getQuestionnaireThumbnail,
    questionnaireId,
    saveCachedAnswer,
    setAnswerDueAt,
    setDescription,
    setItems,
    setName,
    setPrevQuestionnaire
  ]);

  const updateQuestionnaire = useSafeCallback((): void => {
    saveCachedAnswer(partialBuilder(cachedAnswer, { questionnaire }));
  }, [cachedAnswer, questionnaire, saveCachedAnswer]);

  const handleItemChanged = useSafeCallback(
    (index: Index, deleteCount: Count, item: QuestionnaireItem): void => {
      setItems(prevItems => {
        prevItems.splice(index, deleteCount, item);
        return orderItems(prevItems);
      });
      setSelectedCardIdx(index);
      if (isZero(deleteCount)) setSelectedQuestionIdx(index);
      updateQuestionnaire();
    },
    [setItems, setSelectedCardIdx, setSelectedQuestionIdx, updateQuestionnaire]
  );

  const handleDeleteButtonClicked = useSafeCallback(
    (targetIndex: Index): void => {
      setItems(prevItems => {
        return orderItems(prevItems.filter((_, index) => index !== targetIndex));
      });
      setSelectedCardIdx(NOT_FOUND_INDEX);
      updateQuestionnaire();
    },
    [setItems, setSelectedCardIdx, updateQuestionnaire]
  );

  const saveQuestionnaire = useSafeCallback(
    async (status: QuestionnaireStatus): Promise<void> => {
      setIsSaving(true);

      const thumbnailURL = await ImageService.uploadImageToFirebase(
        thumbnail,
        ImageCategory.QUESTIONNAIRE,
        questionnaireId
      );
      const releasedAt =
        prevQuestionnaire.releasedAt || (status === QuestionnaireStatus.RELEASED ? new Date() : undefined);
      const createdUser = prevQuestionnaire.createdUser || user;
      const releasedUser =
        prevQuestionnaire.releasedUser || (status === QuestionnaireStatus.RELEASED ? user : undefined);
      const questionnaireToSave = partialBuilder(questionnaire, {
        thumbnailURL,
        status,
        releasedAt,
        createdUser,
        updatedUser: user,
        releasedUser
      });

      const request = builder<SaveQuestionnaireForAdminRequest>()
        .baseId(base.baseId)
        .questionnaire(questionnaireToSave)
        .build();
      const respose = await commonRequest<SaveQuestionnaireForAdminRequest, SaveQuestionnaireForAdminResponse>(
        SAVE_QUESTIONNAIRE_FOR_ADMIN,
        request
      );

      if (!respose) {
        setIsSaving(false);
        return;
      }

      switch (status) {
        case QuestionnaireStatus.DRAFT:
          openPath(embedIdInPath(Path.EDIT_QUESTIONNAIRE, PATH_IDS, [base.baseCode, questionnaire.questionnaireId]));
          openSnackbar('下書き保存をしました。', 'success', 3000);
          break;
        case QuestionnaireStatus.PRIVATE:
          openPath(embedIdInPath(Path.QUESTIONNAIRE_DETAILS, PATH_IDS, [base.baseCode, questionnaire.questionnaireId]));
          break;
        case QuestionnaireStatus.RELEASED:
          openPath(embedIdInPath(Path.QUESTIONNAIRE_DETAILS, PATH_IDS, [base.baseCode, questionnaire.questionnaireId]));
          openSnackbar('アンケートを公開しました。', 'success', 3000);
          break;
      }

      setIsSaving(false);
    },
    [base, commonRequest, openPath, openSnackbar, questionnaire, setIsSaving, thumbnail, questionnaireId, user]
  );

  const openQuestionnaireListScreen = useSafeCallback((): void => {
    openBasePath(Path.QUESTIONNAIRE_LIST);
  }, [openBasePath]);

  const validateDate = useSafeCallback((): boolean => {
    if (isNull(answerDueAt)) {
      return true;
    }
    if (!isBefore(new Date(), answerDueAt)) {
      setIsValidAnswerDueAt(false);
      openSnackbar('回答期限が過去の日時に指定されています', 'error', 3000);
      return false;
    }
    return true;
  }, [answerDueAt, setIsValidAnswerDueAt, openSnackbar]);

  const handleModalButtonClicked = useSafeCallback(async (): Promise<void> => {
    switch (modalType) {
      case 'delete':
        await deleteQuestionnaire(questionnaireId, base.baseId);
        openQuestionnaireListScreen();
        break;
      case 'release':
        await saveQuestionnaire(QuestionnaireStatus.RELEASED);
        break;
      case 'private':
        await saveQuestionnaire(QuestionnaireStatus.PRIVATE);
    }
  }, [base, deleteQuestionnaire, modalType, openQuestionnaireListScreen, questionnaireId, saveQuestionnaire]);

  const handleModalOpened = useSafeCallback(
    (type: ModalType): void => {
      setIsModalOpen(true);
      setmodalType(type);
    },
    [setIsModalOpen, setmodalType]
  );

  const handleSecondaryButtonClicked = useSafeCallback(async (): Promise<void> => {
    if (!status) {
      await saveQuestionnaire(QuestionnaireStatus.DRAFT);
      return;
    }
    switch (status) {
      case QuestionnaireStatus.RELEASED:
        handleModalOpened('private');
        break;
      default:
        await saveQuestionnaire(status);
    }
  }, [handleModalOpened, saveQuestionnaire, status]);

  const handlePrimaryButtonClicked = useSafeCallback(async (): Promise<void> => {
    if (!validateDate()) return;
    switch (status) {
      case QuestionnaireStatus.RELEASED:
        await saveQuestionnaire(status);
        break;
      case QuestionnaireStatus.PRIVATE:
      case QuestionnaireStatus.DRAFT:
      default:
        handleModalOpened('release');
    }
  }, [validateDate, handleModalOpened, saveQuestionnaire, status]);

  useEffect(() => {
    if (!isNotFoundIndex(selectedQuestionIdx)) setSelectedQuestionIdx(NOT_FOUND_INDEX);
  }, [selectedQuestionIdx, setSelectedQuestionIdx]);

  useEffect(() => {
    clearCachedAnswer();
  }, [clearCachedAnswer, selectedTabIdx]);

  useEffect(() => {
    setIsValidAnswerDueAt(true);
  }, [answerDueAt, setIsValidAnswerDueAt]);

  const isInitialized = useRef<boolean>(false);

  useEffect(() => {
    if (!isEdit || isInitialized.current) return;
    initialize();
    isInitialized.current = true;
  }, [isEdit, initialize]);

  return (
    <Component loading={isLoading} style={{ width: '100%', height: '100%' }} className='register-questionnaire-screen'>
      <Container>
        <Contents>
          <BackButtonV2 label='戻る' onClick={openQuestionnaireListScreen} />
          <PageHeaderV2 title={isEdit ? questionnaire.name : 'アンケートの新規作成'} />
          <TabsV3
            width={128}
            tabs={tabProperties}
            variant='outline'
            selectedTabIdx={selectedTabIdx}
            onChange={setSelectedTabIdx}
          />

          {status && (
            <InformationPanel title={`このアンケートは${getQuestionnaireStatusLabel(prevQuestionnaire)}です。`}>
              {status !== QuestionnaireStatus.DRAFT && (
                <div>
                  <MessageText>このアンケートは{messageForInformationPanel}、以下の変更はできません。</MessageText>
                  <MessageText>・質問項目の追加・削除</MessageText>
                  <MessageText>・回答形式の変更</MessageText>
                </div>
              )}
            </InformationPanel>
          )}

          {selectedTabIdx === EditorTabEnum.EDIT && (
            <>
              <Content>
                <Title>基本情報</Title>
                <InputWithLabelV2 required text='アンケート名' value={name} onChange={setName} />
                <InputWithLabelV2
                  multiline
                  text='アンケートの説明'
                  remarks='この説明は、アンケート回答者にも表示されます。'
                  value={description}
                  onChange={setDescription}
                />
                <ImageUploadV2
                  header='カバー写真'
                  remarks='PCでは幅640px×高さ200px、スマートフォンでは幅350px×高さ200pxを目安にトリミングされます。ここではスマホサイズで表示を行っております。'
                  height={200}
                  file={thumbnail}
                  onChange={onChangeFile}
                />
                <DateWrapper>
                  <InputDateWithLabelV2
                    clearable
                    text='回答期限'
                    value={answerDueAt}
                    onChange={date => {
                      const endOfDay = toEndOfDay(date);
                      setAnswerDueAt(endOfDay ? endOfDay : null);
                    }}
                    placeholder='YYYY/MM/DD'
                    error={!isValidAnswerDueAt}
                  />
                </DateWrapper>
              </Content>
              <Content>
                <Title>質問</Title>
                {items &&
                  items.map((item, index) => (
                    <QuestionCard
                      key={`card-${index}`}
                      isEditOnly={isEditOnly}
                      isSelected={index === selectedCardIdx}
                      isQuestionFocused={index === selectedQuestionIdx}
                      item={item}
                      onChange={item => handleItemChanged(index, 1, item)}
                      onCopy={item => handleItemChanged(index + 1, 0, item)}
                      onDelete={() => handleDeleteButtonClicked(index)}
                      onFocus={() => setSelectedCardIdx(index)}
                    />
                  ))}
                {!isEditOnly && (
                  <ButtonV2
                    startIcon={<AddRoundedIcon />}
                    label='質問を追加'
                    onClick={() => handleItemChanged(items.length, 0, getItem(items.length))}
                  />
                )}
              </Content>
            </>
          )}
          {selectedTabIdx === EditorTabEnum.PREVIEW && (
            <QuestionnaireForm questionnaire={questionnaire} thumbnail={thumbnail} />
          )}
        </Contents>
        <Footer>
          {selectedTabIdx === EditorTabEnum.EDIT && (
            <>
              <ButtonWrapper>
                <ButtonV2
                  size='large'
                  type='secondary'
                  label={secondaryButtonLabel}
                  onClick={handleSecondaryButtonClicked}
                />
                <ButtonV2
                  width={160}
                  size='large'
                  disabled={isButtonDisabled}
                  type='primary'
                  label='公開'
                  onClick={handlePrimaryButtonClicked}
                />
              </ButtonWrapper>
              {isEdit && !isReleased && (
                <DeleteButton size='large' label='削除' onClick={() => handleModalOpened('delete')} />
              )}
              {(!status || isReleased) && (
                <ButtonV2 width={160} size='large' label='キャンセル' onClick={openQuestionnaireListScreen} />
              )}
            </>
          )}
          {selectedTabIdx === EditorTabEnum.PREVIEW && (
            <FooterContent>
              <MessageText>この画面はプレビューです。「送信」ボタンを押下しても回答は送信されません。</MessageText>
              <ButtonWrapperForPreview>
                <StyledButtonForPreview type='primary' label='送信' onClick={validateAnswer} />
                <StyledButtonForPreview label='フォームをクリア' onClick={clearCachedAnswer} />
              </ButtonWrapperForPreview>
            </FooterContent>
          )}
        </Footer>
        <DefaultModal
          width={409}
          isOpen={isModalOpen}
          headerLabel={'アンケートを' + MODAL_LABEL[modalType]}
          leftButtonProps={{ disabled: isLoading }}
          rightButtonProps={{ disabled: isLoading, label: MODAL_LABEL[modalType], onClick: handleModalButtonClicked }}
          onClose={() => setIsModalOpen(false)}
        >
          {modalMessage}
        </DefaultModal>
        <ScreenLoaderV2 loading={isSaving || isLoading} />
      </Container>
    </Component>
  );
});

RegisterQuestionnaireScreen.displayName = 'RegisterQuestionnaireScreen';
export default RegisterQuestionnaireScreen;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Contents = styled.div`
  width: 100%;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 4}px;
  margin: 0 auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  border-radius: 8px;
  background: ${themeV2.mixins.v2.color.background.offWhite};
`;

const DateWrapper = styled.div`
  width: 30%;
`;

const Title = styled.div`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-top: auto;
  background: ${themeV2.mixins.v2.color.background.white};
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ButtonWrapperForPreview = styled(ButtonWrapper)`
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const DeleteButton = styled(ButtonV2)`
  && {
    color: ${themeV3.mixins.v3.color.object.error};
  }
`;

const MessageText = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const StyledButtonForPreview = styled(ButtonV2).attrs(() => ({ size: 'large', width: 200 }))``;
