import {
  ButtonV2,
  Component,
  MOBILE_MAX_WIDTH,
  MOBILE_MIN_WIDTH,
  Scrollable,
  StepNavigationV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AUTHORITY_CODE,
  BaseDto,
  CREATE_WORKHUB_ACCOUNT,
  CreateWorkhubAccountRequest,
  CreateWorkhubAccountResponse,
  END_DATE,
  FETCH_USER_DIVS,
  FETCH_USER_INFLOW_SOURCES,
  FetchUserDivsRequest,
  FetchUserDivsResponse,
  FetchUserInflowSourcesRequest,
  FetchUserInflowSourcesResponse,
  SAVE_FIREBASE,
  START_DATE,
  SaveFirebaseRequest,
  SaveFirebaseResponse,
  User,
  UserDiv,
  UserInflowSource
} from '@atomica.co/irori';
import { Email, Id, Index, Label, Name, Password, Phone } from '@atomica.co/types';
import { EMAIL, EMPTY, Prefecture, builder, isEmpty, isNull, noop, uuid } from '@atomica.co/utils';
import React, { CSSProperties, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { toUserToSave } from '../../converters/user-converter';
import { RegisterAccountStepEnum } from '../../enums/account-enum';
import useCachedEmail from '../../redux/hooks/useCachedEmail';
import useCachedInitialAuthorityCode from '../../redux/hooks/useCachedInitialAuthorityCode';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import CommonRequest from '../../requests/common-request';
import { Path } from '../../router/Routes';
import { AuthService } from '../../services/auth-service';
import { REGISTER_ACCOUNT_STEP_LABELS } from '../../texts/account-text';
import RegisterAccountAuthorization from './register-account/RegisterAccountAuthorization';
import RegisterAccountCompletion from './register-account/RegisterAccountCompletion';
import RegisterAccountInput, {
  AccountInputOnChanges,
  AccountInputValues,
  IsValidAccountInputValues
} from './register-account/RegisterAccountInput';
import RegisterAccountSummary from './register-account/RegisterAccountSummary';

const REGEX_FOR_PASSWORD = /[!-~]{8,}/;

interface P {
  base: BaseDto;
  user: User | undefined;
}

const RegisterAccountScreenV2: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { isLineUser, saveActivatedUser } = useUser();
  const { queryParams, openBasePath } = usePath();
  const { cachedEmail, clearCachedEmail } = useCachedEmail();
  const { cachedInitialAuthorityCode, clearCachedInitialAuthorityCode } = useCachedInitialAuthorityCode();

  const initialEmail = useMemo<Email>(() => {
    return queryParams[EMAIL] || cachedEmail || EMPTY;
  }, [cachedEmail, queryParams]);

  const unmountRef = useUnmountRef();
  const [currentStepIndex, setCurrentStepIndex] = useSafeState<Index>(unmountRef, RegisterAccountStepEnum.SUMMARY);
  const [familyName, setFamilyName] = useSafeState<Name>(unmountRef, EMPTY);
  const [firstName, setFirstName] = useSafeState<Name>(unmountRef, EMPTY);
  const [email, setEmail] = useSafeState<Email>(unmountRef, initialEmail);
  const [dateOfBirth, setDateOfBirth] = useSafeState<Date>(unmountRef, new Date('2000/01/01'));
  const [phone, setPhone] = useSafeState<Phone>(unmountRef, EMPTY);
  const [password, setPassword] = useSafeState<Password>(unmountRef, EMPTY);
  const [prefecture, setPrefecture] = useSafeState<Prefecture>(unmountRef);

  const [userDivs, setUserDivs] = useSafeState<UserDiv[]>(unmountRef, []);
  const [userInflowSources, setUserInflowSources] = useSafeState<UserInflowSource[]>(unmountRef, []);

  const [userDiv, setUserDiv] = useSafeState<UserDiv>(unmountRef);
  const [userInflowSource, setUserInflowSource] = useSafeState<UserInflowSource>(unmountRef);
  const [isTermsOfUseForKnotPLACEAgreed, setIsTermsOfUseForKnotPLACEAgreed] = useSafeState<boolean>(unmountRef, false);
  const [isTermsOfUseForWorkhubAgreed, setIsTermsOfUseForWorkhubAgreed] = useSafeState<boolean>(unmountRef, false);

  const [isValidFamilyName, setIsValidFamilyName] = useSafeState<boolean>(unmountRef, false);
  const [isValidFirstName, setIsValidFirstName] = useSafeState<boolean>(unmountRef, false);
  const [isValidEmail, setIsValidEmail] = useSafeState<boolean>(unmountRef, false);
  const [isValidDateOfBirth, setIsValidDateOfBirth] = useSafeState<boolean>(unmountRef, false);
  const [isValidPhone, setIsValidPhone] = useSafeState<boolean>(unmountRef, false);
  const [isFilledPassword, setIsFilledPassword] = useSafeState<boolean>(unmountRef, false);
  const [isRegexValidPassword, setIsRegexValidPassword] = useSafeState<boolean>(unmountRef, false);
  const [isTermsOfUseChecked, setIsTermsOfUseChecked] = useSafeState<boolean>(unmountRef, false);

  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);

  const [signupSessionId, setSignupSessionId] = useSafeState<Id>(unmountRef, EMPTY);

  const topRef = useRef<HTMLDivElement>(null);

  const startDate = useMemo<Date>(() => {
    const startDate = queryParams[START_DATE];
    return startDate ? new Date(startDate) : new Date();
  }, [queryParams]);

  const endDate = useMemo<Date | undefined>(() => {
    const endDate = queryParams[END_DATE];
    return endDate ? new Date(endDate) : undefined;
  }, [queryParams]);

  const values = useMemo<AccountInputValues>(
    () =>
      builder<AccountInputValues>()
        .familyName(familyName)
        .firstName(firstName)
        .email(email)
        .dateOfBirth(dateOfBirth)
        .phone(phone)
        .password(password)
        .prefecture(prefecture)
        .userDiv(userDiv)
        .userInflowSource(userInflowSource)
        .isTermsOfUseForKnotPLACEAgreed(isTermsOfUseForKnotPLACEAgreed)
        .isTermsOfUseForWorkhubAgreed(isTermsOfUseForWorkhubAgreed)
        .build(),
    [
      dateOfBirth,
      email,
      familyName,
      firstName,
      isTermsOfUseForKnotPLACEAgreed,
      isTermsOfUseForWorkhubAgreed,
      password,
      phone,
      prefecture,
      userDiv,
      userInflowSource
    ]
  );

  const onChanges = useMemo<AccountInputOnChanges>(
    () =>
      builder<AccountInputOnChanges>()
        .setFamilyName(setFamilyName)
        .setFirstName(setFirstName)
        .setEmail(setEmail)
        .setDateOfBirth(setDateOfBirth)
        .setPhone(setPhone)
        .setPassword(setPassword)
        .setPrefecture(setPrefecture)
        .setUserDiv(setUserDiv)
        .setUserInflowSource(setUserInflowSource)
        .setIsTermsOfUseForKnotPLACEAgreed(setIsTermsOfUseForKnotPLACEAgreed)
        .setIsTermsOfUseForWorkhubAgreed(setIsTermsOfUseForWorkhubAgreed)
        .build(),
    [
      setDateOfBirth,
      setEmail,
      setFamilyName,
      setFirstName,
      setIsTermsOfUseForKnotPLACEAgreed,
      setIsTermsOfUseForWorkhubAgreed,
      setPassword,
      setPhone,
      setPrefecture,
      setUserDiv,
      setUserInflowSource
    ]
  );

  const isValidValues = useMemo<IsValidAccountInputValues>(
    () =>
      builder<IsValidAccountInputValues>()
        .isValidFamilyName(isValidFamilyName)
        .isValidFirstName(isValidFirstName)
        .isValidEmail(isValidEmail)
        .isValidDateOfBirth(isValidDateOfBirth)
        .isValidPhone(isValidPhone)
        .isFilledPassword(isFilledPassword)
        .isRegexValidPassword(isRegexValidPassword)
        .isTermsOfUseChecked(isTermsOfUseChecked)
        .build(),
    [
      isValidDateOfBirth,
      isValidEmail,
      isValidFamilyName,
      isValidFirstName,
      isFilledPassword,
      isRegexValidPassword,
      isValidPhone,
      isTermsOfUseChecked
    ]
  );

  const primaryButtonLabel = useMemo<Label>(() => {
    switch (currentStepIndex) {
      case RegisterAccountStepEnum.SUMMARY:
        return 'アカウント情報の入力へ';
      case RegisterAccountStepEnum.INPUT:
        return 'メールアドレスの認証へ';
      default:
        return EMPTY;
    }
  }, [currentStepIndex]);

  const cancelButtonLabel = useMemo<Label>(() => {
    switch (currentStepIndex) {
      case RegisterAccountStepEnum.SUMMARY:
        return 'キャンセル';
      case RegisterAccountStepEnum.INPUT:
        return '概要に戻る';
      default:
        return EMPTY;
    }
  }, [currentStepIndex]);

  const validate = useSafeCallback((): boolean => {
    let isInvalid = false;
    if (isEmpty(familyName)) {
      setIsValidFamilyName(false);
      isInvalid = true;
    }
    if (isEmpty(firstName)) {
      setIsValidFirstName(false);
      isInvalid = true;
    }
    if (isEmpty(email)) {
      setIsValidEmail(false);
      isInvalid = true;
    }
    if (isNull(dateOfBirth)) {
      setIsValidDateOfBirth(false);
      isInvalid = true;
    }
    if (isEmpty(phone)) {
      setIsValidPhone(false);
      isInvalid = true;
    }
    if (isEmpty(password)) {
      setIsFilledPassword(false);
      isInvalid = true;
    }
    if (!REGEX_FOR_PASSWORD.test(password)) {
      setIsRegexValidPassword(false);
      isInvalid = false;
    }
    if (!isTermsOfUseForKnotPLACEAgreed || !isTermsOfUseForWorkhubAgreed) {
      setIsTermsOfUseChecked(false);
      isInvalid = true;
    }
    return !isInvalid;
  }, [
    dateOfBirth,
    email,
    familyName,
    firstName,
    isTermsOfUseForKnotPLACEAgreed,
    isTermsOfUseForWorkhubAgreed,
    password,
    phone,
    setIsTermsOfUseChecked,
    setIsValidDateOfBirth,
    setIsValidEmail,
    setIsValidFamilyName,
    setIsValidFirstName,
    setIsFilledPassword,
    setIsRegexValidPassword,
    setIsValidPhone
  ]);

  useEffect(() => setIsValidDateOfBirth(true), [dateOfBirth, setIsValidDateOfBirth]);
  useEffect(() => setIsValidEmail(true), [email, setIsValidEmail]);
  useEffect(() => setIsValidFamilyName(true), [familyName, setIsValidFamilyName]);
  useEffect(() => setIsValidFirstName(true), [firstName, setIsValidFirstName]);
  useEffect(() => setIsFilledPassword(true), [password, setIsFilledPassword]);
  useEffect(() => setIsRegexValidPassword(true), [password, setIsRegexValidPassword]);
  useEffect(() => setIsValidPhone(true), [phone, setIsValidPhone]);
  useEffect(
    () => setIsTermsOfUseChecked(true),
    [isTermsOfUseForKnotPLACEAgreed, isTermsOfUseForWorkhubAgreed, setIsTermsOfUseChecked]
  );

  const saveNewUser = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);

    const authorityCode = queryParams[AUTHORITY_CODE] || cachedInitialAuthorityCode;

    if (email && !isLineUser) {
      const request = builder<SaveFirebaseRequest>().email(email).password(password).build();
      const response = await CommonRequest.call<SaveFirebaseRequest, SaveFirebaseResponse>(SAVE_FIREBASE, request);
      const customToken = response.customToken;
      const { user } = await AuthService.signInWithCustomToken(customToken!);
      if (user) await user.updateEmail(email).catch(noop);
    }

    const userToSave = toUserToSave(
      user,
      startDate,
      endDate,
      familyName,
      firstName,
      EMPTY,
      dateOfBirth,
      phone,
      email,
      EMPTY,
      prefecture,
      EMPTY,
      userDiv,
      userInflowSource,
      EMPTY,
      EMPTY
    );

    await saveActivatedUser(base, userToSave, authorityCode, base.baseId);

    setIsLoaderShown(false);
    clearCachedInitialAuthorityCode();
    clearCachedEmail();
  }, [
    base,
    cachedInitialAuthorityCode,
    clearCachedEmail,
    clearCachedInitialAuthorityCode,
    dateOfBirth,
    email,
    endDate,
    familyName,
    firstName,
    isLineUser,
    password,
    phone,
    prefecture,
    queryParams,
    saveActivatedUser,
    setIsLoaderShown,
    startDate,
    user,
    userDiv,
    userInflowSource
  ]);

  const createWorkhubAccount = useSafeCallback(async (): Promise<CreateWorkhubAccountResponse> => {
    setIsLoaderShown(true);
    const request = builder<CreateWorkhubAccountRequest>()
      .base(base)
      .code(uuid())
      .familyNameJp(familyName)
      .firstNameJp(firstName)
      .email(email)
      .phoneNumber(phone)
      .build();
    const response = await CommonRequest.call<CreateWorkhubAccountRequest, CreateWorkhubAccountResponse>(
      CREATE_WORKHUB_ACCOUNT,
      request
    );
    setIsLoaderShown(false);
    if (response) setSignupSessionId(response.signupSessionId);
    return response;
  }, [base, email, familyName, firstName, phone, setIsLoaderShown, setSignupSessionId]);

  const openSignUpScreen = useSafeCallback((): void => {
    openBasePath(Path.SIGN_UP);
  }, [openBasePath]);

  const handleAuthorizationStepChanged = useSafeCallback(
    async (index: Index): Promise<void> => {
      setCurrentStepIndex(index);
      await saveNewUser();
    },
    [saveNewUser, setCurrentStepIndex]
  );

  const handlePrimaryButtonClicked = useSafeCallback(async (): Promise<void> => {
    switch (currentStepIndex) {
      case RegisterAccountStepEnum.SUMMARY:
        setCurrentStepIndex(RegisterAccountStepEnum.INPUT);
        break;
      case RegisterAccountStepEnum.INPUT: {
        if (!validate()) {
          topRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        }
        const response = await createWorkhubAccount();
        if (response.alreadyExistAccount) {
          setCurrentStepIndex(RegisterAccountStepEnum.COMPLETION);
          await saveNewUser();
        } else {
          setCurrentStepIndex(RegisterAccountStepEnum.AUTHORIZATION);
        }
        break;
      }
      default:
        noop();
    }
  }, [createWorkhubAccount, currentStepIndex, saveNewUser, setCurrentStepIndex, validate]);

  const handleCancelButtonClicked = useSafeCallback((): void => {
    switch (currentStepIndex) {
      case RegisterAccountStepEnum.SUMMARY:
        openSignUpScreen();
        break;
      case RegisterAccountStepEnum.INPUT:
        setCurrentStepIndex(RegisterAccountStepEnum.SUMMARY);
        break;
      default:
        noop();
    }
  }, [currentStepIndex, openSignUpScreen, setCurrentStepIndex]);

  const components = useMemo<JSX.Element>(() => {
    switch (currentStepIndex) {
      case RegisterAccountStepEnum.SUMMARY:
        return <RegisterAccountSummary />;
      case RegisterAccountStepEnum.INPUT:
        return (
          <RegisterAccountInput
            base={base}
            userDivs={userDivs}
            userInflowSources={userInflowSources}
            values={values}
            onChanges={onChanges}
            isValidValues={isValidValues}
          />
        );
      case RegisterAccountStepEnum.AUTHORIZATION:
        return (
          <RegisterAccountAuthorization
            base={base}
            email={email}
            password={password}
            signupSessionId={signupSessionId}
            onChange={handleAuthorizationStepChanged}
          />
        );
      case RegisterAccountStepEnum.COMPLETION:
        return <RegisterAccountCompletion />;
      default:
        return <></>;
    }
  }, [
    base,
    currentStepIndex,
    email,
    handleAuthorizationStepChanged,
    isValidValues,
    onChanges,
    password,
    signupSessionId,
    userDivs,
    userInflowSources,
    values
  ]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const userDivRequest = builder<FetchUserDivsRequest>().baseCode(base.baseCode).build();
    const userInflowSourceRequest = builder<FetchUserInflowSourcesRequest>().baseCode(base.baseCode).build();

    const [userDivResponse, userInflowSourceResponse] = await Promise.all([
      CommonRequest.call<FetchUserDivsRequest, FetchUserDivsResponse>(FETCH_USER_DIVS, userDivRequest),
      CommonRequest.call<FetchUserInflowSourcesRequest, FetchUserInflowSourcesResponse>(
        FETCH_USER_INFLOW_SOURCES,
        userInflowSourceRequest
      )
    ]);

    const userDivs = userDivResponse.userDivs;
    const userInflowSources = userInflowSourceResponse.userInflowSources;

    setUserDivs(userDivs);
    setUserInflowSources(userInflowSources);
  }, [base, setUserDivs, setUserInflowSources]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (user?.isActivated) openBasePath(Path.ACCOUNT_HOME);
  }, [openBasePath, user]);

  return (
    <Component
      style={styleForComponent}
      loading={isLoaderShown}
      className='register-account-screen'
      title='アカウントの新規登録'
    >
      <Container>
        <Scrollable>
          <Content>
            <div ref={topRef} />
            <ComponentWrapper>
              <Title>アカウントの新規登録</Title>
              <StepNavigationV2
                texts={Object.values(REGISTER_ACCOUNT_STEP_LABELS)}
                currentStepIndex={currentStepIndex}
              />
              {components}
            </ComponentWrapper>
            {(currentStepIndex === RegisterAccountStepEnum.SUMMARY ||
              currentStepIndex === RegisterAccountStepEnum.INPUT) && (
              <Footer>
                <ButtonV2
                  isFullWidth
                  size='large'
                  type='primary'
                  label={primaryButtonLabel}
                  onClick={handlePrimaryButtonClicked}
                />
                <ButtonV2 isFullWidth size='large' label={cancelButtonLabel} onClick={handleCancelButtonClicked} />
              </Footer>
            )}
          </Content>
        </Scrollable>
      </Container>
    </Component>
  );
});

RegisterAccountScreenV2.displayName = 'RegisterAccountScreenV2';
export default RegisterAccountScreenV2;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: '100dvh',
  display: 'flex',
  justifyContent: 'center'
};

const Container = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: 100%;
  display: flex;
  margin: 0 auto;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Title = styled.div`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  margin-top: auto;
  background: ${themeV2.mixins.v2.color.background.white};
`;
