import {
  ButtonV2,
  Component,
  customMedia,
  DialogV2,
  PageHeaderV2,
  TabProperty,
  TabsV3,
  themeV2,
  useMobile,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  ContractUsageV2,
  ContractV2,
  ContractVersion,
  FETCH_CONTRACT_USAGE,
  FETCH_CONTRACT_V2_BY_USER,
  FetchContractUsageRequest,
  FetchContractUsageResponse,
  FetchContractV2ByUserRequest,
  FetchContractV2ByUserResponse,
  InterestTagPlain,
  REGISTER_PROFILE,
  RegisterProfileRequest,
  RegisterProfileResponse,
  SkillTagPlain,
  UPDATE_PROFILE,
  UpdateProfileRequest,
  UpdateProfileResponse,
  User
} from '@atomica.co/irori';
import { Description, Message } from '@atomica.co/types';
import { builder, EMPTY } from '@atomica.co/utils';
import { Divider } from '@material-ui/core';
import { MeetingRoomOutlined } from '@material-ui/icons';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { PRIVACY_POLICY_FOR_KNOT_PLACE, TERMS_OF_USE_FOR_KNOT_PLACE } from '../../../constants/account-const';
import { MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH } from '../../../constants/common-const';
import { MyPageTabEnum } from '../../../enums/account-enum';
import { useSnackbarV2 } from '../../../provider/SnackbarProviderV2';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import usePath from '../../../redux/hooks/usePath';
import useProfile from '../../../redux/hooks/useProfile';
import { screenContext } from '../../../redux/hooks/useScreenContext';
import useUserWish from '../../../redux/hooks/useUserWish';
import { Path } from '../../../router/Routes';
import { MYPAGE_TAB_LABELS } from '../../../texts/account-text';
import { EditAccountProfileForm } from '../../../validate/accountScreen/accountProfile/schema';
import { validateEditProfile } from '../../../validate/accountScreen/accountProfile/validate';
import AccountEnterExitHistoryCard from './usage-information/AccountEnterExitHistoryCard';
import AccountUsageSituationCard from './usage-information/AccountUsageSituationCard';
import AccountProfileCardEditV3 from './user-information-v3/AccountProfileCardEditV3';
import AccountProfileCardViewV3 from './user-information-v3/AccountProfileCardViewV3';
import AccountWishCardViewV3 from './user-information-v3/AccountWishCardViewV3';

interface P {
  base: BaseDto;
  user: User;
}

const TAB_PROPERTIES: TabProperty[] = [
  { label: MYPAGE_TAB_LABELS[MyPageTabEnum.OVERVIEW] },
  { label: MYPAGE_TAB_LABELS[MyPageTabEnum.USAGE_INFO] },
  { label: MYPAGE_TAB_LABELS[MyPageTabEnum.ENTER_LEAVE_HISTORY] }
];

const AccountProfileV3: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { openBasePath } = usePath();
  const { commonRequest } = useCommonRequest();
  const { profile, loadProfile } = useProfile();
  const { userWishes, loadUserWish } = useUserWish();

  const unmountRef = useUnmountRef();
  const [initialized, setInitilized] = useSafeState<boolean>(unmountRef, false);
  const [selectedTabIdx, setSelectedTabIdx] = useSafeState<MyPageTabEnum>(unmountRef, MyPageTabEnum.OVERVIEW);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isEditMode, setIsEditMode] = useSafeState<boolean>(unmountRef, false);
  const [contract, setContract] = useSafeState<ContractV2 | undefined>(unmountRef, undefined);
  const [contractUsage, setContractUsage] = useSafeState<ContractUsageV2 | undefined>(unmountRef, undefined);
  const [saveButtonDisabled] = useSafeState<boolean>(unmountRef, false);
  const [editableSelfIntroduction, setEditableSelfIntroduction] = useSafeState<Description>(unmountRef, EMPTY);
  const [editableBiography, setEditableBiography] = useSafeState<Description>(unmountRef, EMPTY);
  const [editableInterestTagNames, setEditableInterestTagNames] = useSafeState<
    Pick<InterestTagPlain, 'interestTagName'>[]
  >(unmountRef, []);
  const [editableSkillTagNames, setEditableSkillTagNames] = useSafeState<Pick<SkillTagPlain, 'name'>[]>(unmountRef, []);

  const [errorMessageForSelfIntroduction, setErrorMessageForSelfIntroduction] = useSafeState<Message>(
    unmountRef,
    EMPTY
  );
  const { setIsDrawerOpen } = useContext(screenContext);

  const { openSnackbar } = useSnackbarV2();

  const getContract = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchContractV2ByUserRequest>().baseId(base.baseId).userId(user.userId).build();
    const { contract } = await commonRequest<FetchContractV2ByUserRequest, FetchContractV2ByUserResponse>(
      FETCH_CONTRACT_V2_BY_USER,
      request
    );
    setContract(contract);
  }, [base.baseId, setContract, user.userId, commonRequest]);

  const getContractUsage = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchContractUsageRequest>()
      .baseId(base.baseId)
      .userId(user.userId)
      .version(ContractVersion.V2)
      .build();
    const response = await commonRequest<FetchContractUsageRequest, FetchContractUsageResponse>(
      FETCH_CONTRACT_USAGE,
      request
    );
    if (response.usage) {
      setContractUsage(response.usage);
    }
  }, [base, setContractUsage, user, commonRequest]);

  const openSignOut = useSafeCallback((): void => {
    openBasePath(Path.SIGN_OUT);
  }, [openBasePath]);

  const openTermOfUse = useSafeCallback((): void => {
    window.open(TERMS_OF_USE_FOR_KNOT_PLACE, '_blank');
  }, []);

  const openPrivacyPolicy = useSafeCallback((): void => {
    window.open(PRIVACY_POLICY_FOR_KNOT_PLACE, '_blank');
  }, []);

  const handleProfileEditButtonClicked = useSafeCallback((): void => {
    setEditableSelfIntroduction(profile?.profile?.selfIntroduction ?? EMPTY);
    setEditableBiography(profile?.profile?.biography ?? EMPTY);
    setEditableInterestTagNames(profile?.interestTags ?? []);
    setEditableSkillTagNames(profile?.skillTags ?? []);
    setIsDrawerOpen(false);
    setIsEditMode(true);
  }, [
    setEditableSelfIntroduction,
    profile?.profile?.selfIntroduction,
    profile?.profile?.biography,
    profile?.interestTags,
    profile?.skillTags,
    setEditableBiography,
    setEditableInterestTagNames,
    setEditableSkillTagNames,
    setIsEditMode,
    setIsDrawerOpen
  ]);
  const handleTabChanged = useSafeCallback(
    (selectedIndex): void => {
      setSelectedTabIdx(selectedIndex);
      setIsEditMode(false);
      setIsDrawerOpen(true);
    },
    [setIsEditMode, setSelectedTabIdx, setIsDrawerOpen]
  );
  const handlerCancelButtonClicked = useSafeCallback((): void => {
    setIsEditMode(false);
    setIsDrawerOpen(true);
  }, [setIsEditMode, setIsDrawerOpen]);

  const isMobile = useMobile();

  const execRegisterProfile = useSafeCallback(async (): Promise<RegisterProfileResponse> => {
    const registerProfileRequest = builder<RegisterProfileRequest>()
      .userId(user.userId)
      .selfIntroduction(editableSelfIntroduction)
      .biography(editableBiography)
      .interestTagNames(editableInterestTagNames.map(({ interestTagName }) => interestTagName))
      .skillTagNames(editableSkillTagNames.map(({ name }) => name))
      .build();
    const registerProfileResponse = await commonRequest<RegisterProfileRequest, RegisterProfileResponse>(
      REGISTER_PROFILE,
      registerProfileRequest
    );
    return registerProfileResponse;
  }, [
    commonRequest,
    editableBiography,
    editableInterestTagNames,
    editableSelfIntroduction,
    editableSkillTagNames,
    user.userId
  ]);

  const handleAccountSettingClicked = useSafeCallback((): void => {
    openBasePath(Path.ACCOUNT_SETTING);
  }, [openBasePath]);

  const execUpdateProfile = useSafeCallback(async (): Promise<UpdateProfileResponse> => {
    const updateProfileRequest = builder<UpdateProfileRequest>()
      .userId(user.userId)
      .selfIntroduction(editableSelfIntroduction)
      .biography(editableBiography)
      .interestTagNames(editableInterestTagNames.map(({ interestTagName }) => interestTagName))
      .skillTagNames(editableSkillTagNames.map(({ name }) => name))
      .build();
    const registerProfileResponse = await commonRequest<UpdateProfileRequest, UpdateProfileResponse>(
      UPDATE_PROFILE,
      updateProfileRequest
    );
    return registerProfileResponse;
  }, [
    commonRequest,
    editableBiography,
    editableInterestTagNames,
    editableSelfIntroduction,
    editableSkillTagNames,
    user.userId
  ]);

  const validateProfile = useSafeCallback((): boolean => {
    setErrorMessageForSelfIntroduction(EMPTY);
    const inputedProfile = builder<EditAccountProfileForm>().selfIntroduction(editableSelfIntroduction).build();
    const errorMessage = validateEditProfile(inputedProfile);
    setErrorMessageForSelfIntroduction(errorMessage);
    if (errorMessage) {
      openSnackbar('入力値に誤りがあります', 'warning', 4000);
    }
    return !errorMessage;
  }, [editableSelfIntroduction, openSnackbar, setErrorMessageForSelfIntroduction]);

  const saveProfile = useSafeCallback(async (): Promise<void> => {
    setSaving(true);
    const response = profile?.profile ? await execUpdateProfile() : await execRegisterProfile();

    if (!response.result) {
      setSaving(false);
      openSnackbar('プロフィールの保存に失敗しました 時間をおいてもう一度お試しください', 'error', 4000);
      return;
    }
    openSnackbar('プロフィールを保存しました！', 'success', 4000);
    setSaving(false);
  }, [execRegisterProfile, execUpdateProfile, openSnackbar, profile, setSaving]);

  const handlerSaveButtonClicked = useSafeCallback(async (): Promise<void> => {
    if (saving || !validateProfile()) return;
    await saveProfile();
    await loadProfile(user.userId, base.baseId);
    setIsDrawerOpen(true);
    setIsEditMode(false);
  }, [base.baseId, loadProfile, saveProfile, saving, setIsDrawerOpen, setIsEditMode, user.userId, validateProfile]);

  const initialLoad = useSafeCallback(async (): Promise<void> => {
    if (initialized) return;
    await Promise.all([
      getContract(),
      getContractUsage(),
      loadProfile(user.userId, base.baseId),
      loadUserWish(user.userId, base.baseId)
    ]);
    setInitilized(true);
  }, [base.baseId, getContract, getContractUsage, initialized, loadProfile, setInitilized, loadUserWish, user.userId]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  useEffect(() => {
    setErrorMessageForSelfIntroduction(EMPTY);
  }, [editableSelfIntroduction, setErrorMessageForSelfIntroduction]);

  return (
    <Component style={{ width: '100%', height: '100%' }} className='account-profile' title={'マイページ'}>
      <Container data-testid='account-profile-v3'>
        <Content isMobile={isMobile}>
          <PageHeaderV2 title={isEditMode ? 'プロフィール' : 'マイページ'} titleSize={isMobile ? 'small' : 'default'} />
          {isEditMode && (
            <AccountProfileCardEditV3
              base={base}
              user={user}
              selfIntroduction={editableSelfIntroduction}
              setSelfIntroduction={setEditableSelfIntroduction}
              biography={editableBiography}
              setBiography={setEditableBiography}
              setInterestTagNames={setEditableInterestTagNames}
              setSkillTagNames={setEditableSkillTagNames}
              skillTagNames={editableSkillTagNames}
              interestTagNames={editableInterestTagNames}
              inputErrorMessage={{ selfIntroduction: errorMessageForSelfIntroduction }}
            />
          )}
          {!isEditMode && (
            <TabsV3
              tabs={TAB_PROPERTIES}
              selectedTabIdx={selectedTabIdx}
              onChange={handleTabChanged}
              align={isMobile ? 'center' : 'left'}
            />
          )}
          {!isEditMode && selectedTabIdx === MyPageTabEnum.OVERVIEW && (
            <ProfileViewFrame>
              <AccountProfileCardViewV3
                base={base}
                profile={profile}
                onClickEditButton={handleProfileEditButtonClicked}
                initialized={initialized}
              />
              <AccountWishCardViewV3 base={base} userWishes={userWishes} initialized={initialized} userId={user.userId}/>
            </ProfileViewFrame>
          )}
          {selectedTabIdx === MyPageTabEnum.USAGE_INFO && (
            <AccountUsageSituationCard base={base} user={user} contractUsage={contractUsage} contract={contract} />
          )}
          {selectedTabIdx === MyPageTabEnum.ENTER_LEAVE_HISTORY && (
            <AccountEnterExitHistoryCard base={base} user={user} />
          )}
          {!!isEditMode && selectedTabIdx === MyPageTabEnum.OVERVIEW ? (
            <FooterEdit>
              <ButtonV2
                size='medium'
                type='primary'
                label='プロフィールを保存'
                disabled={saving || saveButtonDisabled}
                onClick={handlerSaveButtonClicked}
              />
              <ButtonV2 type='default' label='キャンセル' onClick={handlerCancelButtonClicked} />
            </FooterEdit>
          ) : (
            <Footer>
              <ButtonV2 startIcon={<GroupRoundedIcon />} label='アカウント設定' onClick={handleAccountSettingClicked} />
              <ButtonV2 startIcon={<MeetingRoomOutlined />} label='ログアウト' onClick={() => setIsModalOpen(true)} />
              <StyledDivider variant='fullWidth' flexItem />
              <TermOfService>
                <ButtonV2 label='利用規約' onClick={openTermOfUse} />
                <ButtonV2 label='プライバシーポリシー' onClick={openPrivacyPolicy} />
              </TermOfService>
            </Footer>
          )}
        </Content>

        <DialogV2
          width={280}
          isOpen={isModalOpen}
          headerLabel='ログアウトしますか？'
          buttonsOnTheRight={[
            <ButtonV2 key='cancel' label='いいえ' onClick={() => setIsModalOpen(false)} />,
            <ButtonV2 type='primary' key='sign-out' label='はい' onClick={openSignOut} />
          ]}
          onClose={() => setIsModalOpen(false)}
        />
      </Container>
    </Component>
  );
});

AccountProfileV3.displayName = 'AccountProfileV3';
export default AccountProfileV3;

const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Content = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  flex: 1;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  flex: ${({ isMobile }) => {
    if (!isMobile) return '1 0 0';
  }};
  justify-content: ${({ isMobile }) => {
    if (isMobile) return 'center';
  }};
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 2}px 0px;
  ${customMedia.greaterThan('small')`
    min-width: 640px;
  `}
`;

const TermOfService = styled.div`
  display: flex;
  padding-bottom: ${themeV2.mixins.v2.spacing * 3}px;
  gap: ${themeV2.mixins.v2.spacing * 4}px;
  justify-content: center;
  text-align: center;
`;

const StyledDivider = styled(Divider)`
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
  width: auto;
`;

const ProfileViewFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
`;

const Footer = styled.div`
  width: 100%;
  margin-top: auto;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
`;

const FooterEdit = styled(Footer)`
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  padding: ${themeV2.mixins.v2.spacing * 2}px 0px;
  background: ${themeV2.mixins.v2.color.background.white};
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;
