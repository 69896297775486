import { BaseDto } from '@atomica.co/irori';
import { Code, Label, Name, Option, Price } from '@atomica.co/types';
import { EMPTY, ZERO } from '@atomica.co/utils';
import { Labels, PriceInfo } from '../models/common-model';

/** @deprecated */
export enum BaseDef {
  ALL = 'all'
}

/** @deprecated */
export enum Base {
  ALL = 'all',
  MIYAZAKI = 'miyazaki',
  KITAKYUSHU = 'kitakyushu',
  NOBEOKA = 'nobeoka',
  KYUKODAI = 'kyukodai',
  KAGOSHIMA = 'kagoshima',
  fukuyama = 'fukuyama',
  staging = 'staging',
  fujinomiya = 'fujinomiya',
  sapporo = 'sapporo',
  hiroshima = 'hiroshima',
  gifu = 'gifu',
  takamatsu = 'takamatsu',
  zentsuji = 'zentsuji',
  motomachi = 'motomachi',
  naha = 'naha',
  MINATOMIRAI = 'minatomirai'
}

/** @deprecated */
export enum UsageType {
  COWORKING = 'coworking',
  ONE_DAY = 'one_day',
  ONE_HOUR = 'one_hour',
  TWO_HOURS = 'two_hours',
  THREE_HOURS = 'three_hours',
  CONFERENCE = 'conference',
  EVENT = 'event',
  GUEST = 'guest',
  PREVIEW = 'preview'
}

/** @deprecated */
export const USAGE_TYPE_LABELS: Labels = {
  [UsageType.COWORKING]: 'コワーキング利用',
  [UsageType.ONE_DAY]: '1DAY利用',
  [UsageType.ONE_HOUR]: '1H利用',
  [UsageType.TWO_HOURS]: '2H利用',
  [UsageType.THREE_HOURS]: '3H利用',
  [UsageType.CONFERENCE]: '会議室利用',
  [UsageType.EVENT]: 'イベント参加',
  [UsageType.GUEST]: '会員様ゲスト',
  [UsageType.PREVIEW]: '内覧'
};

/** @deprecated */
export const USABLE_HOURS_OF_PLAN_V1 = {
  '80時間': 80,
  '80H': 80,
  '160時間': 160,
  '160H': 160,
  '320時間': 320,
  '320H': 320,
  '640時間': 640,
  '2000時間': 2000
};

/** @deprecated */
enum UsageOptionMiyazakiOneDay {
  NONE = 'none',
  WOWD = 'wowd',
  TPT = 'tpt',
  LINE = 'line',
  MAIL = 'mail',
  NEW_WORK = 'new_work',
  WORKUS = 'workus',
  TIMEWORK = 'timework',
  IIOFFICE = 'iioffice'
}

/** @deprecated */
enum UsageOptionNobeokaOneDay {
  NONE = 'none',
  WITHIN_CITY = 'within_city'
}

/** @deprecated */
enum UsageOptionNobeokaThreeHours {
  NONE = 'none',
  WITHIN_CITY = 'within_city'
}

/** @deprecated */
enum UsageOptionKitakyushuOneDay {
  NONE = 'none',
  JQ_CARD = 'jq_card',
  TANGA_TABLE = 'tanga_table',
  SAINT_CITY = 'saint_city',
  NEW_WORK = 'new_work',
  OTAMESHI = 'otameshi',
  FREE = 'free'
}

/** @deprecated */
export type UsageOption =
  | UsageOptionMiyazakiOneDay
  | UsageOptionNobeokaOneDay
  | UsageOptionNobeokaThreeHours
  | UsageOptionKitakyushuOneDay;

/** @deprecated */
const UsageOptionPriceLabelMiyazakiOneDay: Labels = {
  [UsageOptionMiyazakiOneDay.NONE]: '',
  [UsageOptionMiyazakiOneDay.WOWD]: 'ワウディ会員割引(税込)',
  [UsageOptionMiyazakiOneDay.TPT]: 'TPTランチ割引(税込)',
  [UsageOptionMiyazakiOneDay.LINE]: 'LINEクーポン割引(税込)',
  [UsageOptionMiyazakiOneDay.MAIL]: 'メルマガクーポン割引(税込)',
  [UsageOptionMiyazakiOneDay.NEW_WORK]: 'NewWork割引(税込)',
  [UsageOptionMiyazakiOneDay.WORKUS]: 'Workus割引(税込)',
  [UsageOptionMiyazakiOneDay.TIMEWORK]: 'TIMEWORK割引(税込)',
  [UsageOptionMiyazakiOneDay.IIOFFICE]: 'いいオフィス割引(税込)'
};

/** @deprecated */
const UsageOptionPriceLabelMiyazaki = {
  [UsageType.ONE_DAY]: UsageOptionPriceLabelMiyazakiOneDay
};

/** @deprecated */
const UsageOptionPriceLabelNobeokaOneDay: Labels = {
  [UsageOptionNobeokaOneDay.NONE]: '',
  [UsageOptionNobeokaOneDay.WITHIN_CITY]: '市内在住割引(税込)'
};

/** @deprecated */
const UsageOptionPriceLabelNobeokaThreeHours: Labels = {
  [UsageOptionNobeokaThreeHours.NONE]: '',
  [UsageOptionNobeokaThreeHours.WITHIN_CITY]: '市内在住割引(税込)'
};

/** @deprecated */
const UsageOptionPriceLabelNobeoka = {
  [UsageType.ONE_DAY]: UsageOptionPriceLabelNobeokaOneDay,
  [UsageType.THREE_HOURS]: UsageOptionPriceLabelNobeokaThreeHours
};

/** @deprecated */
const UsageOptionPriceLabelKitakyushuOneDay: Labels = {
  [UsageOptionKitakyushuOneDay.NONE]: '',
  [UsageOptionKitakyushuOneDay.JQ_CARD]: 'JQカード会員割引(税込)',
  [UsageOptionKitakyushuOneDay.TANGA_TABLE]: 'タンガテーブル割引(税込)',
  [UsageOptionKitakyushuOneDay.SAINT_CITY]: 'セントシティ従業員割引(税込)',
  [UsageOptionKitakyushuOneDay.NEW_WORK]: 'NewWork会員割引(税込)',
  [UsageOptionKitakyushuOneDay.OTAMESHI]: 'おためしサテライト割引(税込)',
  [UsageOptionKitakyushuOneDay.FREE]: '無料クーポン割引(税込)'
};

/** @deprecated */
const UsageOptionPriceLabelKitakyushu = {
  [UsageType.ONE_DAY]: UsageOptionPriceLabelKitakyushuOneDay
};

/** @deprecated */
const UsageOptionPriceLabelKyukodai = {};

/** @deprecated */
const UsageOptionPriceLabelKagoshima = {};

/** @deprecated */
const UsageOptionPriceLabelByBase = {
  [Base.MIYAZAKI]: UsageOptionPriceLabelMiyazaki,
  [Base.NOBEOKA]: UsageOptionPriceLabelNobeoka,
  [Base.KITAKYUSHU]: UsageOptionPriceLabelKitakyushu,
  [Base.KYUKODAI]: UsageOptionPriceLabelKyukodai,
  [Base.KAGOSHIMA]: UsageOptionPriceLabelKagoshima
};

/** @deprecated */
export const toUsageOptionPriceLabels = (base: BaseDto, usageType: UsageType | undefined): Labels => {
  if (!base || !usageType) return {};
  const labelsByBase = UsageOptionPriceLabelByBase[base.baseCode];
  const labelsByUsageType = labelsByBase ? labelsByBase[usageType] : {};
  return labelsByUsageType ? labelsByUsageType : {};
};

/** @deprecated */
export const toUsageOptionPriceLabel = (
  base: BaseDto,
  usageType: UsageType | undefined,
  usageOption: UsageOption | undefined
): Label => {
  if (!base || !usageType || !usageOption) return EMPTY;
  const labelsByUsageType = toUsageOptionPriceLabels(base, usageType);
  const label = labelsByUsageType ? labelsByUsageType[usageOption] : EMPTY;
  return label ? label : EMPTY;
};

/** @deprecated */
enum ConferenceUsageTypeMiyazaki {
  ROOM_FOR_THREE = 'room_for_three',
  ROOM_FOR_SIX = 'room_for_six',
  ROOM_FOR_EIGHT = 'room_for_eight',
  ROOM_FOR_LARGE = 'room_for_large',
  ROOM_FOR_KUMANOMA = 'room_for_kumanoma',
  GUEST = 'guest'
}

/** @deprecated */
enum ConferenceUsageTypeNobeoka {
  ROOM_ONE = 'room_one',
  ROOM_TWO = 'room_two',
  ROOM_THREE = 'room_three',
  ROOM_FOUR = 'room_four',
  GUEST = 'guest'
}

/** @deprecated */
enum ConferenceUsageTypeKitakyushu {
  ROOM_FOR_FOUR = 'room_for_four',
  ROOM_FOR_EIGHT = 'room_for_eight',
  ROOM_FOR_TWELVE = 'room_for_twelve',
  GUEST = 'guest'
}

/** @deprecated */
enum ConferenceUsageTypeKyukodai {}

/** @deprecated */
enum ConferenceUsageTypeKagoshima {
  ROOM = 'room',
  GUEST = 'guest'
}

/** @deprecated */
export type ConferenceUsageType =
  | ConferenceUsageTypeMiyazaki
  | ConferenceUsageTypeNobeoka
  | ConferenceUsageTypeKitakyushu
  | ConferenceUsageTypeKyukodai
  | ConferenceUsageTypeKagoshima;

/** @deprecated */
const ConferenceUsageTypeLabelMiyazaki: Labels = {
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_THREE]: '3名部屋',
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_SIX]: '6名部屋',
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_EIGHT]: '8名部屋',
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_LARGE]: '大会議室',
  [ConferenceUsageTypeMiyazaki.ROOM_FOR_KUMANOMA]: '熊の間',
  [ConferenceUsageTypeMiyazaki.GUEST]: 'ゲスト利用'
};

/** @deprecated */
const ConferenceUsageTypeLabelNobeoka: Labels = {
  [ConferenceUsageTypeNobeoka.ROOM_ONE]: 'ミーティングルーム1',
  [ConferenceUsageTypeNobeoka.ROOM_TWO]: 'ミーティングルーム2',
  [ConferenceUsageTypeNobeoka.ROOM_THREE]: 'ミーティングルーム3',
  [ConferenceUsageTypeNobeoka.ROOM_FOUR]: 'ミーティングルーム4',
  [ConferenceUsageTypeNobeoka.GUEST]: 'ゲスト利用'
};

/** @deprecated */
const ConferenceUsageTypeLabelKitakyushu: Labels = {
  [ConferenceUsageTypeKitakyushu.ROOM_FOR_FOUR]: '4名部屋',
  [ConferenceUsageTypeKitakyushu.ROOM_FOR_EIGHT]: '8名部屋',
  [ConferenceUsageTypeKitakyushu.ROOM_FOR_TWELVE]: '12名部屋',
  [ConferenceUsageTypeKitakyushu.GUEST]: 'ゲスト利用'
};

/** @deprecated */
const ConferenceUsageTypeLabelKyukodai: Labels = {};

/** @deprecated */
const ConferenceUsageTypeLabelKagoshima: Labels = {
  [ConferenceUsageTypeKagoshima.ROOM]: '会議室',
  [ConferenceUsageTypeKagoshima.GUEST]: 'ゲスト利用'
};

/** @deprecated */
const ConferenceUsageTypeLabelByBase = {
  [Base.MIYAZAKI]: ConferenceUsageTypeLabelMiyazaki,
  [Base.NOBEOKA]: ConferenceUsageTypeLabelNobeoka,
  [Base.KITAKYUSHU]: ConferenceUsageTypeLabelKitakyushu,
  [Base.KYUKODAI]: ConferenceUsageTypeLabelKyukodai,
  [Base.KAGOSHIMA]: ConferenceUsageTypeLabelKagoshima
};

/** @deprecated */
export const toConferenceUsageTypeLabels = (base: BaseDto | undefined): Labels => {
  if (!base) return {};
  const labelsByBase = ConferenceUsageTypeLabelByBase[base.baseCode];
  return labelsByBase ? labelsByBase : {};
};

/** @deprecated */
export const toConferenceUsageTypeLabel = (
  base: BaseDto,
  conferenceUsageType: ConferenceUsageType | undefined
): Label => {
  if (!base || !conferenceUsageType) return EMPTY;
  const labelsByBase = toConferenceUsageTypeLabels(base);
  const label = labelsByBase ? labelsByBase[conferenceUsageType] : EMPTY;
  return label ? label : EMPTY;
};

/** @deprecated */
enum ContractPlanMiyazaki {
  TEN_DAYS = 'ten_days',
  EIGHTY_HOURS = 'eighty_hours',
  ONE_HUNDRED_SIXTY_HOURS = 'one_hundred_sixty_hours',
  THREE_HUNDREDS_TWENTY_HOURS = 'three_hundreds_twenty_hours',
  SIX_HUNDREDS_FOURTY_HOURS = 'six_hundreds_fourty_hours',
  ALL_DAYS = 'all_days',
  ROOM_FOR_TWO = 'room_for_two',
  ROOM_FOR_THREE = 'room_for_three',
  ROOM_FOR_FOUR = 'room_for_four',
  ROOM_FOR_SIX = 'room_for_six'
}

/** @deprecated */
enum ContractPlanNobeoka {
  MONTHLY_PERSONAL_INSIDE_CITY = 'monthly_personal_inside_city',
  MONTHLY_PERSONAL_OUTSIDE_CITY = 'monthly_personal_outside_city',
  MONTHLY_COMPANY_INSIDE_CITY = 'monthly_company_inside_city',
  MONTHLY_COMPANY_OUTSIDE_CITY = 'monthly_company_outside_city',
  YEARLY_PERSONAL_INSIDE_CITY = 'yearly_personal_inside_city',
  YEARLY_PERSONAL_OUTSIDE_CITY = 'yearly_personal_outside_city',
  YEARLY_COMPANY_INSIDE_CITY = 'yearly_company_inside_city',
  YEARLY_COMPANY_OUTSIDE_CITY = 'yearly_company_outside_city'
}

/** @deprecated */
enum ContractPlanKitakyushu {
  TEN_DAYS = 'ten_days',
  EIGHTY_HOURS = 'eighty_hours',
  ONE_HUNDRED_SIXTY_HOURS = 'one_hundred_sixty_hours',
  THREE_HUNDREDS_TWENTY_HOURS = 'three_hundreds_twenty_hours',
  TWO_THOUSAND_HOURS = 'two_thousand_hours',
  ALL_DAYS = 'all_days',
  ROOM_FOR_ONE = 'room_for_one',
  ROOM_FOR_TWO = 'room_for_two',
  ROOM_FOR_FOUR = 'room_for_four',
  ROOM_FOR_FIVE = 'room_for_five',
  ROOM_FOR_SIX = 'room_for_six',
  ROOM_FOR_NINE = 'room_for_nine'
}

/** @deprecated */
enum ContractPlanKyukodai {
  ROOM_FOR_G = 'room_for_g',
  ROOM_FOR_Y = 'room_for_y',
  ROOM_FOR_M = 'room_for_m'
}

/** @deprecated */
enum ContractPlanKagoshima {
  PERSONAL = 'personal',
  COMPANY = 'company'
}

/** @deprecated */
enum ContractPlanFukuyama {
  TEN_DAYS = 'ten_days',
  EIGHTY_HOURS = 'eighty_hours',
  ONE_HUNDRED_SIXTY_HOURS = 'one_hundred_sixty_hours',
  THREE_HUNDREDS_TWENTY_HOURS = 'three_hundreds_twenty_hours',
  ALL_DAYS = 'all_days',
  OFFICE_TWO = 'office_two',
  OFFICE_THREE = 'office_three',
  OFFICE_FOUR = 'office_four',
  OFFICE_FIVE = 'office_five',
  OFFICE_SIX = 'office_six',
  OFFICE_SEVEN = 'office_seven',
  OFFICE_EIGHT = 'office_eight',
  OFFICE_NINE = 'office_nine',
  OFFICE_TEN = 'office_ten',
  OFFICE_ELEVEN = 'office_eleven',
  OFFICE_TWELVE = 'office_twelve',
  OFFICE_THIRTEEN = 'office_thirteen',
  OFFICE_FOURTEEN = 'office_fourteen',
  OFFICE_FIFTEEN = 'office_fifteen',
  OFFICE_SIXTEEN = 'office_sixteen',
  OFFICE_SEVENTEEN = 'office_seventeen',
  OFFICE_EIGHTEEN = 'office_eighteen',
  OFFICE_NINETEEN = 'office_nineteen',
  OFFICE_TWENTY = 'office_twenty',
  OFFICE_TWENTY_ONE = 'office_twenty_one',
  OFFICE_TWENTY_TWO = 'office_twenty_two',
  OFFICE_TWENTY_THREE = 'office_twenty_three',
  PROVATE_BOOTH = 'private_booth'
}

/** @deprecated */
enum ContractPlanHiroshima {
  TEN_DAYS = 'ten_days',
  EIGHTY_HOURS = 'eighty_hours',
  ONE_HUNDRED_SIXTY_HOURS = 'one_hundred_sixty_hours',
  THREE_HUNDREDS_TWENTY_HOURS = 'three_hundreds_twenty_hours',
  ALL_DAYS = 'all_days'
}

/** @deprecated */
enum ContractPlanZentsuji {
  PERSONAL_MEMBER = 'personal_member',
  ENTITY_MEMBER = 'entity_member'
}

/** @deprecated */
export type ContractPlan =
  | ContractPlanMiyazaki
  | ContractPlanNobeoka
  | ContractPlanKitakyushu
  | ContractPlanKyukodai
  | ContractPlanKagoshima
  | ContractPlanFukuyama
  | ContractPlanHiroshima
  | ContractPlanZentsuji;

/** @deprecated */
export const ContractPlans = {
  ...ContractPlanMiyazaki,
  ...ContractPlanNobeoka,
  ...ContractPlanKitakyushu,
  ...ContractPlanKyukodai,
  ...ContractPlanKagoshima,
  ...ContractPlanFukuyama,
  ...ContractPlanHiroshima,
  ...ContractPlanZentsuji
};

/** @deprecated */
const ContractPlanByBase = {
  [Base.MIYAZAKI]: ContractPlanMiyazaki,
  [Base.NOBEOKA]: ContractPlanNobeoka,
  [Base.KITAKYUSHU]: ContractPlanKitakyushu,
  [Base.KYUKODAI]: ContractPlanKyukodai,
  [Base.KAGOSHIMA]: ContractPlanKagoshima,
  fukuyama: ContractPlanFukuyama,
  hiroshima: ContractPlanHiroshima,
  zentsuji: ContractPlanZentsuji
};

/** @deprecated */
export const toContractPlans = (baseCode: Code | undefined): Option[] => {
  if (!baseCode) return [];
  const optionsByBase = ContractPlanByBase[baseCode];
  return optionsByBase ? Object.values(optionsByBase) : [];
};

/** @deprecated */
const ContractPlanLabelMiyazaki: Labels = {
  [ContractPlanMiyazaki.ROOM_FOR_TWO]: '2名部屋',
  [ContractPlanMiyazaki.ROOM_FOR_THREE]: '3名部屋',
  [ContractPlanMiyazaki.ROOM_FOR_FOUR]: '4名部屋',
  [ContractPlanMiyazaki.ROOM_FOR_SIX]: '6名部屋',
  [ContractPlanMiyazaki.TEN_DAYS]: '10Days',
  [ContractPlanMiyazaki.EIGHTY_HOURS]: '80時間',
  [ContractPlanMiyazaki.ONE_HUNDRED_SIXTY_HOURS]: '160時間',
  [ContractPlanMiyazaki.THREE_HUNDREDS_TWENTY_HOURS]: '320時間',
  [ContractPlanMiyazaki.SIX_HUNDREDS_FOURTY_HOURS]: '640時間',
  [ContractPlanMiyazaki.ALL_DAYS]: 'AllDays'
};

/** @deprecated */
const ContractPlanLabelNobeoka: Labels = {
  [ContractPlanNobeoka.MONTHLY_PERSONAL_INSIDE_CITY]: '個人(市内)・月契約',
  [ContractPlanNobeoka.MONTHLY_PERSONAL_OUTSIDE_CITY]: '個人(市外)・月契約',
  [ContractPlanNobeoka.MONTHLY_COMPANY_INSIDE_CITY]: '法人(市内)・月契約',
  [ContractPlanNobeoka.MONTHLY_COMPANY_OUTSIDE_CITY]: '法人(市外)・月契約',
  [ContractPlanNobeoka.YEARLY_PERSONAL_INSIDE_CITY]: '個人(市内)・年契約',
  [ContractPlanNobeoka.YEARLY_PERSONAL_OUTSIDE_CITY]: '個人(市外)・年契約',
  [ContractPlanNobeoka.YEARLY_COMPANY_INSIDE_CITY]: '法人(市内)・年契約',
  [ContractPlanNobeoka.YEARLY_COMPANY_OUTSIDE_CITY]: '法人(市外)・年契約'
};

/** @deprecated */
const ContractPlanLabelKitakyushu: Labels = {
  [ContractPlanKitakyushu.ROOM_FOR_ONE]: '1名部屋',
  [ContractPlanKitakyushu.ROOM_FOR_TWO]: '2名部屋',
  [ContractPlanKitakyushu.ROOM_FOR_FOUR]: '4名部屋',
  [ContractPlanKitakyushu.ROOM_FOR_FIVE]: '5名部屋',
  [ContractPlanKitakyushu.ROOM_FOR_SIX]: '6名部屋',
  [ContractPlanKitakyushu.ROOM_FOR_NINE]: '9名部屋',
  [ContractPlanKitakyushu.TEN_DAYS]: '10Days',
  [ContractPlanKitakyushu.EIGHTY_HOURS]: '80時間',
  [ContractPlanKitakyushu.ONE_HUNDRED_SIXTY_HOURS]: '160時間',
  [ContractPlanKitakyushu.THREE_HUNDREDS_TWENTY_HOURS]: '320時間',
  [ContractPlanKitakyushu.TWO_THOUSAND_HOURS]: '2,000時間',
  [ContractPlanKitakyushu.ALL_DAYS]: 'AllDays'
};

/** @deprecated */
const ContractPlanLabelKyukodai: Labels = {
  [ContractPlanKyukodai.ROOM_FOR_G]: 'GYMLABO ルームG',
  [ContractPlanKyukodai.ROOM_FOR_Y]: 'GYMLABO ルームY',
  [ContractPlanKyukodai.ROOM_FOR_M]: 'GYMLABO ルームM'
};

/** @deprecated */
const ContractPlanLabelKagoshima: Labels = {
  [ContractPlanKagoshima.PERSONAL]: '個人',
  [ContractPlanKagoshima.COMPANY]: '法人'
};

/** @deprecated */
const ContractPlanLabelFukuyama: Labels = {
  [ContractPlanFukuyama.TEN_DAYS]: '10Days',
  [ContractPlanFukuyama.EIGHTY_HOURS]: '80H',
  [ContractPlanFukuyama.ONE_HUNDRED_SIXTY_HOURS]: '160H',
  [ContractPlanFukuyama.THREE_HUNDREDS_TWENTY_HOURS]: '320H',
  [ContractPlanFukuyama.ALL_DAYS]: 'AllDays',
  [ContractPlanFukuyama.OFFICE_TWO]: '事務所02',
  [ContractPlanFukuyama.OFFICE_THREE]: '事務所03',
  [ContractPlanFukuyama.OFFICE_FOUR]: '事務所04',
  [ContractPlanFukuyama.OFFICE_FIVE]: '事務所05',
  [ContractPlanFukuyama.OFFICE_SIX]: '事務所06',
  [ContractPlanFukuyama.OFFICE_SEVEN]: '事務所07',
  [ContractPlanFukuyama.OFFICE_EIGHT]: '事務所08',
  [ContractPlanFukuyama.OFFICE_NINE]: '事務所09',
  [ContractPlanFukuyama.OFFICE_TEN]: '事務所10',
  [ContractPlanFukuyama.OFFICE_ELEVEN]: '事務所11',
  [ContractPlanFukuyama.OFFICE_TWELVE]: '事務所12',
  [ContractPlanFukuyama.OFFICE_THIRTEEN]: '事務所13',
  [ContractPlanFukuyama.OFFICE_FOURTEEN]: '事務所14',
  [ContractPlanFukuyama.OFFICE_FIFTEEN]: '事務所15',
  [ContractPlanFukuyama.OFFICE_SIXTEEN]: '事務所16',
  [ContractPlanFukuyama.OFFICE_SEVENTEEN]: '事務所17',
  [ContractPlanFukuyama.OFFICE_EIGHTEEN]: '事務所18',
  [ContractPlanFukuyama.OFFICE_NINETEEN]: '事務所19',
  [ContractPlanFukuyama.OFFICE_TWENTY]: '事務所20',
  [ContractPlanFukuyama.OFFICE_TWENTY_ONE]: '事務所21',
  [ContractPlanFukuyama.OFFICE_TWENTY_TWO]: '事務所22',
  [ContractPlanFukuyama.OFFICE_TWENTY_THREE]: '事務所23',
  [ContractPlanFukuyama.PROVATE_BOOTH]: 'PRIVATE BOOTH'
};

/** @deprecated */
const ContractPlanLabelHiroshima: Labels = {
  [ContractPlanHiroshima.TEN_DAYS]: '10Days',
  [ContractPlanHiroshima.EIGHTY_HOURS]: '80時間',
  [ContractPlanHiroshima.ONE_HUNDRED_SIXTY_HOURS]: '160時間',
  [ContractPlanHiroshima.THREE_HUNDREDS_TWENTY_HOURS]: '320時間',
  [ContractPlanHiroshima.ALL_DAYS]: 'AllDays'
};

/** @deprecated */
const ContractPlanLabelZentsuji: Labels = {
  [ContractPlanZentsuji.PERSONAL_MEMBER]: '個人会員',
  [ContractPlanZentsuji.ENTITY_MEMBER]: '法人会員'
};

/** @deprecated */
export const ContractPlanLabelByBase = {
  [Base.MIYAZAKI]: ContractPlanLabelMiyazaki,
  [Base.NOBEOKA]: ContractPlanLabelNobeoka,
  [Base.KITAKYUSHU]: ContractPlanLabelKitakyushu,
  [Base.KYUKODAI]: ContractPlanLabelKyukodai,
  [Base.KAGOSHIMA]: ContractPlanLabelKagoshima,
  fukuyama: ContractPlanLabelFukuyama,
  hiroshima: ContractPlanLabelHiroshima,
  zentsuji: ContractPlanLabelZentsuji
};

/** @deprecated */
export const toContractPlanLabel = (baseCode: Code | undefined, contractPlan: Name | undefined): Label => {
  if (!baseCode || !contractPlan) return EMPTY;
  const labelsByBase = ContractPlanLabelByBase[baseCode];
  const label = labelsByBase ? labelsByBase[contractPlan] : EMPTY;
  return label ? label : EMPTY;
};

/** @deprecated */
export const CONTRACT_KEY_ISSUE_PRICE = 5000;

/** @deprecated */
export const CONTRACT_LOCKER_PRICE = 500;

/** @deprecated */
export const CONTRACT_ADDRESS_PRICE = 2000;

/** @deprecated */
const ContractPlanPriceMiyazaki: PriceInfo = {
  [ContractPlanMiyazaki.TEN_DAYS]: 8000,
  [ContractPlanMiyazaki.EIGHTY_HOURS]: 10000,
  [ContractPlanMiyazaki.ONE_HUNDRED_SIXTY_HOURS]: 15000,
  [ContractPlanMiyazaki.THREE_HUNDREDS_TWENTY_HOURS]: 25000,
  [ContractPlanMiyazaki.SIX_HUNDREDS_FOURTY_HOURS]: 50000,
  [ContractPlanMiyazaki.ALL_DAYS]: 20000,
  [ContractPlanMiyazaki.ROOM_FOR_TWO]: 60000,
  [ContractPlanMiyazaki.ROOM_FOR_THREE]: 80000,
  [ContractPlanMiyazaki.ROOM_FOR_FOUR]: 90000,
  [ContractPlanMiyazaki.ROOM_FOR_SIX]: 200000
};

/** @deprecated */
const ContractPlanPriceNobeoka: PriceInfo = {
  [ContractPlanNobeoka.MONTHLY_PERSONAL_INSIDE_CITY]: 9000,
  [ContractPlanNobeoka.MONTHLY_PERSONAL_OUTSIDE_CITY]: 15000,
  [ContractPlanNobeoka.MONTHLY_COMPANY_INSIDE_CITY]: 27000,
  [ContractPlanNobeoka.MONTHLY_COMPANY_OUTSIDE_CITY]: 45000,
  [ContractPlanNobeoka.YEARLY_PERSONAL_INSIDE_CITY]: 90000,
  [ContractPlanNobeoka.YEARLY_PERSONAL_OUTSIDE_CITY]: 150000,
  [ContractPlanNobeoka.YEARLY_COMPANY_INSIDE_CITY]: 270000,
  [ContractPlanNobeoka.YEARLY_COMPANY_OUTSIDE_CITY]: 450000
};

/** @deprecated */
const ContractPlanPriceKitakyushu: PriceInfo = {
  [ContractPlanKitakyushu.TEN_DAYS]: 8000,
  [ContractPlanKitakyushu.EIGHTY_HOURS]: 10000,
  [ContractPlanKitakyushu.ONE_HUNDRED_SIXTY_HOURS]: 18000,
  [ContractPlanKitakyushu.THREE_HUNDREDS_TWENTY_HOURS]: 32000,
  [ContractPlanKitakyushu.TWO_THOUSAND_HOURS]: 0,
  [ContractPlanKitakyushu.ALL_DAYS]: 20000,
  [ContractPlanKitakyushu.ROOM_FOR_ONE]: 35000,
  [ContractPlanKitakyushu.ROOM_FOR_TWO]: 70000,
  [ContractPlanKitakyushu.ROOM_FOR_FOUR]: 120000,
  [ContractPlanKitakyushu.ROOM_FOR_FIVE]: 150000,
  [ContractPlanKitakyushu.ROOM_FOR_SIX]: 180000,
  [ContractPlanKitakyushu.ROOM_FOR_NINE]: 270000
};

/** @deprecated */
const ContractPlanPriceKyukodai: PriceInfo = {
  [ContractPlanKyukodai.ROOM_FOR_G]: 0,
  [ContractPlanKyukodai.ROOM_FOR_Y]: 0,
  [ContractPlanKyukodai.ROOM_FOR_M]: 0
};

/** @deprecated */
const ContractPlanPriceKagoshima: PriceInfo = {
  [ContractPlanKagoshima.PERSONAL]: 6000,
  [ContractPlanKagoshima.COMPANY]: 30000
};

/** @deprecated */
const ContractPlanPriceFukuyama: PriceInfo = {
  [ContractPlanFukuyama.TEN_DAYS]: 8000,
  [ContractPlanFukuyama.EIGHTY_HOURS]: 10000,
  [ContractPlanFukuyama.ONE_HUNDRED_SIXTY_HOURS]: 15000,
  [ContractPlanFukuyama.THREE_HUNDREDS_TWENTY_HOURS]: 25000,
  [ContractPlanFukuyama.ALL_DAYS]: 20000,
  [ContractPlanFukuyama.OFFICE_TWO]: 210000,
  [ContractPlanFukuyama.OFFICE_THREE]: 160000,
  [ContractPlanFukuyama.OFFICE_FOUR]: 215000,
  [ContractPlanFukuyama.OFFICE_FIVE]: 60000,
  [ContractPlanFukuyama.OFFICE_SIX]: 60000,
  [ContractPlanFukuyama.OFFICE_SEVEN]: 60000,
  [ContractPlanFukuyama.OFFICE_EIGHT]: 65000,
  [ContractPlanFukuyama.OFFICE_NINE]: 55000,
  [ContractPlanFukuyama.OFFICE_TEN]: 55000,
  [ContractPlanFukuyama.OFFICE_ELEVEN]: 60000,
  [ContractPlanFukuyama.OFFICE_TWELVE]: 65000,
  [ContractPlanFukuyama.OFFICE_THIRTEEN]: 55000,
  [ContractPlanFukuyama.OFFICE_FOURTEEN]: 55000,
  [ContractPlanFukuyama.OFFICE_FIFTEEN]: 60000,
  [ContractPlanFukuyama.OFFICE_SIXTEEN]: 60000,
  [ContractPlanFukuyama.OFFICE_SEVENTEEN]: 60000,
  [ContractPlanFukuyama.OFFICE_EIGHTEEN]: 60000,
  [ContractPlanFukuyama.OFFICE_NINETEEN]: 105000,
  [ContractPlanFukuyama.OFFICE_TWENTY]: 105000,
  [ContractPlanFukuyama.OFFICE_TWENTY_ONE]: 165000,
  [ContractPlanFukuyama.OFFICE_TWENTY_TWO]: 155000,
  [ContractPlanFukuyama.OFFICE_TWENTY_THREE]: 165000,
  [ContractPlanFukuyama.PROVATE_BOOTH]: 30000
};

/** @deprecated */
const ContractPlanPriceHiroshima: PriceInfo = {
  [ContractPlanHiroshima.TEN_DAYS]: 8000,
  [ContractPlanHiroshima.EIGHTY_HOURS]: 10000,
  [ContractPlanHiroshima.ONE_HUNDRED_SIXTY_HOURS]: 18000,
  [ContractPlanHiroshima.THREE_HUNDREDS_TWENTY_HOURS]: 32000,
  [ContractPlanHiroshima.ALL_DAYS]: 15000
};

/** @deprecated */
const ContractPlanPriceZentsuji: PriceInfo = {
  [ContractPlanZentsuji.PERSONAL_MEMBER]: 8000,
  [ContractPlanZentsuji.ENTITY_MEMBER]: 20000
};

/** @deprecated */
const ContractPlanPriceByBase = {
  [Base.MIYAZAKI]: ContractPlanPriceMiyazaki,
  [Base.NOBEOKA]: ContractPlanPriceNobeoka,
  [Base.KITAKYUSHU]: ContractPlanPriceKitakyushu,
  [Base.KYUKODAI]: ContractPlanPriceKyukodai,
  [Base.KAGOSHIMA]: ContractPlanPriceKagoshima,
  fukuyama: ContractPlanPriceFukuyama,
  hiroshima: ContractPlanPriceHiroshima,
  zentsuji: ContractPlanPriceZentsuji
};

/** @deprecated */
export const toContractPlanPrice = (baseCode: Code | undefined, contractPlan: Name | undefined): Price => {
  if (!baseCode || !contractPlan) return ZERO;
  const pricesByBase = ContractPlanPriceByBase[baseCode];
  const price = pricesByBase ? pricesByBase[contractPlan] : ZERO;
  return price ? price : ZERO;
};
