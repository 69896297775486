import {
  CircularLoader,
  ColgroupV2,
  Component,
  DateFieldV2,
  Pagenate,
  SearchField,
  SelectBoxV2,
  StatusV2,
  TableV2,
  TbodyV2,
  TdV2,
  TheadV2,
  themeV2,
  ThV2,
  TrV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  BillingDateColumn,
  BillingIdV2,
  BillingSortColumn,
  BillingStatus,
  BillingV2,
  ContractV2,
  isBaseFunctionToggleEnabled,
  PaymentMethod,
  SEARCH_BILLINGS_V2_FOR_ADMIN,
  SearchBillingsV2ForAdminRequest,
  SearchBillingsV2ForAdminResponse,
  Sort
} from '@atomica.co/irori';
import { Code, Count, Id, Index, Label, Offset, Padding, Word } from '@atomica.co/types';
import {
  builder,
  embedIdInPath,
  EMPTY,
  hasLength,
  ONE,
  toBeginningOfDay,
  toEndOfDay,
  toFirstDayOfMonth,
  toLastDayOfMonth,
  ZERO
} from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { format } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import {
  BILLING_DATE_OPTIONS,
  BILLING_PAYMENT_OPTIONS,
  BILLING_STATUS_OPTIONS,
  BILLING_STATUS_OPTIONS_USE_PAYMENT
} from '../../../constants/billing-v2-const';
import useCachedBillingList, { CachedBillingInfo } from '../../../redux/hooks/useCachedBillingList';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import usePath from '../../../redux/hooks/usePath';
import { Path, PATH_IDS } from '../../../router/Routes';
import { BILLING_DATE_LABEL, BILLING_PAYMENT_METHOD_LABEL, BILLING_STATUS_LABEL } from '../../../texts/billing-v2-text';
import { NO_DATA_TEXT } from '../../../texts/common-text';
import { updateSortCol } from '../../../utils/contract-v2-util';

const LIMIT = 50;

const TABLE_COLUMNS: TableColumn[] = [
  { label: 'ステータス', sortCol: BillingSortColumn.BILLING_STATUS },
  { label: '請求番号', sortCol: BillingSortColumn.BILLING_NO },
  { label: '件名', sortCol: BillingSortColumn.BILLING_NAME },
  { label: '請求金額', sortCol: BillingSortColumn.TAX_INCLUDED_TOTAL_PRICE, align: 'right' },
  { label: '請求締め日', sortCol: BillingSortColumn.CUTOFF_DATE },
  { label: '請求日', sortCol: BillingSortColumn.BILLING_DATE }
];

interface P {
  base: BaseDto;
  contract: ContractV2 | undefined;
}

interface TableColumn {
  label: Label;
  sortCol?: BillingSortColumn;
  horizonPadding?: Padding;
  align?: 'center' | 'left' | 'right';
}

const ContractBillings: React.FC<P> = React.memo(props => {
  const { base, contract } = props;
  const unmountRef = useUnmountRef();
  const { openPathInNewTab } = usePath();
  const { cachedBillingInfo, saveCachedBillingInfo, clearCachedBillingInfo } = useCachedBillingList();
  const { commonRequest } = useCommonRequest();

  const [isSorting, setIsSorting] = useSafeState<boolean>(unmountRef, false);
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, true);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, ZERO);
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [billings, setBillings] = useSafeState<BillingV2[]>(unmountRef, []);
  const [totalRecordCount, setTotalRecordCount] = useSafeState<Count | undefined>(unmountRef);
  const [sortKey, setSortKey] = useSafeState<BillingSortColumn>(unmountRef, BillingSortColumn.BILLING_NO);
  const [sort, setSort] = useSafeState<Sort>(unmountRef, Sort.DESC);
  const [selectedFilterDate, setSelectedFilterDate] = useSafeState<BillingDateColumn>(
    unmountRef,
    BillingDateColumn.CUTOFF_DATE
  );
  const [startDate, setStartdate] = useSafeState<Date>(unmountRef, toFirstDayOfMonth(new Date(), ZERO));
  const [endDate, setEnddate] = useSafeState<Date>(unmountRef, toLastDayOfMonth(new Date(), ZERO));
  const [selectedStatus, setSelectedStatus] = useSafeState<BillingStatus>(unmountRef);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useSafeState<PaymentMethod>(unmountRef);

  const baseCode = useMemo((): Code => base.baseCode, [base]);
  const baseId = useMemo((): Id => base.baseId, [base]);

  const handleClickSortCol = useSafeCallback(
    (sortCol: BillingSortColumn | undefined): void => {
      updateSortCol(sortCol, setSortKey, setSort);
      setIsSorting(true);
    },
    [setSortKey, setSort, setIsSorting]
  );

  const handleClickRow = useSafeCallback(
    (billingId: BillingIdV2): void => {
      const billingInfo = builder<CachedBillingInfo>()
        .offset(ZERO)
        .sortKey(BillingSortColumn.BILLING_NO)
        .sort(Sort.DESC)
        .searchingWord(EMPTY)
        .totalRecordCount(ONE)
        .selectedBillingIds([billingId])
        .billings(billings.filter(billing => billing.billingId === billingId))
        .build();

      saveCachedBillingInfo(billingInfo);
      openPathInNewTab(embedIdInPath(Path.BILLING_DETAILS_V2_DETAIL, PATH_IDS, [baseCode, billingId]));
    },
    [baseCode, billings, openPathInNewTab, saveCachedBillingInfo]
  );

  const searchBillings = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<SearchBillingsV2ForAdminRequest>()
      .baseId(baseId)
      .word(searchingWord)
      .filterDate(selectedFilterDate)
      .start(toBeginningOfDay(startDate)!)
      .end(toEndOfDay(endDate)!)
      .contractId(contract!.contractId)
      .limit(LIMIT)
      .offset(offset)
      .sortCol(sortKey)
      .sort(sort);
    if (selectedPaymentMethod) request.paymentMethod(selectedPaymentMethod);
    if (selectedStatus) request.status(selectedStatus);

    const response = await commonRequest<SearchBillingsV2ForAdminRequest, SearchBillingsV2ForAdminResponse>(
      SEARCH_BILLINGS_V2_FOR_ADMIN,
      request.build()
    );

    if (response) {
      setBillings(response.billings);
      setTotalRecordCount(response.count);
    }
    setIsLoaderShown(false);
    setIsSorting(false);
  }, [
    baseId,
    contract,
    selectedFilterDate,
    startDate,
    endDate,
    selectedPaymentMethod,
    selectedStatus,
    sortKey,
    sort,
    searchingWord,
    offset,
    setBillings,
    setIsLoaderShown,
    setTotalRecordCount,
    setIsSorting
  ]);

  const isUseContractPayment = useMemo(() => {
    return [BaseFunctionToggleCode.FUNCTION_USE_STRIPE, BaseFunctionToggleCode.FUNCTION_USE_SAISON].some(e =>
      isBaseFunctionToggleEnabled(base, e)
    );
  }, [base]);

  const refreshBillings = useSafeCallback(async (): Promise<void> => {
    setOffset(ZERO);
    await searchBillings();
  }, [setOffset, searchBillings]);

  useEffect(() => {
    if (cachedBillingInfo) return;
    refreshBillings();
  }, [refreshBillings, sortKey, sort, searchingWord, cachedBillingInfo]);

  const handleOffsetChanged = useSafeCallback(async (): Promise<void> => {
    await searchBillings();
  }, [searchBillings]);

  useEffect(() => {
    handleOffsetChanged();
  }, [handleOffsetChanged, offset]);

  useEffect(() => {
    clearCachedBillingInfo();
  }, [clearCachedBillingInfo]);

  return (
    <Component className='contract-billings' style={styleForComponent}>
      <Container>
        <TableWrapper>
          <SearchRow>
            <CountLabel>{totalRecordCount !== undefined && `${totalRecordCount}件の請求`}</CountLabel>
            <SearchFieldWrapper>
              <SearchField placeholder='請求番号・請求者・作成者' value={searchingWord} onChange={setSearchingWord} />
            </SearchFieldWrapper>
          </SearchRow>
          <ConditionRow>
            <FieldWrapper>
              <SelectBoxV2
                options={BILLING_DATE_OPTIONS}
                labels={BILLING_DATE_LABEL}
                onChange={setSelectedFilterDate}
                value={selectedFilterDate}
              />
            </FieldWrapper>
            <FieldWrapper>
              <DateFieldV2 value={startDate} shape='circle' onChange={setStartdate}></DateFieldV2>
            </FieldWrapper>
            ～
            <FieldWrapper>
              <DateFieldV2 value={endDate} shape='circle' onChange={setEnddate}></DateFieldV2>
            </FieldWrapper>
            <FieldWrapper>
              <SelectBoxV2
                labels={BILLING_STATUS_LABEL}
                nullable={true}
                options={isUseContractPayment ? BILLING_STATUS_OPTIONS_USE_PAYMENT : BILLING_STATUS_OPTIONS}
                placeholder='ステータス'
                value={selectedStatus}
                onChange={setSelectedStatus}
              />
            </FieldWrapper>
            <FieldWrapper>
              <SelectBoxV2
                labels={BILLING_PAYMENT_METHOD_LABEL}
                nullable={true}
                options={BILLING_PAYMENT_OPTIONS}
                placeholder='支払方法'
                value={selectedPaymentMethod || EMPTY}
                onChange={setSelectedPaymentMethod}
              />
            </FieldWrapper>
          </ConditionRow>
          {(!isLoaderShown || isSorting) && hasLength(billings) && (
            <WithPagenation>
              <TableV2>
                <ColgroupV2 width={120} />
                <ColgroupV2 width={160} />
                <ColgroupV2 />
                <ColgroupV2 width={125} />
                <ColgroupV2 width={125} />
                <ColgroupV2 width={125} />
                <TheadV2>
                  <TrV2>
                    {TABLE_COLUMNS.map((column: TableColumn, idx: Index) => (
                      <ThV2
                        key={idx}
                        onClick={() => handleClickSortCol(column.sortCol)}
                        horizonPadding={column.horizonPadding}
                        align={column.align}
                      >
                        {column.sortCol ? (
                          <SortColumn align={column.align ?? 'left'}>
                            {column.label}
                            {column.sortCol === sortKey &&
                              (sort === Sort.ASC ? <CustomExpandLessIcon /> : <CustomExpandMoreIcon />)}
                          </SortColumn>
                        ) : (
                          column.label
                        )}
                      </ThV2>
                    ))}
                  </TrV2>
                </TheadV2>
                <TbodyV2>
                  {billings.map((row, index) => (
                    <TrV2
                      key={`tr${index}`}
                      onClick={() => {
                        handleClickRow(row.billingId);
                      }}
                    >
                      <TdV2>
                        <StatusWrapper>
                          {row.billingStatus === BillingStatus.CONFIRMED && (
                            <StatusV2
                              label={BILLING_STATUS_LABEL[BillingStatus.CONFIRMED]}
                              variant='normal'
                              status='success'
                              width={84}
                            />
                          )}
                          {row.billingStatus === BillingStatus.UNCONFIRMED && (
                            <StatusV2
                              label={BILLING_STATUS_LABEL[BillingStatus.UNCONFIRMED]}
                              variant='normal'
                              status='default'
                              width={84}
                            />
                          )}
                          {row.billingStatus === BillingStatus.PAYMENT_COMPLETED && (
                            <StatusV2
                              label={BILLING_STATUS_LABEL[BillingStatus.PAYMENT_COMPLETED]}
                              variant='normal'
                              status='success'
                              width={84}
                            />
                          )}
                          {row.billingStatus === BillingStatus.PAYMENT_ERROR && (
                            <StatusV2
                              label={BILLING_STATUS_LABEL[BillingStatus.PAYMENT_ERROR]}
                              variant='normal'
                              status='error'
                              width={84}
                            />
                          )}
                        </StatusWrapper>
                      </TdV2>
                      <TdV2>{row.billingNo}</TdV2>
                      <TdV2>{row.billingName}</TdV2>
                      <TdV2 align='right'>￥{row.taxIncludedTotalPrice?.toLocaleString() ?? EMPTY}</TdV2>
                      <TdV2>{format(new Date(row.cutoffDate), 'yyyy/MM/dd')}</TdV2>
                      <TdV2>{format(new Date(row.billingDate), 'yyyy/MM/dd')}</TdV2>
                    </TrV2>
                  ))}
                </TbodyV2>
              </TableV2>
              <Pagenate
                offset={offset}
                limit={LIMIT}
                count={totalRecordCount}
                onClickBack={() => setOffset(offset - ONE)}
                onClickForward={() => setOffset(offset + ONE)}
              />
            </WithPagenation>
          )}

          {!isLoaderShown && !hasLength(billings) && <NoSearchResult>{NO_DATA_TEXT}</NoSearchResult>}

          {isLoaderShown && !isSorting && (
            <LoaderWrapper>
              <CircularLoader />
            </LoaderWrapper>
          )}
        </TableWrapper>
      </Container>
    </Component>
  );
});

ContractBillings.displayName = 'ContractBillings';
export default ContractBillings;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TableWrapper = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  background-color: ${themeV2.mixins.v2.color.background.white};
  border-radius: 6px;
  margin: ${themeV2.mixins.v2.spacing * 2}px 0;
`;

const SearchRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${themeV2.mixins.v2.spacing * 1.5}px ${themeV2.mixins.v2.spacing * 2}px;
`;

const WithPagenation = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${themeV2.mixins.v2.spacing * 8}px;
`;

const CountLabel = styled(Typography)`
  ${themeV2.mixins.v2.typography.label.large};
`;

const SearchFieldWrapper = styled.div`
  width: 296px;
`;

const SortColumn = styled.div<{ align: string }>`
  display: flex;
  justify-content: ${({ align }) => (align === 'right' ? 'end' : 'space-between')};
  align-items: center;
`;

const CustomExpandLessIcon = styled(ExpandLessIcon)`
  ${themeV2.mixins.v2.typography.body.small};
`;

const CustomExpandMoreIcon = styled(ExpandMoreIcon)`
  ${themeV2.mixins.v2.typography.body.small};
`;

const StatusWrapper = styled.div`
  width: min-content;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConditionRow = styled.div`
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 3}px;
  display: flex;
  gap: ${themeV2.mixins.v2.spacing}px;
  align-items: center;
  background-color: ${themeV2.mixins.v2.color.background.lightGray};
`;

const FieldWrapper = styled.div`
  width: 160px;
  height: 36px;
`;

const LoaderWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoSearchResult = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
  padding: ${themeV2.mixins.v2.spacing * 3}px;
`;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: '100%'
};
