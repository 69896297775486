import {
  ButtonV2,
  Components,
  InputWithLabelV2,
  LabelV2,
  PageHeaderV2,
  RadioBoxV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  EventHoldingMethod,
  EventInflowSource,
  EventScheduleParticipantV2,
  EventScheduleV2,
  EventV2,
  SAVE_EVENT_SCHEDULE_PARTICIPANTS_V2,
  SaveEventScheduleParticipantsV2Request,
  SaveEventScheduleParticipantsV2Response,
  User
} from '@atomica.co/irori';
import { Email, Label, Message, Name, Text } from '@atomica.co/types';
import { EMPTY, builder, isEmpty, toFormattedDateTimeDurationStr, uuid } from '@atomica.co/utils';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import mojaco from '../../../../assets/mojaco/mojaco_break.png';
import { useSnackbarV2 } from '../../../../provider/SnackbarProviderV2';
import CommonRequest from '../../../../requests/common-request';
import { EVENT_HOLDING_METHOD_LABELS, EVENT_INFLOW_SOURCE_LABELS } from '../../../../texts/event-text';
import { ENTRY_FORM_SAVED } from '../../../../texts/snackbar-text';

interface P {
  base: BaseDto;
  user: User;
  event: EventV2;
  eventSchedule: EventScheduleV2;
  isConfirmation: boolean;
  onClick: (isConfirmation: boolean) => void;
  onChange: () => void;
}

const getButtonLabelForHoldingMethod = (holdingMethod: EventHoldingMethod): Label => {
  switch (holdingMethod) {
    case EventHoldingMethod.ONLINE:
      return 'リンクを開く';
    case EventHoldingMethod.OFFLINE:
      return 'マップを開く';
    default:
      return EMPTY;
  }
};

const AccountEventEntryForm: React.FC<P> = React.memo(props => {
  const { user, event, eventSchedule, isConfirmation, onClick, onChange } = props;
  const { openSnackbar } = useSnackbarV2();

  const unmountRef = useUnmountRef();
  const [email, setEmail] = useSafeState<Email>(unmountRef, user.email || EMPTY);
  const [eventInflowSouce, setEventInflowSource] = useSafeState<EventInflowSource>(
    unmountRef,
    EventInflowSource.CONTINUE
  );
  const [friendName, setFriendName] = useSafeState<Name>(unmountRef, EMPTY);
  const [question, setQuestion] = useSafeState<Text>(unmountRef, EMPTY);
  const [messageForParticipants, setMessageForParticipants] = useSafeState<Message>(unmountRef, EMPTY);
  const [isValid, setIsValid] = useSafeState<boolean>(unmountRef, true);
  const [isSaving, setIsSaving] = useSafeState<boolean>(unmountRef, false);
  const [isThumbnailExisting, setIsThumbnailExisting] = useSafeState<boolean>(unmountRef, true);

  const isDisabled = useMemo<boolean>(() => !isValid || isEmpty(email), [isValid, email]);

  const saveEntryForm = useSafeCallback(async (): Promise<void> => {
    if (!user || !eventSchedule || !eventSchedule.participants) return;
    setIsSaving(true);
    const existingParticipant = eventSchedule.participants.find(
      participant => participant.user?.userId === user.userId
    );

    const participant = builder<EventScheduleParticipantV2>()
      .eventScheduleParticipantId(existingParticipant?.eventScheduleParticipantId || uuid())
      .particiantEmail(email)
      .particiantInflowSource(eventInflowSouce)
      .question(question)
      .messageForParticipants(messageForParticipants)
      .isInterested(existingParticipant?.isInterested || false)
      .isApplied(true)
      .eventSchedule(eventSchedule)
      .user(user)
      .build();

    const request = builder<SaveEventScheduleParticipantsV2Request>()
      .eventScheduleParticipantsV2([participant])
      .applyPoints(true)
      .build();

    const response = await CommonRequest.call<
      SaveEventScheduleParticipantsV2Request,
      SaveEventScheduleParticipantsV2Response
    >(SAVE_EVENT_SCHEDULE_PARTICIPANTS_V2, request);

    setIsSaving(false);
    if (!response.eventScheduleParticipantIds) return;

    onChange();
    openSnackbar(ENTRY_FORM_SAVED, 'success', 3000);
  }, [email, eventInflowSouce, eventSchedule, messageForParticipants, question, user, onChange, openSnackbar]);

  const handleEmailChanged = useSafeCallback(
    (email: Email) => {
      setEmail(email);
      setIsValid(true);
    },
    [setEmail, setIsValid]
  );

  const handleButtonClicked = useSafeCallback(() => {
    onClick(true);
  }, [onClick]);

  const childComponents = useMemo<Components>(() => {
    return {
      [EventInflowSource.FRIEND]: (
        <StyledInputWithLabelV2
          key={EventInflowSource.FRIEND}
          optional
          text='紹介してくれた方のお名前'
          value={friendName}
          onChange={setFriendName}
        />
      )
    };
  }, [friendName, setFriendName]);

  return (
    <Container>
      <Contents>
        <Content>
          <ThumbnailWrapper>
            <Thumbnail
              src={isThumbnailExisting && eventSchedule.thumbnailURL ? eventSchedule.thumbnailURL : mojaco}
              onError={() => setIsThumbnailExisting(false)}
            />
          </ThumbnailWrapper>
          <TextWrapper>
            <HeaderWrapper>
              <PageHeaderV2 title={isConfirmation ? '申し込み内容の確認' : '申し込みフォーム'} />
              <EventName>
                <PageHeaderV2 title={event.name} />
                {eventSchedule.name}
              </EventName>
            </HeaderWrapper>
          </TextWrapper>
        </Content>

        <div>
          <EntryText>以下のイベントに申し込みます</EntryText>
          <CardWrapper>
            <CardRow>
              <LabelWrapper>
                <LabelV2 text='イベント' fontSize='large' />
              </LabelWrapper>
              <div>
                <CardText>{event.name}</CardText>
                <SubText>{eventSchedule.name}</SubText>
              </div>
            </CardRow>
            <Border />
            <CardRow>
              <LabelWrapper>
                <LabelV2 text='日時' fontSize='large' />
              </LabelWrapper>
              <CardText>
                {toFormattedDateTimeDurationStr(
                  eventSchedule.startAtV2,
                  eventSchedule.endAtV2,
                  'yyyy/MM/dd(E) HH:mm',
                  '~'
                )}
              </CardText>
            </CardRow>
            <Border />
            <CardRow>
              <LabelWrapper>
                <LabelV2 text='開催方法' fontSize='large' />
              </LabelWrapper>
              <HoldingMethodWrapper>
                <HoldingMethodText>{EVENT_HOLDING_METHOD_LABELS[eventSchedule.holdingMethod]}</HoldingMethodText>
                {!(eventSchedule.holdingMethod === EventHoldingMethod.UNDECIDED) && (
                  <ButtonV2
                    type='secondary'
                    label={getButtonLabelForHoldingMethod(eventSchedule.holdingMethod)}
                    onClick={() => {}}
                  />
                )}
              </HoldingMethodWrapper>
            </CardRow>
          </CardWrapper>
        </div>
        <CardWrapper>
          <InputWrapper>
            <InputWithLabelV2
              required={!isConfirmation}
              errorMessage={!isValid ? 'メールアドレスを入力してください' : EMPTY}
              text='メールアドレス'
              readonly={isConfirmation}
              value={email}
              onChange={handleEmailChanged}
              onBlur={() => setIsValid(!isEmpty(email))}
            />
          </InputWrapper>
          {isConfirmation && (
            <>
              <InputWrapper>
                <InputWithLabelV2
                  optional={!isConfirmation}
                  readonly
                  text='何でこのイベントを知りましたか？'
                  value={EVENT_INFLOW_SOURCE_LABELS[eventInflowSouce]}
                />
              </InputWrapper>
              {friendName && eventInflowSouce === EventInflowSource.FRIEND && (
                <InputWrapper>
                  <InputWithLabelV2
                    optional={!isConfirmation}
                    readonly
                    text='紹介してくれた方のお名前'
                    value={friendName}
                  />
                </InputWrapper>
              )}
            </>
          )}
          {!isConfirmation && (
            <>
              <LabelV2 optional text='何でこのイベントを知りましたか？' />
              <RadioBoxWrapper>
                <RadioBoxV2
                  labels={EVENT_INFLOW_SOURCE_LABELS}
                  options={Object.values(EventInflowSource)}
                  childComponents={childComponents}
                  direction='column'
                  value={eventInflowSouce}
                  onChange={setEventInflowSource}
                />
              </RadioBoxWrapper>
            </>
          )}
          <InputWrapper>
            <InputWithLabelV2
              optional={!isConfirmation}
              multiline
              text='スタッフへの質問事項'
              readonly={isConfirmation}
              value={question}
              onChange={setQuestion}
            />
          </InputWrapper>
          <InputWithLabelV2
            optional={!isConfirmation}
            maxRows={2}
            text='他の参加者へのメッセージ'
            placeholder={isConfirmation ? EMPTY : '他の参加者に向けて、簡単な自己紹介やメッセージを書いてみましょう！'}
            readonly={isConfirmation}
            value={messageForParticipants}
            onChange={setMessageForParticipants}
          />
        </CardWrapper>
      </Contents>

      <Footer>
        {isConfirmation && (
          <ButtonWrapper>
            <StyledButton type='secondary' label='回答に戻る' onClick={() => onClick(false)} />
            <StyledButton disabled={isSaving} type='primary' label='送信' onClick={saveEntryForm} />
          </ButtonWrapper>
        )}
        {!isConfirmation && (
          <StyledButton disabled={isDisabled} type='primary' label='確認へ' onClick={handleButtonClicked} />
        )}
        <StyledButton label='キャンセル' onClick={onChange} />
      </Footer>
    </Container>
  );
});

AccountEventEntryForm.displayName = 'AccountEventEntryForm';
export default AccountEventEntryForm;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-bottom: ${themeV2.mixins.v2.spacing * 30}px;
  gap: ${themeV2.mixins.v2.spacing}px;
  ${media.greaterThan('small')`
    gap: ${themeV2.mixins.v2.spacing * 3}px;;
  `}
`;

const ThumbnailWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  ${media.greaterThan('small')`
    width: 160px;
    min-width: 160px;
    height: 120px;
  `}
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9;
  ${media.greaterThan('small')`
    border-radius: 12px;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  ${media.greaterThan('small')`
    flex-direction: row;
    margin: 0 ${themeV2.mixins.v2.spacing * 2}px 0;
  `};
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: 0 ${themeV2.mixins.v2.spacing * 2}px 0;
  ${media.greaterThan('small')`
    gap: ${themeV2.mixins.v2.spacing}px;
    padding: 0;
  `};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const HeaderWrapper = styled(Wrapper)``;
const HoldingMethodWrapper = styled(Wrapper)``;

const EventName = styled(Wrapper)`
  ${media.lessThan('small')`
    display:none;
  `};
`;

const HoldingMethodText = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 12px;
  margin: 0 ${themeV2.mixins.v2.spacing}px 0;
`;

const CardRow = styled.div`
  display: flex;
  align-items: start;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const LabelWrapper = styled.div`
  width: 72px;
`;

const CardText = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
`;

const EntryText = styled(CardText)`
  margin: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 2}px;
`;

const SubText = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const Border = styled.div`
  border-top: 1px dashed ${themeV2.mixins.v2.color.border.gray};
  margin: ${themeV2.mixins.v2.spacing * 2}px 0;
`;

const InputWrapper = styled.div`
  margin-bottom: ${themeV2.mixins.v2.spacing * 3}px;
`;

const RadioBoxWrapper = styled.div`
  margin: 0 0 ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 1.5}px;
`;

const Footer = styled.div`
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  bottom: 0;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV2.mixins.v2.color.background.white};
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const StyledButton = styled(ButtonV2).attrs(() => ({ isFullWidth: true, size: 'large' }))`
  ${media.greaterThan('small')`
      width: 200px !important;
  `}
`;

const StyledInputWithLabelV2 = styled(InputWithLabelV2)`
  padding-left: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;
