import {
  CommentBox,
  Component,
  IconButtonV2,
  InputBox,
  MultiCheckBox,
  MultiCheckBoxOption,
  PageHeaderV2,
  QRCode,
  RadioButton,
  SelectBox,
  SelectBoxV2,
  theme,
  themeV2,
  themeV3,
  useMobile,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AuthorityId,
  BaseDto,
  BaseFunctionToggleCode,
  FETCH_SHOT_CATEGORIES,
  FetchShotCategoriesRequest,
  FetchShotCategoriesResponse,
  Shot,
  ShotCategory,
  ShotSubcategory,
  User,
  isBaseFunctionToggleEnabled
} from '@atomica.co/irori';
import { Code, Count, Message, Name, NoStr, Title, URL } from '@atomica.co/types';
import {
  ASTERISK,
  EMPTY,
  FIRST_INDEX,
  ONE,
  ZERO,
  builder,
  hasLength,
  isGreaterThanZero,
  removeBreaks,
  uuid
} from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { toShot } from '../../../converters/shot-usage-converter';
import { Hour, NumberOfPeople } from '../../../enums/common-enum';
import usePath from '../../../redux/hooks/usePath';
import CommonRequest from '../../../requests/common-request';
import { Path } from '../../../router/Routes';
import { HOURS_LABELS, NUMBER_OF_PEOPLE_LABELS } from '../../../texts/common-text';
import { getItemCode } from '../../../utils/item-util';
import { getReceptionURL } from '../../../utils/path-util';
import {
  getOneDayDiscountPrice,
  isQuantityFixed,
  isQuantityManual,
  toShotCategoryLabels,
  toShotCategoryOptions
} from '../../../utils/shot-category-util';
import { getBasePICV2, isAdmin } from '../../../utils/user-util';
import mojaco_greeting from './../../../assets/mojaco/mojaco_greeting.png';

export const toOption = (conferenceOneDayUsage: boolean): MultiCheckBoxOption[] => {
  return [
    {
      id: 'conference-one-day-usage',
      label: '1DAYも利用する',
      checked: conferenceOneDayUsage
    }
  ];
};

const toQRCode = (receptionURL: URL): React.ReactNode => {
  return <QRCode size={200} color={themeV2.mixins.v2.color.background.white} url={receptionURL} />;
};

const Mojaco = React.memo(
  React.forwardRef<HTMLDivElement, { message: Message; children: React.ReactNode }>((props, ref) => {
    const { message, children } = props;
    return (
      <>
        <MojacoWrapper>
          <CommentBox animation photoURL={mojaco_greeting}>
            <Greeting>{message}</Greeting>
          </CommentBox>
        </MojacoWrapper>
        {children}
        {ref && <ScrollTarget ref={ref} />}
      </>
    );
  })
);

interface CardProps {
  required?: boolean;
  title: Title;
  isIconButtonShown: boolean;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = props => {
  const { title, required, isIconButtonShown, children } = props;
  const { openBasePath } = usePath();
  return (
    <CardWrapper>
      <CardTitleWrapper>
        <CardTitle>{`${title}${required ? ASTERISK : EMPTY}`}</CardTitle>
        {isIconButtonShown && (
          <IconButtonV2 icon={<CreateOutlinedIcon />} onClick={() => openBasePath(Path.SHOT_MASTER)} />
        )}
      </CardTitleWrapper>
      <CardContent>{children}</CardContent>
    </CardWrapper>
  );
};

interface P {
  base: BaseDto;
  user: User;
}

const AccountShot: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const unmountRef = useUnmountRef();
  const isMobile = useMobile();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [categories, setCategories] = useSafeState<ShotCategory[]>(unmountRef, []);
  const [selectedCategory, setSelectedCategory] = useSafeState<ShotCategory | undefined>(unmountRef);
  const [selectedSubcategory, setSelectedSubcategory] = useSafeState<ShotSubcategory | undefined>(unmountRef);
  const [shotUsage, setShotUsage] = useSafeState<Shot>(unmountRef);
  const [cardNo, setCardNo] = useSafeState<NoStr>(unmountRef, EMPTY);
  const [conferenceUsageHours, setConferenceUsageHours] = useSafeState<Hour>(unmountRef, Hour.ONE);
  const [conferenceNumberOfPeople, setConferenceNumberOfPeople] = useSafeState<Count>(unmountRef, ONE);
  const [conferenceOneDayUsage, setConferenceOneDayUsage] = useSafeState<boolean>(unmountRef, false);
  const [conferenceOneDayNumberOfPeople, setConferenceOneDayNumberOfPeople] = useSafeState<Count>(unmountRef, ONE);
  const [receiptName, setReceiptName] = useSafeState<Name>(unmountRef, EMPTY);

  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  const receptionURL = useMemo<URL>(() => {
    const url = base ? getReceptionURL(shotUsage, base.baseCode) : EMPTY;
    console.log(url);
    return url;
  }, [shotUsage, base]);

  const categoryName = useMemo<Name>(() => {
    if (!selectedCategory) return '会議室';
    return selectedCategory.item!.itemName;
  }, [selectedCategory]);

  const authorityIds = useMemo<AuthorityId[]>(
    () => getBasePICV2(user, base).map(pic => pic.authority!.authorityId),
    [base, user]
  );

  const subCategoriesForDisplay = useMemo<ShotSubcategory[]>(() => {
    if (!selectedCategory || !selectedCategory.subcategories) return [];
    return selectedCategory.subcategories.filter(sub => {
      if (
        !isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_RESOURCE_AUTHORITY) ||
        !(sub.item?.spaces && hasLength(sub.item.spaces))
      )
        return true;
      const authorities = sub.item.spaces[FIRST_INDEX].authorities;
      return (
        !hasLength(authorities) ||
        authorities!.some(spaceAuth => authorityIds.includes(spaceAuth.authority!.authorityId))
      );
    });
  }, [base, authorityIds, selectedCategory]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchShotCategoriesRequest>().baseCode(base.baseCode).build();
    const response = await CommonRequest.call<FetchShotCategoriesRequest, FetchShotCategoriesResponse>(
      FETCH_SHOT_CATEGORIES,
      request
    );
    setCategories(response.categories);
    setLoaded(true);
  }, [base, setCategories, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    setShotUsage(prevShotUsage => {
      if (!base || !user) return prevShotUsage;

      const shotUsageToUpdate = toShot(
        prevShotUsage ? prevShotUsage.shotId : uuid(),
        base,
        user,
        selectedCategory,
        selectedSubcategory,
        conferenceUsageHours,
        conferenceNumberOfPeople,
        conferenceOneDayUsage,
        conferenceOneDayNumberOfPeople,
        receiptName,
        cardNo
      );

      return shotUsageToUpdate;
    });
  }, [
    setShotUsage,
    selectedCategory,
    base,
    user,
    selectedSubcategory,
    conferenceUsageHours,
    conferenceNumberOfPeople,
    conferenceOneDayUsage,
    conferenceOneDayNumberOfPeople,
    receiptName,
    cardNo
  ]);

  const handleCategoryChanged = useSafeCallback(
    (itemCode: Code): void => {
      const selectedCategory = categories.find(category => !!category.item && category.item.itemCode === itemCode);
      setSelectedCategory(selectedCategory);
      setSelectedSubcategory(undefined);
      ref1.current?.scrollIntoView({ behavior: 'smooth' });
    },
    [categories, setSelectedCategory, setSelectedSubcategory]
  );

  const handleSubCategoryChanged = useSafeCallback(
    (itemCode: Code): void => {
      const selectedSubCategory = subCategoriesForDisplay!.find(
        category => !!category.item && category.item.itemCode === itemCode
      );
      setSelectedSubcategory(selectedSubCategory);
      ref2.current?.scrollIntoView({ behavior: 'smooth' });
    },
    [setSelectedSubcategory, subCategoriesForDisplay]
  );

  const receiptComponent = useMemo<JSX.Element>(() => {
    if (!shotUsage) return <></>;
    return (
      <>
        {isGreaterThanZero(shotUsage.taxIncludedTotalPrice) && (
          <Mojaco message='領収書が必要な場合は宛名を記入してね！'>
            <InputBox type='text' label='領収書の宛名' text={receiptName} onChange={setReceiptName} />
          </Mojaco>
        )}

        <Mojaco message='スタッフから貸出カードを受け取ったら、その番号を入力してね！'>
          <InputBox maxLength={10} type='number' label='貸出カードNo.' text={cardNo} onChange={setCardNo} />
        </Mojaco>

        <Mojaco message='入力ありがとう！このQRコードをスタッフに見せてね！QRの読み取りが終わったら入館登録は完了だよ！そうしたらこの画面は閉じても大丈夫！'>
          <QRCodeWrapper>{toQRCode(receptionURL)}</QRCodeWrapper>
        </Mojaco>
      </>
    );
  }, [cardNo, receptionURL, receiptName, setCardNo, setReceiptName, shotUsage]);

  const isExistingOneDay = useMemo<boolean>(
    () => categories.some(category => category.item?.itemName.includes('1DAY利用')),
    [categories]
  );

  return (
    <Component
      style={styleForComponent}
      loading={!loaded}
      className='account-shot-screen'
      title={'knot PLACE -入館受付-'}
    >
      <Container>
        <TitleWrapper>
          <PageHeaderV2 title='入館受付' titleSize={isMobile ? 'small' : 'default'} />
        </TitleWrapper>
        <Mojaco
          ref={ref1}
          message={`${base ? `${removeBreaks(base.baseName)}へようこそ！` : EMPTY}
        利用する項目を選んでね！${isExistingOneDay ? '1日利用の場合は「1DAY利用」を選んでね！' : EMPTY}`}
        >
          <Card title='本日のご利用内容' isIconButtonShown={isAdmin(user, base.baseCode)}>
            <RadioButton
              options={toShotCategoryOptions(categories)}
              labels={toShotCategoryLabels(categories)}
              value={selectedCategory ? getItemCode(selectedCategory.item) : undefined}
              onChange={handleCategoryChanged}
            />
          </Card>
        </Mojaco>

        {isQuantityFixed(selectedCategory) && (
          <>
            {hasLength(subCategoriesForDisplay) && (
              <Mojaco ref={ref2} message='該当するものがあれば選んでね！'>
                <Card title='該当するものをお選びください' isIconButtonShown={isAdmin(user, base.baseCode)}>
                  <RadioButton
                    options={toShotCategoryOptions(subCategoriesForDisplay)}
                    labels={toShotCategoryLabels(subCategoriesForDisplay)}
                    value={selectedSubcategory ? getItemCode(selectedSubcategory.item) : undefined}
                    onChange={handleSubCategoryChanged}
                  />
                </Card>
              </Mojaco>
            )}

            {(!hasLength(subCategoriesForDisplay) || (hasLength(subCategoriesForDisplay) && !!selectedSubcategory)) &&
              receiptComponent}
          </>
        )}

        {isQuantityManual(selectedCategory) && (
          <>
            <Mojaco
              ref={ref2}
              message={`${categoryName}の申込者は申し込んだ${categoryName}を選んでね！申込者以外は「ゲスト利用」を選んでね！`}
            >
              <Card title={categoryName} isIconButtonShown={isAdmin(user, base.baseCode)}>
                <RadioButton
                  options={toShotCategoryOptions(subCategoriesForDisplay)}
                  labels={toShotCategoryLabels(subCategoriesForDisplay)}
                  value={selectedSubcategory ? getItemCode(selectedSubcategory.item) : undefined}
                  onChange={handleSubCategoryChanged}
                />
              </Card>
            </Mojaco>

            {isQuantityFixed(selectedSubcategory) && receiptComponent}

            {isQuantityManual(selectedSubcategory) && (
              <>
                <Mojaco message='利用時間と人数を選んでね！'>
                  <Content>
                    <Card title='利用時間' isIconButtonShown={isAdmin(user, base.baseCode)}>
                      <SelectWrapper>
                        <SelectBoxV2
                          noBorder
                          options={Object.values(Hour)}
                          labels={HOURS_LABELS}
                          value={conferenceUsageHours}
                          onChange={setConferenceUsageHours}
                        />
                      </SelectWrapper>
                    </Card>
                    <Card title='利用人数' isIconButtonShown={isAdmin(user, base.baseCode)}>
                      <SelectWrapper>
                        <SelectBoxV2
                          noBorder
                          options={Object.values(NumberOfPeople)}
                          labels={NUMBER_OF_PEOPLE_LABELS}
                          value={conferenceNumberOfPeople}
                          onChange={setConferenceNumberOfPeople}
                        />
                      </SelectWrapper>
                    </Card>
                  </Content>
                </Mojaco>

                {isGreaterThanZero(getOneDayDiscountPrice(base)) && (
                  <Mojaco message={`${categoryName}の利用以外に1DAYも利用する場合は、チェックを入れてね！`}>
                    <MultiCheckBox
                      title='1DAYの併用有無'
                      options={toOption(conferenceOneDayUsage)}
                      onChange={options => setConferenceOneDayUsage(options[ZERO].checked)}
                    />
                  </Mojaco>
                )}

                {conferenceOneDayUsage && (
                  <Mojaco message='1DAYを利用する人数を選んでね！'>
                    <SelectBox
                      title='1DAYの利用人数'
                      options={Object.values(NumberOfPeople)}
                      labels={NUMBER_OF_PEOPLE_LABELS}
                      value={conferenceOneDayNumberOfPeople}
                      onChange={setConferenceOneDayNumberOfPeople}
                    />
                  </Mojaco>
                )}

                {receiptComponent}
              </>
            )}
          </>
        )}
        <BottomArea />
      </Container>
    </Component>
  );
});

AccountShot.displayName = 'AccountShot';
export default AccountShot;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center'
};

const Container = styled.div`
  width: 100%;
  max-width: 1124px;
  height: inherit;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing}px;
  padding-bottom: ${themeV2.mixins.v2.spacing * 200}px;
`;

const TitleWrapper = styled.div`
  padding: 0 ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 2}px;
`;

const MojacoWrapper = styled.div`
  margin: ${themeV2.mixins.v2.spacing}px 0px;
`;

const Greeting = styled(Typography)`
  width: calc(100% - ${theme.mixins.spacing * 3}px);
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  ${theme.mixins.underline};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const SelectWrapper = styled.div`
  width: 72px;
`;

const QRCodeWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background: ${themeV2.mixins.v2.color.background.white};
  padding: ${themeV2.mixins.v2.spacing * 3}px 0px;
  margin: ${themeV2.mixins.v2.spacing}px 0px ${themeV2.mixins.v2.spacing * 2}px;
  border-radius: 8px;
`;

const ScrollTarget = styled.div`
  scroll-margin-top: ${themeV2.mixins.v2.spacing * 2}px;
`;

const BottomArea = styled.div`
  width: 100%;
  height: 120px;
`;

const CardWrapper = styled.div`
  width: 100%;
  background: ${themeV3.mixins.v3.color.object.white};
  border-radius: 12px;
  padding: 0 ${themeV2.mixins.v2.spacing * 2}px;
  margin-bottom: ${themeV2.mixins.v2.spacing * 2}px;
`;

const CardTitleWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.mixins.border.lightGray};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${themeV2.mixins.v2.spacing}px 0 0 ${themeV2.mixins.v2.spacing * 2}px;
`;

const CardTitle = styled.div`
  ${themeV2.mixins.v2.typography.title.medium};
  width: 100%;
`;

const CardContent = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 2}px;
`;
