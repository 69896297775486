import {
  BackButtonV2,
  ButtonV2,
  CardV2,
  CardWithLabelV2,
  LabelV2,
  LinkButton,
  PageHeaderV2,
  ScreenLoaderV2,
  StatusV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  ContractV2,
  FETCH_SPACE_RESERVATION_FOR_ADMIN,
  FetchSpaceReservationForAdminRequest,
  FetchSpaceReservationForAdminResponse,
  ReservationPaymentMethod,
  SPACE_RESERVATION_ID,
  SpaceReservation,
  SpaceReservationId,
  SpaceReservationPaymentStatus,
  SpaceReservationStatus,
  UPDATE_RESOURCE_RESERVATION_PAYMENT_STATUS_FOR_ADMIN,
  UPDATE_SPACE_RESERVATION_STATUS_FOR_ADMIN,
  UpdateResourceReservationPaymentStatusForAdminRequest,
  UpdateResourceReservationPaymentStatusForAdminResponse,
  UpdateSpaceReservationStatusForAdminRequest,
  UpdateSpaceReservationStatusForAdminResponse,
  User,
  isBaseFunctionToggleEnabled,
  toFullName
} from '@atomica.co/irori';
import { Gap, Label } from '@atomica.co/types';
import { EMPTY, FIRST_INDEX, HYPHEN, builder, embedIdInPath, hasLength } from '@atomica.co/utils';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { format } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Card from '../../components/cards/Card';
import DefaultModal from '../../components/modal/DefaultModal';
import Screen from '../../components/screen/Screen';
import { useSnackbarV2 } from '../../provider/SnackbarProviderV2';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../router/Routes';
import { RESERVATION_PAYMENT_METHOD_LABELS } from '../../texts/payment-method-text';
import { RESERVATION_PAYMENT_STATUS_LABELS } from '../../texts/reservation-payment-status-text';
import {
  toPaymentStatusFromSpaceReservation,
  toStatusFromSpaceReservation,
  toStatusLabelFromSpaceReservation
} from '../../utils/space-reservation-util';
import ChangeSpaceModal from './modal/ChangeSpaceModal';

interface P {
  base: BaseDto;
  user: User;
}

const resolveUpdatePaymentStatus = (reservation: SpaceReservation): SpaceReservationPaymentStatus => {
  if (!reservation || !reservation.paymentStatus) return SpaceReservationPaymentStatus.PAID;
  switch (reservation.paymentStatus) {
    case SpaceReservationPaymentStatus.PAID:
      return SpaceReservationPaymentStatus.UNPAID;
    case SpaceReservationPaymentStatus.UNPAID:
      return SpaceReservationPaymentStatus.PAID;
    default:
      return SpaceReservationPaymentStatus.PAID;
  }
};

const ReservationDetailScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;

  const { params, openPathInNewTab, openBasePath } = usePath();
  const { commonRequest } = useCommonRequest();
  const { openSnackbar } = useSnackbarV2();

  const unmountRef = useUnmountRef();
  const [reservation, setReservation] = useSafeState<SpaceReservation>(unmountRef);
  const [modalType, setModalType] = useSafeState<SpaceReservationStatus>(unmountRef, SpaceReservationStatus.CONFIRMED);
  const [isChangeSpaceModalOpen, setIsChangeSpaceModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isUpdateStatusModalOpen, setIsUpdateStatusModalopen] = useSafeState<boolean>(unmountRef, false);
  const [isUpdating, setIsUpdating] = useSafeState<boolean>(unmountRef, false);
  const [contract, setContract] = useSafeState<ContractV2 | undefined>(unmountRef);

  const isShowSpacePayment = useMemo<boolean>(() => {
    if (!contract) return true;
    return false;
  }, [contract]);

  const spaceReservationId = useMemo<SpaceReservationId>(() => params[SPACE_RESERVATION_ID], [params]);

  const actionLabel = useMemo<Label>(() => {
    switch (modalType) {
      case SpaceReservationStatus.REJECTED:
        return '却下';
      case SpaceReservationStatus.CONFIRMED:
      default:
        return '確定';
    }
  }, [modalType]);

  const fetchSpaceReservation = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchSpaceReservationForAdminRequest>()
      .baseId(base.baseId)
      .spaceReservationId(spaceReservationId)
      .build();
    const response = await commonRequest<FetchSpaceReservationForAdminRequest, FetchSpaceReservationForAdminResponse>(
      FETCH_SPACE_RESERVATION_FOR_ADMIN,
      request
    );
    setReservation(response.spaceReservation);
    setContract(response.spaceReservation?.contract);
  }, [base.baseId, commonRequest, setContract, setReservation, spaceReservationId]);

  const handleSpaceReservationSaved = useSafeCallback(async (): Promise<void> => {
    setIsChangeSpaceModalOpen(false);
    await fetchSpaceReservation();
  }, [fetchSpaceReservation, setIsChangeSpaceModalOpen]);

  const updateSpaceReservationStatus = useSafeCallback(
    async (status: SpaceReservationStatus): Promise<void> => {
      setIsUpdating(true);
      const request = builder<UpdateSpaceReservationStatusForAdminRequest>()
        .baseId(base.baseId)
        .spaceReservationIds([spaceReservationId])
        .userId(user.userId)
        .status(status)
        .build();
      const response = await commonRequest<
        UpdateSpaceReservationStatusForAdminRequest,
        UpdateSpaceReservationStatusForAdminResponse
      >(UPDATE_SPACE_RESERVATION_STATUS_FOR_ADMIN, request);

      if (!response) return;

      await fetchSpaceReservation();
      setIsUpdating(false);
      setIsUpdateStatusModalopen(false);
    },
    [base, commonRequest, fetchSpaceReservation, setIsUpdateStatusModalopen, setIsUpdating, spaceReservationId, user]
  );

  const updateSpaceReservationPaymentStatus = useSafeCallback(async (): Promise<void> => {
    setIsUpdating(true);
    const request = builder<UpdateResourceReservationPaymentStatusForAdminRequest>()
      .baseId(base.baseId)
      .spaceReservationId(spaceReservationId)
      .paymentStatus(resolveUpdatePaymentStatus(reservation!))
      .build();
    const response = await commonRequest<
      UpdateResourceReservationPaymentStatusForAdminRequest,
      UpdateResourceReservationPaymentStatusForAdminResponse
    >(UPDATE_RESOURCE_RESERVATION_PAYMENT_STATUS_FOR_ADMIN, request);

    if (response.result === 'success') {
      openSnackbar('支払いステータスを更新しました', 'success', 4000);
      await fetchSpaceReservation();
    } else {
      openSnackbar('支払いステータスを更新に失敗しました', 'error', 4000);
    }
    setIsUpdating(false);
  }, [base, spaceReservationId, reservation]);

  const openBillingDetailScreen = useSafeCallback((): void => {
    if (!reservation?.contract?.billings) return;
    const billingId = reservation.contract.billings[FIRST_INDEX].billingId;
    openPathInNewTab(embedIdInPath(Path.BILLING_DETAILS_V2_DETAIL, PATH_IDS, [base.baseCode, billingId]));
  }, [base, openPathInNewTab, reservation]);

  const openUserDetailScreen = useSafeCallback((): void => {
    if (!reservation) return;
    openPathInNewTab(embedIdInPath(Path.USER_OVERVIEW, PATH_IDS, [base.baseCode, reservation.createdUser!.userId]));
  }, [base, openPathInNewTab, reservation]);

  useEffect(() => {
    fetchSpaceReservation();
  }, [fetchSpaceReservation]);

  const handleUpdateStatusModalOpen = useSafeCallback(
    (status: SpaceReservationStatus): void => {
      setModalType(status);
      setIsUpdateStatusModalopen(true);
    },
    [setIsUpdateStatusModalopen, setModalType]
  );

  const paymentStatusUpdateLabel = useMemo(() => {
    if (reservation?.paymentStatus === SpaceReservationPaymentStatus.PAID) {
      return RESERVATION_PAYMENT_STATUS_LABELS.unpaid.concat('にする');
    }
    if (reservation?.paymentStatus === SpaceReservationPaymentStatus.UNPAID) {
      return RESERVATION_PAYMENT_STATUS_LABELS.paid.concat('にする');
    }
  }, [reservation]);

  const headerRightButtonComponent = useMemo<React.ReactNode>(() => {
    if (!reservation) return <></>;
    const rejectButton = (
      <ButtonV2
        key={SpaceReservationStatus.REJECTED}
        type='tertiary'
        label='却下'
        onClick={() => handleUpdateStatusModalOpen(SpaceReservationStatus.REJECTED)}
      />
    );
    const confirmButton = (
      <ButtonV2
        key={SpaceReservationStatus.CONFIRMED}
        startIcon={<CheckRoundedIcon />}
        type='primary'
        label='確定'
        onClick={() => handleUpdateStatusModalOpen(SpaceReservationStatus.CONFIRMED)}
      />
    );
    switch (reservation.status) {
      case SpaceReservationStatus.PROVISIONAL:
        return [rejectButton, confirmButton];
      case SpaceReservationStatus.CONFIRMED:
        return rejectButton;
      default:
        return <></>;
    }
  }, [handleUpdateStatusModalOpen, reservation]);

  return (
    <Screen loading={!reservation} loadingType='circular' className='reservation-detail-screen'>
      {reservation && (
        <Container>
          <BackButtonV2 label='戻る' onClick={() => openBasePath(Path.RESERVATION_LIST)} />
          <PageHeaderV2
            title={reservation.space?.spaceName ? `${reservation.space?.spaceName}の予約` : '予約'}
            rightComponent={<ButtonWrapper>{headerRightButtonComponent}</ButtonWrapper>}
          />
          <Content>
            <Card title='基本情報'>
              <CardContent>
                <CardRow>
                  <CardWithLabelV2
                    label='利用開始日時'
                    text={reservation.startAt ? format(reservation.startAt, 'yyyy/MM/dd HH:mm') : EMPTY}
                  />
                  <CardWithLabelV2
                    label='利用終了日時'
                    text={reservation.endAt ? format(reservation.endAt, 'yyyy/MM/dd HH:mm') : EMPTY}
                  />
                </CardRow>
                <CardRow>
                  <CardWithLabelV2
                    label='設備カテゴリー'
                    text={reservation.space?.baseResourceCategory?.categoryLabel ?? '-'}
                  />
                  <CardWithLabelV2
                    label='設備名'
                    text={reservation.space!.spaceName}
                    component={
                      <ButtonV2
                        endIcon={<EditOutlinedIcon />}
                        label='編集'
                        onClick={() => setIsChangeSpaceModalOpen(true)}
                      />
                    }
                  />
                </CardRow>
                <CardRow>
                  <CardWithLabelV2
                    label='法人'
                    text={reservation.contract?.entityName || '-'}
                    component={
                      reservation.contract?.billings && (
                        <LinkButton hasIcon label='請求へ' onClick={openBillingDetailScreen} />
                      )
                    }
                  />
                  <CardWithLabelV2
                    label='予約者'
                    text={toFullName(reservation.createdUser) || '-'}
                    component={
                      reservation.createdUser && <LinkButton hasIcon label='' onClick={openUserDetailScreen} />
                    }
                  />
                </CardRow>
                <div>
                  <LabelV2 text='利用者' />
                  {hasLength(reservation.participants) &&
                    reservation.participants.map((participant, index, arr) => {
                      return (
                        index % 2 === 0 && (
                          <CardRow key={`participant-${index}`} gap={8}>
                            <CardV2 text={toFullName(participant.user)} />
                            {index + 1 < arr.length ? <CardV2 text={toFullName(arr[index + 1].user)} /> : <Dummy />}
                          </CardRow>
                        )
                      );
                    })}
                  {!hasLength(reservation.participants) && (
                    <CardRow>
                      <CardV2 text={HYPHEN} />
                      <Dummy />
                    </CardRow>
                  )}
                </div>
                {isShowSpacePayment && reservation.paymentMethod && (
                  <CardRow>
                    <CardWithLabelV2
                      label={'支払い方法'}
                      text={RESERVATION_PAYMENT_METHOD_LABELS[reservation.paymentMethod]}
                    />
                    <Dummy />
                  </CardRow>
                )}
                {reservation.options?.map((option, index) => {
                  return (
                    <CardRow key={`option-${index}`}>
                      <CardWithLabelV2
                        label={option.baseResourceCategoryOption!.optionLabel}
                        text={option.value ?? EMPTY}
                      />
                      <Dummy />
                    </CardRow>
                  );
                })}
                {reservation.remarks && <CardWithLabelV2 label='備考' text={reservation.remarks} />}
              </CardContent>
            </Card>
            <Card title='その他の情報'>
              <CardContent>
                <StatusWrapper>
                  <LabelV2 text='ステータス' />
                  <StatusV2
                    status={toStatusFromSpaceReservation(reservation)}
                    label={toStatusLabelFromSpaceReservation(reservation)}
                  />
                </StatusWrapper>
                {isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_RESERVATION_SPACE_PAYMENT) &&
                  isShowSpacePayment &&
                  reservation.paymentStatus &&
                  reservation.paymentMethod === ReservationPaymentMethod.CASH && (
                    <StatusWrapper>
                      <LabelV2 text='支払いステータス' />
                      <StatusWithButton>
                        <StatusV2
                          status={toPaymentStatusFromSpaceReservation(reservation)}
                          label={RESERVATION_PAYMENT_STATUS_LABELS[reservation.paymentStatus]}
                        />
                        <ButtonV2
                          onClick={updateSpaceReservationPaymentStatus}
                          label={paymentStatusUpdateLabel}
                          type={'tertiary'}
                        />
                      </StatusWithButton>
                    </StatusWrapper>
                  )}
                <CardWithLabelV2
                  label='予約日時'
                  text={reservation.createdAt ? format(reservation.createdAt, 'yyyy/MM/dd HH:mm') : '-'}
                />
                <CardWithLabelV2
                  label='予約確定/却下日時'
                  text={toFullName(reservation.updatedUser) ? format(reservation.updatedAt!, 'yyyy/MM/dd HH:mm') : '-'}
                />
                <CardWithLabelV2 label='予約確定/却下の操作者' text={toFullName(reservation.updatedUser) || '-'} />
              </CardContent>
            </Card>
          </Content>
          <ChangeSpaceModal
            baseId={base.baseId}
            userId={user.userId}
            reservation={reservation}
            baseResourceCategories={base.baseResourceCategories ?? []}
            isOpen={isChangeSpaceModalOpen}
            onClose={() => setIsChangeSpaceModalOpen(false)}
            onSave={handleSpaceReservationSaved}
          />
          <DefaultModal
            isOpen={isUpdateStatusModalOpen}
            headerLabel={`予約を${actionLabel}`}
            rightButtonProps={{
              disabled: isUpdating,
              label: '確定',
              onClick: () => updateSpaceReservationStatus(modalType)
            }}
            onClose={() => setIsUpdateStatusModalopen(false)}
          >
            予約を{actionLabel}します。
          </DefaultModal>
          <ScreenLoaderV2 loading={isUpdating} />
        </Container>
      )}
    </Screen>
  );
});

ReservationDetailScreen.displayName = 'ReservationDetailScreen';
export default ReservationDetailScreen;

const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  margin: 0 auto;
`;

const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  margin-top: ${themeV2.mixins.v2.spacing * 3}px;
`;

const CardRow = styled.div<{ gap?: Gap }>`
  display: flex;
  gap: ${({ gap }) => (gap ? gap : themeV2.mixins.v2.spacing * 2)}px;
`;

const Dummy = styled.div`
  width: 100%;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const StatusWithButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
