import {
  BackButtonV2,
  CircularLoader,
  Component,
  PageHeaderV2,
  StatusV2,
  TabProperty,
  TabsV3,
  themeV2,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BASE_CODE,
  BaseDto,
  EVENT_SCHEDULE_ID_V2,
  EventHoldingMethod,
  EventScheduleIdV2,
  EventScheduleV2,
  FETCH_SPACE_RESERVATIONS_BY_USER,
  FetchSpaceReservationsByUserRequest,
  FetchSpaceReservationsByUserResponse,
  SEARCH_EVENT_SCHEDULES_V2,
  STRIPE_PAY_RESULT,
  STRIPE_SESSION_ID,
  SearchEventSchedulesV2Request,
  SearchEventSchedulesV2Response,
  Sort,
  SpaceReservation,
  SpaceReservationId,
  SpaceReservationStatus,
  SpaceReservationType,
  User,
  toSpaceName
} from '@atomica.co/irori';
import { Index, Name, Offset, Order, Text } from '@atomica.co/types';
import {
  EMPTY,
  FIRST_INDEX,
  MINUS_ONE,
  ONE,
  ZERO,
  builder,
  embedIdInPath,
  hasLength,
  toFormattedDateTimeDurationStr
} from '@atomica.co/utils';
import MeetingRoomRoundedIcon from '@material-ui/icons/MeetingRoomRounded';
import PeopleOutlineRoundedIcon from '@material-ui/icons/PeopleOutlineRounded';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { format } from 'date-fns';
import React, { CSSProperties, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH } from '../../../constants/common-const';
import { AccountMySchedulesTabEnum } from '../../../enums/account-enum';
import { useSnackbarV2 } from '../../../provider/SnackbarProviderV2';
import usePath from '../../../redux/hooks/usePath';
import CommonRequest from '../../../requests/common-request';
import { PATH_IDS, Path } from '../../../router/Routes';
import { ACCOUNT_MY_SCHEDULES_TAB_LABELS } from '../../../texts/event-text';
import { SPACE_RESERVATION_STATUS_LABEL } from '../../../texts/space-text';
import { toStatusFromSpaceReservation } from '../../../utils/space-reservation-util';

const LIMIT = 10;

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 300px 0px'
};

type EventScheduleOrSpaceReservation = EventScheduleV2 | SpaceReservation;

interface P {
  base: BaseDto;
  user: User;
}

const isEventSchedule = (schedule: EventScheduleOrSpaceReservation): schedule is EventScheduleV2 =>
  EVENT_SCHEDULE_ID_V2 in schedule;

const getStartAt = (schedule: EventScheduleOrSpaceReservation): Date =>
  isEventSchedule(schedule) ? schedule.startAtV2 : schedule.startAt!;

const toHoldingMethod = (schedule: EventScheduleV2): Text => {
  switch (schedule.holdingMethod) {
    case EventHoldingMethod.OFFLINE:
      return schedule.location ?? EMPTY;
    case EventHoldingMethod.ONLINE:
      return 'オンライン';
    case EventHoldingMethod.UNDECIDED:
    default:
      return '未定';
  }
};

const toSpaceNameWithPlan = (reservation: SpaceReservation): Name => {
  const planName = hasLength(reservation.space?.plans) ? reservation.space!.plans[FIRST_INDEX].planName : EMPTY;
  const spaceName = toSpaceName(reservation) ?? EMPTY;
  return planName ? `${planName} / ${spaceName}` : spaceName;
};

const tabProperties = Object.values(ACCOUNT_MY_SCHEDULES_TAB_LABELS).map<TabProperty>(label => ({
  label,
  disabled: false
}));

const toFormattedDatetime = (schedule: EventScheduleOrSpaceReservation) => {
  if (isEventSchedule(schedule)) {
    return toFormattedDateTimeDurationStr(schedule.startAtV2, schedule.endAtV2, 'yyyy年M月d日 HH:mm', '~');
  }
  if (schedule.space?.baseResourceCategory?.resourceCategoryDef?.reservationType === SpaceReservationType.DATE) {
    return `${schedule.startAt ? format(schedule.startAt, 'yyyy年M月d日') : EMPTY}～${schedule.endAt ? format(schedule.endAt, 'yyyy年M月d日') : EMPTY}`;
  }
  return toFormattedDateTimeDurationStr(schedule.startAt!, schedule.endAt!, 'yyyy年M月d日 HH:mm', '~');
};

const AccountMySchedules: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { openBasePath, openPath, getHistoryState, setHistoryState, queryParams } = usePath();

  const unmountRef = useUnmountRef();
  const [isInitialized, setIsInitialized] = useSafeState<boolean>(unmountRef, false);
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [schedules, setSchedules] = useSafeState<EventScheduleOrSpaceReservation[]>(unmountRef, []);
  const [selectedTabIdx, setSelectedTabIdx] = useSafeState<AccountMySchedulesTabEnum>(
    unmountRef,
    AccountMySchedulesTabEnum.ATTENDING
  );

  const [stripeSessionId, setStripeSessionId] = useSafeState<string | undefined>(
    unmountRef,
    queryParams[STRIPE_SESSION_ID]
  );
  const [stripePayStatus, setStripePayStatus] = useSafeState<string | undefined>(
    unmountRef,
    queryParams[STRIPE_PAY_RESULT]
  );

  const eventScheduleOffset = useRef<Offset>(ZERO);
  const spaceReservationOffset = useRef<Offset>(ZERO);
  const eventScheduleHasMore = useRef<boolean>(true);
  const spaceReservationHasMore = useRef<boolean>(true);
  const bottomRef = useRef<HTMLDivElement>(null);

  const { openSnackbar } = useSnackbarV2();

  const createSpaceReservationRequest = useSafeCallback((): FetchSpaceReservationsByUserRequest => {
    switch (selectedTabIdx) {
      case AccountMySchedulesTabEnum.ATTENDING:
        return builder<FetchSpaceReservationsByUserRequest>()
          .baseId(base.baseId)
          .userIds([user.userId])
          .fromDate(new Date())
          .limit(LIMIT)
          .offset(spaceReservationOffset.current)
          .sort(Sort.ASC)
          .build();
      case AccountMySchedulesTabEnum.ATTENDED:
      default:
        return builder<FetchSpaceReservationsByUserRequest>()
          .baseId(base.baseId)
          .userIds([user.userId])
          .toDate(new Date())
          .limit(LIMIT)
          .offset(spaceReservationOffset.current)
          .sort(Sort.DESC)
          .build();
    }
  }, [base.baseId, spaceReservationOffset, selectedTabIdx, user]);

  const searchEventSchedules = useSafeCallback(async (): Promise<EventScheduleV2[]> => {
    if (!eventScheduleHasMore) return [];

    const eventScheduleRequest = builder<SearchEventSchedulesV2Request>()
      .baseId(base.baseId)
      .limit(LIMIT)
      .offset(eventScheduleOffset.current)
      .word(EMPTY)
      .userId(user.userId)
      .isHeld(!!selectedTabIdx)
      .isApplied(true)
      .build();

    const { eventScheduleV2 } = await CommonRequest.call<SearchEventSchedulesV2Request, SearchEventSchedulesV2Response>(
      SEARCH_EVENT_SCHEDULES_V2,
      eventScheduleRequest
    );

    eventScheduleOffset.current += ONE;
    eventScheduleHasMore.current = eventScheduleV2.length === LIMIT;

    return eventScheduleV2;
  }, [base, eventScheduleHasMore, eventScheduleOffset, selectedTabIdx, user]);

  const fetchSpaceReservations = useSafeCallback(async (): Promise<SpaceReservation[]> => {
    if (!spaceReservationHasMore) return [];

    const spaceReservationRequest = createSpaceReservationRequest();

    const { spaceReservations } = await CommonRequest.call<
      FetchSpaceReservationsByUserRequest,
      FetchSpaceReservationsByUserResponse
    >(FETCH_SPACE_RESERVATIONS_BY_USER, spaceReservationRequest);
    spaceReservationOffset.current += LIMIT;
    spaceReservationHasMore.current = spaceReservations.length === LIMIT;

    return spaceReservations;
  }, [spaceReservationHasMore, spaceReservationOffset, createSpaceReservationRequest]);

  const sortSchedules = useSafeCallback(
    (a: SpaceReservation | EventScheduleV2, b: SpaceReservation | EventScheduleV2): Order => {
      switch (selectedTabIdx) {
        case AccountMySchedulesTabEnum.ATTENDED:
          return getStartAt(a) < getStartAt(b) ? ONE : MINUS_ONE;
        case AccountMySchedulesTabEnum.ATTENDING:
        default:
          return getStartAt(a) < getStartAt(b) ? MINUS_ONE : ONE;
      }
    },
    [selectedTabIdx]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const [eventSchedules, spaceReservations] = await Promise.all([searchEventSchedules(), fetchSpaceReservations()]);
    const schedules: EventScheduleOrSpaceReservation[] = [...eventSchedules, ...spaceReservations].sort(sortSchedules);
    setSchedules(schedules);
    setIsLoaderShown(false);
    const historyState = getHistoryState<{ deleted: boolean }>();
    setHistoryState({});
    historyState?.deleted && openSnackbar('予約の削除に成功しました', 'success', 4000);
    if (stripeSessionId && stripePayStatus === 'success') {
      openSnackbar('予約に成功しました 反映まで時間がかかることがあります', 'success', 4000);
      setHistoryState({}, embedIdInPath(Path.ACCOUNT_MY_SCHEDULES, [BASE_CODE], [base.baseCode]));
      setStripeSessionId(EMPTY);
      setStripePayStatus(EMPTY);
    }
  }, [fetchSpaceReservations, searchEventSchedules, setIsLoaderShown, setSchedules]);

  useEffect(() => {
    if (isInitialized) return;
    setIsInitialized(true);
    initialize();
  }, [initialize, isInitialized, setIsInitialized]);

  const handleTabSelected = useSafeCallback(
    (selectedTabIdx: Index): void => {
      setSelectedTabIdx(selectedTabIdx);
      setIsInitialized(false);
      setSchedules([]);
      eventScheduleOffset.current = ZERO;
      eventScheduleHasMore.current = true;
      spaceReservationOffset.current = ZERO;
      spaceReservationHasMore.current = true;
    },
    [setSelectedTabIdx, setSchedules]
  );

  const handleBackButtonClicked = useSafeCallback((): void => {
    openBasePath(Path.ACCOUNT_HOME);
  }, [openBasePath]);

  const openAccountEventDetailScreen = useSafeCallback(
    (eventScheduleId: EventScheduleIdV2): void => {
      openPath(embedIdInPath(Path.ACCOUNT_EVENT_DEATIL, PATH_IDS, [base.baseCode, eventScheduleId]));
    },
    [base, openPath]
  );

  const openSpaceReservationScreen = useSafeCallback(
    (spaceReservationId: SpaceReservationId): void => {
      openPath(embedIdInPath(Path.ACCOUNT_RESERVATION_DETAIL, PATH_IDS, [base.baseCode, spaceReservationId]));
    },
    [base, openPath]
  );

  const onScroll = useSafeCallback(
    async (entries: IntersectionObserverEntry[]): Promise<void> => {
      for (const entry of entries) {
        if (!entry.isIntersecting) return;
        setIsLoaderShown(true);
        const [eventSchedulesToAdd, spaceReservationsToAdd] = await Promise.all([
          searchEventSchedules(),
          fetchSpaceReservations()
        ]);
        setSchedules(prevSchedules =>
          [...prevSchedules, ...eventSchedulesToAdd, ...spaceReservationsToAdd].sort(sortSchedules)
        );
        setIsLoaderShown(false);
      }
    },
    [fetchSpaceReservations, searchEventSchedules, setIsLoaderShown, setSchedules]
  );

  useEffect(() => {
    if (isLoaderShown || !(eventScheduleHasMore.current || spaceReservationHasMore.current)) return;
    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);
    bottomRef.current && observer.observe(bottomRef.current);
    return () => observer.disconnect();
  }, [isLoaderShown, onScroll]);

  return (
    <Component style={styleForComponent} className='account-my-schedule' title='あなたの予定'>
      <Container>
        <ScrollArea>
          <Content>
            <HeaderWrapper>
              <BackButtonV2 label='ホーム' onClick={handleBackButtonClicked} />
              <PageHeaderV2 title='あなたの予定' />
            </HeaderWrapper>
            <TabsV3
              clickable={!isLoaderShown}
              tabs={tabProperties}
              selectedTabIdx={selectedTabIdx}
              onChange={handleTabSelected}
              align='center'
            />
          </Content>

          <Content>
            <CardWrapper>
              {hasLength(schedules) &&
                schedules.map((schedule: EventScheduleOrSpaceReservation, index: Index) => (
                  <MyScheduleCard
                    key={`my-schedule-${index}`}
                    onClick={() =>
                      isEventSchedule(schedule)
                        ? openAccountEventDetailScreen(schedule.eventScheduleId)
                        : openSpaceReservationScreen(schedule.spaceReservationId)
                    }
                  >
                    <InfoWrapper>
                      <TitleWrapper>
                        {!isEventSchedule(schedule) && (
                          <StatusV2
                            status={toStatusFromSpaceReservation(schedule)}
                            label={SPACE_RESERVATION_STATUS_LABEL[schedule.status]}
                          />
                        )}
                        <Title>
                          {isEventSchedule(schedule)
                            ? schedule.eventV2!.name
                            : schedule.reservationName ||
                              `${schedule.space?.baseResourceCategory?.categoryLabel || EMPTY}予約`}
                        </Title>
                      </TitleWrapper>
                      {isEventSchedule(schedule) && <InfoText> {schedule.name}</InfoText>}
                    </InfoWrapper>
                    <InfoWrapper>
                      <CardRow>
                        <InfoText>
                          {isEventSchedule(schedule) ? <StyledRoomOutlinedIcon /> : <StyledMeetingRoomRoundedIcon />}
                          {isEventSchedule(schedule) ? toHoldingMethod(schedule) : toSpaceNameWithPlan(schedule)}
                        </InfoText>
                      </CardRow>
                      <CardRow>
                        <InfoText>
                          <StyledScheduleRoundedIcon />
                          <TimeText>{toFormattedDatetime(schedule)}</TimeText>
                        </InfoText>
                        <MemberCountText>
                          <StyledPeopleOutlineRoundedIcon />
                          {isEventSchedule(schedule)
                            ? schedule.participants?.filter(participant => participant.isApplied).length
                            : (schedule.participants?.length ?? ZERO)}
                          名
                        </MemberCountText>
                      </CardRow>
                    </InfoWrapper>
                    {!isEventSchedule(schedule) &&
                      !hasLength(schedule.participants) &&
                      schedule.status === SpaceReservationStatus.CONFIRMED && (
                        <ErrorMessage>
                          <WarningRoundedIcon />
                          利用者が登録されていません。
                        </ErrorMessage>
                      )}
                  </MyScheduleCard>
                ))}
              {hasLength(schedules) && <Bottom ref={bottomRef}>{isLoaderShown && <CircularLoader />}</Bottom>}
              {!hasLength(schedules) &&
                (isLoaderShown || !isInitialized ? (
                  <CircularLoaderWrapper>
                    <CircularLoader />
                  </CircularLoaderWrapper>
                ) : (
                  <MessageWrapper>
                    <NoScheduleMessage>対象の予定はありません</NoScheduleMessage>
                  </MessageWrapper>
                ))}
            </CardWrapper>
          </Content>
        </ScrollArea>
      </Container>
    </Component>
  );
});

AccountMySchedules.displayName = 'AccountMySchedules';
export default AccountMySchedules;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center'
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ScrollArea = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Content = styled.div`
  width: 100%;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin: 0 auto;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const CardWrapper = styled.div`
  width: 100%;
  flex: 1;
`;

const MyScheduleCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 12px;
  margin-bottom: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  gap: ${themeV2.mixins.v2.spacing * 1.5}px;
  cursor: pointer;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
`;

const Title = styled.div`
  margin-left: ${themeV2.mixins.v2.spacing}px;
  ${themeV2.mixins.v2.typography.title.large};
`;

const InfoText = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  display: flex;
  align-items: center;
  gap: 4px;
  min-width: 64px;
`;
const TimeText = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  letter-spacing: 0.2;
`;

const MemberCountText = styled(InfoText)`
  margin-left: auto;
`;

const CardRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Bottom = styled.div`
  width: 100%;
  height: 128px;
  display: flex;
  justify-content: center;
`;

const CircularLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledMeetingRoomRoundedIcon = styled(MeetingRoomRoundedIcon)`
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const StyledRoomOutlinedIcon = styled(RoomOutlinedIcon)`
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const StyledScheduleRoundedIcon = styled(ScheduleRoundedIcon)`
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const StyledPeopleOutlineRoundedIcon = styled(PeopleOutlineRoundedIcon)`
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const MessageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoScheduleMessage = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV3.mixins.v3.color.object.error};
`;
