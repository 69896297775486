import {
  BackButtonV2,
  ButtonV2,
  Component,
  FormGroupPullDown,
  FormGroupRadio,
  ImageUploadV2,
  InputWithLabelV2,
  PageHeaderV2,
  PullDownOption,
  ScreenLoaderV2,
  themeV2,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';

import {
  BaseDto,
  BaseResourceCategoryId,
  FETCH_RESOURCE_FOR_ADMIN,
  FetchResourceForAdminRequest,
  FetchResourceForAdminResponse,
  SpaceCategory,
  SpaceId,
  SpacePublishOption,
  SpaceReservationType,
  TaxForFetchResource,
  UPDATE_RESOURCE_FOR_ADMIN,
  UpdateResourceForAdminRequest,
  UpdateResourceForAdminResponse
} from '@atomica.co/irori';
import { Code, Id, Label, Message, Name, PriceStr, URL } from '@atomica.co/types';
import { EMPTY, isEmpty, isUndefined } from '@atomica.co/utils';
import { builder } from '@atomica.co/utils/build/builder';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { TaxMapping, toTaxLabel, toTaxMapping } from '../../../converters/tax-converter';
import { ImageCategory } from '../../../enums/common-enum';
import { useSnackbarV2 } from '../../../provider/SnackbarProviderV2';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import usePath from '../../../redux/hooks/usePath';
import useTax from '../../../redux/hooks/useTax';
import { Path } from '../../../router/Routes';
import { ImageService } from '../../../services/image-service';
import { styleForComponent } from '../../../styles/components';
import { SPACE_CATEGORY_LABEL, SPACE_IS_INVISIBLE_LABEL, SPACE_PUBLISH_OPTION_LABEL } from '../../../texts/space-text';
import { getTaxKey } from '../../../utils/tax-util';
import { ResourceForm } from '../../../validate/resourceMasterScreen/schema';
import { validate } from '../../../validate/resourceMasterScreen/validate';

interface P {
  base: BaseDto;
}

const RegisterResourceMasterScreen: React.FC<P> = React.memo(props => {
  const { base } = props;
  const { openBasePath, params, path } = usePath();
  const { commonRequest } = useCommonRequest();
  const { taxes, fetchTaxes } = useTax();
  const { openSnackbar } = useSnackbarV2();

  const unmountRef = useUnmountRef();
  const [isLoaderShwon, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [isSaving, setIsSaving] = useSafeState<boolean>(unmountRef, false);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);

  const [resourceCode, setResourceCode] = useSafeState<Code>(unmountRef, EMPTY);
  const [resourceName, setResourceName] = useSafeState<Name>(unmountRef, EMPTY);
  const [category, setCategory] = useSafeState<SpaceCategory>(unmountRef, SpaceCategory.CONFERENCE);
  const [selectedResourceCategoryId, setSelectedResourceCategoryId] = useSafeState<BaseResourceCategoryId>(
    unmountRef,
    EMPTY
  );
  const [unitPriceLabel, setUnitPriceLabel] = useSafeState<Label>(unmountRef, '1時間あたりの料金');
  const [unitPrice, setUnitPrice] = useSafeState<PriceStr>(unmountRef, EMPTY);
  const [tax, setTax] = useSafeState<TaxForFetchResource | undefined>(unmountRef, undefined);
  const [publish, setPublish] = useSafeState<Exclude<SpacePublishOption, typeof SpacePublishOption.AUTHORITY_LIMITED>>(
    unmountRef,
    SpacePublishOption.OPEN
  );
  const [isInvisible, setIsInvisible] = useSafeState<boolean>(unmountRef, false);
  const [photo, setPhoto] = useSafeState<File | undefined>(unmountRef, undefined);
  const [photoUrl, setPhotoUrl] = useSafeState<URL>(unmountRef, EMPTY);

  const isPhotoChanged = useRef<boolean>(false);

  const spaceId = useMemo<SpaceId>(() => params['resourceId'], [params]);

  const resourceCategoryPullDownOptions = useMemo<PullDownOption[]>(() => {
    if (!selectedResourceCategoryId) {
      return [builder<PullDownOption>().id(category).label(SPACE_CATEGORY_LABEL[category]).build()];
    }
    return (
      base.baseResourceCategories?.map(category =>
        builder<PullDownOption>().id(category.baseResourceCategoryId).label(category.categoryLabel).build()
      ) ?? []
    );
  }, [base.baseResourceCategories, category, selectedResourceCategoryId]);

  const taxMapping = useMemo<TaxMapping>(() => {
    return toTaxMapping(taxes);
  }, [taxes]);

  const taxPullDownOptions = useMemo<PullDownOption[]>(() => {
    return Object.entries(taxMapping).map(([key, obj]) => {
      const label = toTaxLabel(obj);
      return builder<PullDownOption>().id(key).label(label).build();
    });
  }, [taxMapping]);

  const onChangeFile = useSafeCallback(
    async (file: File): Promise<boolean> => {
      setPhoto(prevFile => {
        if (prevFile?.name !== file?.name) isPhotoChanged.current = true;
        return file;
      });
      return true;
    },
    [setPhoto]
  );

  const clearErrorMessage = useSafeCallback((): void => {
    setErrorMessage(EMPTY);
  }, [setErrorMessage]);

  const fetchResource = useCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<FetchResourceForAdminRequest>().spaceId(spaceId).baseId(base.baseId).build();
    const response = await commonRequest<FetchResourceForAdminRequest, FetchResourceForAdminResponse>(
      FETCH_RESOURCE_FOR_ADMIN,
      request
    );

    if (!response) {
      setIsLoaderShown(false);
      return;
    }

    setResourceCode(response.spaceCode);
    setResourceName(response.spaceName);
    setCategory(response.category);
    setSelectedResourceCategoryId(response.baseResourceCategory.baseResourceCategoryId);
    if (response.reservationType === SpaceReservationType.DATE) setUnitPriceLabel('1日あたりの料金');
    setUnitPrice(response.unitPrice.toString());
    if (response.publish !== SpacePublishOption.AUTHORITY_LIMITED) setPublish(response.publish);
    setTax(response.tax);
    setIsInvisible(response.isInvisibleOnUserReservingPage);
    const image = await ImageService.urlToFile(response.photoURL);
    if (image) setPhoto(image);
    setPhotoUrl(response.photoURL ?? EMPTY);
    setIsLoaderShown(false);
  }, [
    base.baseId,
    commonRequest,
    setCategory,
    setIsInvisible,
    setIsLoaderShown,
    setPhoto,
    setPhotoUrl,
    setPublish,
    setResourceCode,
    setResourceName,
    setSelectedResourceCategoryId,
    setTax,
    setUnitPrice,
    setUnitPriceLabel,
    spaceId
  ]);

  const saveResource = useSafeCallback(async (): Promise<void> => {
    if (!tax) return;
    clearErrorMessage();

    const unitPriceNumber = parseInt(unitPrice.replace(/,/g, ''));

    const resource = builder<ResourceForm>()
      .resourceCode(resourceCode)
      .resourceName(resourceName)
      .unitPrice(unitPriceNumber)
      // .photo(photo!)
      .build();
    const errorMessages = [validate(resource)];

    if (errorMessages.some(message => !isEmpty(message))) {
      setErrorMessage(errorMessages.filter(code => code !== EMPTY).join('\n'));
      openSnackbar('リソースの保存に失敗しました', 'error', 3000);
      return;
    }

    setIsSaving(true);

    const preBuild = builder<UpdateResourceForAdminRequest>()
      .baseId(base.baseId)
      .spaceId(spaceId)
      .spaceCode(resourceCode)
      .spaceName(resourceName)
      .unitPrice(unitPriceNumber)
      .taxId(tax.taxId)
      .taxDiv(tax.taxDiv)
      .publish(publish)
      .isInvisible(isInvisible);

    if (isPhotoChanged.current) {
      const photoUrlToSave = await ImageService.uploadImageToFirebase(photo, ImageCategory.CONFERENCE, spaceId);
      preBuild.photoUrl(photoUrlToSave);
    } else {
      preBuild.photoUrl(photoUrl);
    }

    const request = preBuild.build();
    const response = await commonRequest<UpdateResourceForAdminRequest, UpdateResourceForAdminResponse>(
      UPDATE_RESOURCE_FOR_ADMIN,
      request
    );
    if (!isUndefined(response)) {
      openSnackbar('リソースを保存しました', 'success', 3000);
      await fetchResource();
    } else {
      openSnackbar('リソースの保存に失敗しました', 'error', 3000);
    }
    setIsSaving(false);
  }, [
    base.baseId,
    clearErrorMessage,
    commonRequest,
    fetchResource,
    isInvisible,
    openSnackbar,
    photo,
    photoUrl,
    publish,
    resourceCode,
    resourceName,
    setErrorMessage,
    setIsSaving,
    spaceId,
    tax,
    unitPrice
  ]);

  const initialize = useCallback(async (): Promise<void> => {
    await Promise.all([fetchResource(), fetchTaxes(base.baseId)]);
  }, [base.baseId, fetchResource, fetchTaxes]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Component loading={isLoaderShwon} className='register-resource-master-screen' style={styleForComponent}>
      <Container data-testid='register-resource-master-screen'>
        <BackButtonV2 label='戻る' onClick={() => openBasePath(Path.RESOURCE_MASTER)} />
        <PageHeaderV2 title='リソースの編集' />
        <Card>
          <ContentTitle>基本情報</ContentTitle>
          <InputWrapper>
            <InputWithLabelV2
              text='リソースコード'
              value={resourceCode}
              required
              placeholder='例）miyazaki-1'
              onChange={setResourceCode}
            />
          </InputWrapper>
          <InputWrapper>
            <InputWithLabelV2
              text='リソース名称'
              value={resourceName}
              required
              placeholder='例）3名部屋'
              onChange={setResourceName}
            />
          </InputWrapper>
          <InputWrapper data-testid='resource-category'>
            <FormGroupPullDown
              required={path !== Path.EDIT_RESOURCE_MASTER}
              nullable
              placeholder='選択してください'
              label='リソースカテゴリー'
              id={selectedResourceCategoryId ?? category}
              options={resourceCategoryPullDownOptions}
              onClick={(id: Id) => setSelectedResourceCategoryId(id)}
              disabled={path === Path.EDIT_RESOURCE_MASTER}
            />
          </InputWrapper>
          <FormGroupRadio
            required
            title='公開範囲'
            options={Object.values(SpacePublishOption).filter(a => a !== SpacePublishOption.AUTHORITY_LIMITED)}
            labels={SPACE_PUBLISH_OPTION_LABEL}
            value={publish}
            onChange={value => setPublish(value)}
          />
          <FormGroupRadio
            required
            title='予約画面表示'
            options={['0', '1']}
            labels={SPACE_IS_INVISIBLE_LABEL}
            value={String(Number(isInvisible))}
            onChange={value => setIsInvisible(Boolean(Number(value)))}
          />
          <ImageUploadWrapper>
            <ImageUploadV2
              hiddenFileName
              textOnly
              header='リソース写真'
              height={210}
              file={photo}
              onChange={onChangeFile}
            />
          </ImageUploadWrapper>
        </Card>
        <Card>
          <ContentTitle>料金</ContentTitle>
          <InputWithLabelV2
            inputWidth={200}
            text={unitPriceLabel}
            required
            inputType='price'
            placeholder='¥'
            value={unitPrice}
            onChange={setUnitPrice}
          />
          <PullDownWrapper data-testid='tax'>
            <FormGroupPullDown
              required
              placeholder='選択してください'
              label='課税'
              id={getTaxKey(tax?.taxId, tax?.taxDiv, tax?.taxRate)}
              options={taxPullDownOptions}
              onClick={(id: Id) => {
                const { taxId, taxDiv, taxRate } = taxMapping[id];
                setTax({ taxId, taxDiv, taxRate });
              }}
            />
          </PullDownWrapper>
        </Card>
      </Container>
      <Footer data-testid='register-resource-master-screen-footer'>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <ButtonWrapper>
          {/* <ButtonV2 type='secondary' onClick={() => {}} label='キャンセル' /> */}
          <ButtonV2 type='primary' onClick={saveResource} label='保存' />
        </ButtonWrapper>
      </Footer>
      <ScreenLoaderV2 loading={isSaving} />
    </Component>
  );
});

RegisterResourceMasterScreen.displayName = 'RegisterResourceMasterScreen';
export default RegisterResourceMasterScreen;

const Container = styled.div`
  max-width: 1120px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 20}px 68px;
  margin: 0 auto;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeV3.mixins.v3.color.container.neutral.row};
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  border-radius: 8px;
`;

const ContentTitle = styled.div`
  color: ${themeV3.mixins.v3.color.object.black};
  ${themeV2.mixins.v2.typography.title.xLarge};
`;

const InputWrapper = styled.div`
  width: 50%;
`;

const ImageUploadWrapper = styled.div`
  max-width: 330px;
`;

const PullDownWrapper = styled.div`
  width: 200px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  z-index: 100;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
  box-shadow: ${themeV2.mixins.v2.shadow.elevationFooterShadow};
  background-color: ${themeV3.mixins.v3.color.container.neutral.row};
  width: 100%;
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 3}px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ErrorMessage = styled.div`
  ${themeV2.mixins.v2.typography.body.small};
  color: ${themeV2.mixins.v2.color.font.red};
`;
